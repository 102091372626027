const words = {
  nl: {
    next: "Volgende",
    email: "E-mail",
    password: "Wachtwoord",
    error: {
      default:
        "Oeps er is iets misgegaan neem contact op met support@deftiq.com.",
    },
    mail_overview: "E-mailoverzicht",
    search: "Zoeken",
    add: "Toevoegen",
    continue: "Verder",
    language: "Taal",
    save: "Opslaan",
    cancel: "Annuleren",
    finished: "afgerond",
    busy: "bezig",
    scheduled: "ingepland",
    not_started: "niet gestart",
    failed: "gezakt",
    interaction_time: {
      coursename: "Trainingsnaam",
      year: "Jaar",
      message:
        "Overzicht van de leertijd in minuten. De leertijd is de tijd die een deelnemer besteedt aan het voltooien van activiteiten binnen een e-learning.",
    },
    standard_table: {
      filter: "Filteren",
      export: "Exporteren",
      import: "Importeren",
      adjust_view: "Weergave aanpassen",
      column_select: "Kolommen selecteren",
      column_order: "Kolommen ordenen",
      filter_view: "Weergave filteren",
      select_minimal_column: "Selecteer minimaal een waarde.",
    },
    reports: {
      report: "Rapport",
      create_report: "Rapport aanmaken",
      build_steps: {
        select_report_type: "Selecteer een type rapportage",
        select_template:
          "Maak zelf een rapportage of kies een standaard rapportage",
        select_participant: "Selecteer één of meerdere deelnemers",
        select_training: "Selecteer één of meerdere trainingen",
        select_column: "Selecteer één of meerdere kolommen",
      },
      templates: {
        all_grades: "Cijfers voor alle trainingen",
        learn_duration: "Leerduratie voor alle trainingen",
      },
      columns: {
        added_by: "Toegevoegd aan training door",
        expired_date: "Verloopdatum",
        total_interaction_time: "Totale interactie tijd",
      },
      errors: {
        load_training: "De trainingen konden niet worden geladen. ",
      },
    },
    scheduled_exams: {
      exams: "Examens",
      scheduled_exams: "Ingeplande examens",
      columns: {
        name: "Examennaam",
        party: "Examenpartij",
        confirmed: "Bevestigd",
        examdate: "Examendatum",
        participant: "Deelnemer",
        status: "Status",
      },
      status: {
        passed: "afgerond",
        failed: "gezakt",
        not_started: "niet gestart",
        no_show: "afwezig",
        waiting: "in afwachting",
        confirmed: "bevestigd",
        not_confirmed: "niet bevestigd",
      },
    },
    company_page: {
      company_information: "bedrijfsgegevens",
      direct_access: "direct access",
      company_name: "bedrijfsnaam",
      legal_company_name: "Juridische bedrijfsnaam",
      errors: {
        company_name: "De bedrijfsnaam is incorrect ingevuld.",
        legal_company_name: "De primaire kleur is geen HEX code.",
        primairy_color: "De primaire kleur is geen HEX code.",
        secondary_color: "De secundaire kleur is geen HEX code.",
        street: "Een straat is verplicht.",
        city: "Stad",
        country: "Land",
        postal_code: "Vul een geldige postcode in.",
        email: "De email is niet correct.",
        no_participants: "Dit bedrijf bevat nog geen deelnemers.",
        course_change: "Uw wijzigingen zijn mislukt.",
        changed_company: "Het bedrijf is niet gewijzigd",
      },
      messages: {
        in_construction: "De training is in opbouw.",
        course_added: "De training is toegevoegd.",
        course_deleted: "De training is verwijderd.",
        copy_url: "De url is gekopieërd.",
        changed_company: "Het bedrijf is gewijzigd",
      },
      sender: "Afzender (naam onderaan e-mail)",
      login_url: "Unieke login URL",
      primairy_color: "#Primaire kleur",
      secondary_color: "#Secundaire kleur",
      street: "Vul een stad in.",
      city: "Stad",
      country: "Land",
      postal_code: "Postcode",
      catalogue_access:
        "Deelnemers van dit bedrijf hebben toegang tot de catalogus.",
      SIR_member: "is lid van de Stichting Industriële Reiniging (SIR)",
      exam_provider: "is een examen aanbieder.",
      exam_provider_settings: "Instellingen voor examen aanbieders.",
      no_direct_communication: "communiceert niet rechtstreeks met deelnemers.",
      no_terms: "Geen voorwaarden",
      exam_terms: "Examenvoorwaarden",
      exam_state_mail: "E-mail voor examenstatus",
      exam_confirmation_mail: "E-mail voor bevestiging examens",
      exam_billing_mail: "E-mail voor inkoopbonnen examens",
      exam_application_mail: "E-mail voor aanmelding examens",
      exam_finish_mail: "E-mail na afronding examens",
      click: "Klik",
      to_add_participant: "om een deelnemer toe te voegen.",
      abbreviation: "Afkorting",
      direct_access_url: "Direct Access url",
      direct_access_code: "Direct Access code",
      name: "naam",
      copy_url: "url kopieren",
      code: "code",
      standard_direct_access: "Standaard Direct Access (via app.deftiq.com)",
      bouwpas_direct_access: "Bouwpas Direct Access (via bouwpas)",
      goworkforce_direct_access: "Goworkforce Direct Access (via Goworkforce)",
      no_access_instructions: "Dit bedrijf heeft geen project instructies.",
      my_company: "Mijn Bedrijf",
    },
    upload_popup: {
      attachment_upload: "Bijlage uploaden",
      filename: "Bestandsnaam",
      description: "Beschrijving",
    },
    external_course_list: {
      external_courses: "Externe trainingen",
      common_info: "Algemene informatie",
      attachments: "Bijlagen",
      add_new_attachment: "Nieuwe bijlage toevoegen",

      coursename: "Trainingsnaam",
      participant: "Deelnemer",
      status: "Status",
      grade: "Cijfer",
      valid_until: "Geldig tot",
      description: "Omschrijving",
      uploads: "Aantal bijlagen",
    },
    question_editor: {
      activity: "Activiteit",
      rich_text: "Rich Text",
      question: "Vraag",
      are_you_sure: "Weet je het zeker?",
      warning_message: "Je verwijdert de vraag hiermee volledig.",
      question_deleted: "De vraag is verwijderd.",
      question_not_deleted: "De vraag is niet verwijderd",
      good_answer: "Goed antwoord",
      wrong_answer: "Fout antwoord",
      correct_feedback: "Feedback indien correct (optioneel)",
      bad_feedback: "Feedback indien incorrect (optioneel)",
      errors: {
        question: "Een vraag kan niet leeg zijn.",
        answer: "Een antwoord mag niet leeg zijn.",
        question_not_changed: "De vraag is niet gewijzigd",
        question_not_added: "De vraag is niet toegevoegd",
      },
      messages: {
        question_changed: "De vraag is gewijzigd",
        question_added: "De vraag is toegevoegd",
      },
    },
    update_quiz: {
      courses: "Trainingen",
      update_quiz: "Quiz wijzigen",
      add_questions: "Vragen toevoegen",
      structure: "Quiz opbouw",
      fields: {
        name: "Naam",
        description: "De beschrijving van de quiz",
        progress: "De quiz telt mee in de voortgang van de training.",
        has_video: "De quiz heeft video opnames.",
        correct_answer_visible:
          "Tijdens de inzage is het correcte antwoord zichtbaar.",
        feedback_visible:
          "Tijdens de inzage is de feedback bij de vragen zichtbaar.",
        attempts: "Pogingenbeheer",
        max_attempts: "Maximaal aantal pogingen",
        amount_questions: "Aantal vragen tijdens quizpoging",
        quiz_completion: "Afronding quiz",
        endless_attempts:
          "De quiz krijgt nieuwe pogingen tot iemand is geslaagd.",
        num_days_after_attempts:
          "Aantal dagen tot nieuwe pogingen beschikbaar zijn.",
        attempts_example:
          " Voorbeeld: een waarde van 1 zorgt ervoor dat 1 dag na de laatste quizpoging een deelnemer weer pogingen kan doen.",
        percentage_to_pass: "Percentage nodig om quiz te halen",
        valid_percentage: "Vul een getal in tussen 0 en 100",
        restrictions: "Toegangsbeperkingen",
      },
      restrictions: {
        hover_text: "Hovertekst van een vergrendelde activiteit.",
        if: "Als",
        is: "is",
        open_for: ", open dan de toegang voor",
      },
      messages: {
        update_failed: "Het wijzigen van de quiz is mislukt.",
        restriction_impossible: "De beperking is onmogelijk.",
        restriction_exists: "De beperking bestaat al.",
        restriction_added: "De beperking is toegevoegd.",
        restriction_not_added: "De beperking kan niet worden toegevoegd.",
        restriction_deleted: "De beperking is verwijderd.",
        restriction_not_deleted: "De beperking is niet verwijderd.",
        quiz_changed: "De quiz is gewijzigd.",
        quiz_not_changed: "De quiz is niet gewijzigd.",
        are_you_sure: "Weet je het zeker?",
        quiz_not_deleted: "De quiz kan niet worden verwijderd.",
      },
      states: { opened: "Opened", passed: "Passed", completed: "Completed" },
    },
    richtext_activity: {
      continue_learning: "  Druk op de knop en ga verder met je leerpad",
      continue: "Verder",
    },
    bulk_upload: {
      csv_upload: "CSV bestand uploaden",
      download_template: "Template downloaden",
      steps: {
        upload_file: "Upload een CSV bestand",
        check_file: "Controleer je CSV bestand",
        process_file: "Je bestand wordt verwerkt",
      },
      direct_invite:
        "Direct een e-mail uitnodiging versturen naar nieuwe deelnemers.",
      columns: { language: "Taal", status: "Status", reason: "Reden" },
    },
    updaterichtext: {
      update_course: "Training wijzigen",
      update_activity: "Activiteit wijzigen",
      update: "wijzigen",
      structure: "Activiteit opbouw",
      settings: "Instellingen",
      configuration: "Configuratie",
      name: "Naam",
      progress: "Progressie",
      counts_for_progress:
        "De activiteit telt mee in de voortgang van de training.",
      restrictions: {
        title: "Toegangsbeperkingen",
        locked_text: "Hovertekst wanneer activiteit op slot.",
        is: "is",
        if: "Als",
        open_access_for: ", open dan de toegang voor de activiteit",
        restriction_added: "De beperking is toegevoegd.",
        restriction_not_added: "De beperking kan niet worden toegevoegd.",
        restriction_removed: "De beperking is verwijderd.",
        restriction_not_removed: "De beperking is niet verwijderd.",
      },
      activity_updated: "De activiteit is gewijzigd.",
      activity_update_failed: "Het wijzigen van de activiteit is mislukt.",
    },
    richtextbuilder: {
      options: {
        paragraph: "paragraaf",
        italic: "cursief",
        bold: "dikgedrukt",
        ordered_list: "geordende lijst",
        horizontal_rule: "Horizontale regel",
        undo: "Ongedaan maken",
        redo: "Opnieuw doen",
      },
      headers: {
        text: "Tekst",
        uploads: "Uploads",
        alignment: "Uitlijning",
        listview: "Lijstweergave",
        layout: "Layout",
        general: "Algemeen",
        preview: "Preview",
      },
      preview_message_desktop: "Je bekijkt nu de desktop preview.",
      preview_message_mobile: "Je bekijkt nu de mobiele preview.",
    },
    richtextbuilder_popup: {
      give_url_for: "Geef een URL op voor de",
      or_give_url: "Of geef een URL op",
      upload_a: "Upload een",
      upload: "Uploaden",
      cancel: "Annuleren",
      add: "Toevoegen",
    },
    flow: {
      which_course: "Welke training?",
      input_placeholder: "Flow naam",
      days_placeholder: "Aantal dagen",
      error_message: "Een flow mag geen lege naam hebben.",
      confirmation: {
        title: "Weet je het zeker?",
        description:
          "Je hebt sommige regels ingevuld, maar nog niet toegevoegd.",
      },
      flow_settings: "Instellingen van deze flow",
      tooltip: {
        name: "De naam van de training.",
        sector: "De sector van de training.",
        language: "De talen van de training.",
        type: "Het type training.",
      },
      settings: "Instellingen van de flow",
      expired_content_policy:
        " Nieuwe deelnemers ook toevoegen aan trainingen die met een startdatum in het verleden liggen",
      finished_list: {
        title: "Flow is voltooid wanneer:",
        all: "Alle trainingen zijn afgerond.",
        particular: "Een specifieke training is afgerond.",
      },
      restriction: "Restricties",
      condition_list: {
        title: "Soort voorwaarde",
        direct: { title: "Direct beschikbaar" },
        status: {
          title: "Status training",
          start_expression: "Start als",
          middle_expression: "is",
        },
        date: {
          title: "Startdatum",
          start_from: "Start vanaf",
          start_at: "Start op",
        },
        relative_date: {
          title: "Relatieve startdatum",
          start_expression: "Start",
          middle_expression: "dagen nadat",
          end_expression: "is",
          opened: "Geopend",
          finished: "Afgerond",
        },
      },
      create: "Flow aanmaken",
      details: "Trainingsdetails bekijken",
      description_list: {
        first: "Geef je flow een naam",
        second:
          "Selecteer minimaal twee trainingen die onderdeel zijn van de flow",
        third: "Voeg restricties toe aan {flowname}",
        fourth: "Bekijk de details van de training.",
      },
      rule_conflict:
        "De regels zijn met elkaar in conflict. Verwijder regels om deelnemers te laten starten.",
      missing_error: "Vul alle velden in.",
      past_date_error:
        "De regel kan niet aangemaakt worden. De datum ligt in het verleden.",
      recursive_error:
        "Een training kan geen regel met betrekking tot zichzelf hebben.",
      already_exists_error: "Een soortgelijke regel is al toegevoegd.",
      other_rule_error: "Maak gebruik van de regel 'Status training'",
      reminder_title: "Weet je het zeker?",
      reminder_message:
        "Je verwijdert hiermee alle regels die je hebt toegevoegd aan deze training.",
      created_message: "De flow is aangemaakt.",
      failed_message: "Oeps, de flow is niet aangemaakt.",
      updated_message: "De flow is gewijzigd",
      not_updated_message: "De flow is niet gewijzigd",
    },
    flow_details: {
      overview: "Overzicht",
      settings: "Instellingen",
      status: "Status",
      flow_settings: "Flow Instellingen",
      flow_name: "Naam van de flow",
      flow_is_active: "Deze flow is actief.",
      edit_courses: "Trainingen en restricties van deze flow wijzigen",
      no_course_access:
        "De deelnemer heeft nog geen toegang tot deze e-learning.",
      label_list: {
        not_started: "NIET GESTART",
        busy: "BEZIG",
        completed: "AFGEROND",
        started: "GESTART",
        passed: "GESLAAGD",
        not_passed: "GEZAKT",
      },
    },
    flow_overview: {
      columns: {
        name: "Naam",
        status: "Status",
        amount_courses: "Aantal trainingen",
        amount_users: "Aantal deelnemers",
      },
      no_flows_to_export: "Er zijn geen flows om te exporteren",
      reminder_title: "Weet je het zeker?",
      reminder_message:
        "Je verwijdert hiermee ook alle toekomstige flowonderdelen. Bestaande onderdelen blijven beschikbaar.",
      cancel_button: "ANNULEREN",
      confirm_button: "VERWIJDEREN",
      created_message: "De flow is verwijderd.",
      failed_message: "Oeps, De flow is niet verwijderd.",
    },
    flow_booking: {
      title_list: {
        first: "Deelnemers toevoegen aan ",
        second: "Overzicht en bevestiging",
        third: "Deelnemer toevoegen",
      },
      description_list: {
        first: "Selecteer deelnemers om toe te voegen aan de flow ",
        second: "Bekijk en bevestig je boeking.",
        third: "Voeg een deelnemer toe",
      },
      created_message: "De deelnemers zijn toegevoegd aan de flow.",
      failed_message: "Oeps, de deelnemers zijn niet toegevoegd aan de flow.",
    },
    update_course: {
      passed_for:
        "U bent geslaagd voor de training {coursename} met een score van ... % 🎉",
      headers: {
        course_structure: "Trainingsopbouw",
        edit_course: "Training wijzigen",
      },
      select_language: "Selecteer een taal om te wijzigen.",
      language_description:
        "Selecteer een taal van de training waarvan je de inhoud wil wijzigen.",
      delete: "Verwijderen",
      update: "Wijzigen",
      duplicate: "Dupliceren",
      reach_out_deftiq:
        "Neem contact op met Deftiq om deze activiteit te wijzigen.",
      coursename: "Trainingsnaam",
      no_empty_coursename: "De naam van de training mag niet leeg zijn.",
      description: "Trainingsbeschrijving",
      language: "Taal",
      status: {
        status: "Status",
        live: "Gereed voor gebruik",
        building: "In opbouw",
      },
      messages: {
        course_updated: "De training is gewijzigd.",
        course_not_updated: "De training kan niet worden gewijzigd.",
        module_added: "De module is toegevoegd.",
        module_not_added: "Het toevoegen van de module is mislukt.",
        activity_added: "De activiteit is toegevoegd.",
        quiz_added: "De quiz is toegevoegd.",
      },
      delete_module: {
        are_you_sure: "Weet je het zeker?",
        description:
          "Je verwijdert hiermee ook alle aanmeldingen, modules & activiteiten die gekoppeld zijn aan deze training.",
        cancel: "ANNULEREN",
        delete: "VERWIJDEREN",
      },
      delete_activity: {
        description:
          "Je verwijdert hiermee ook alle activiteiten die gekoppeld zijn aan deze module.",
      },
    },
    booking_course_details: {
      category: "Categorie",
      status: "Status",
      no_info_available:
        "   Deze training heeft geen catalogusinformatie. Heb je vragen over de training, neem dan contact met ons op.",
    },
    course_settings: {
      select_company: "Selecteer een bedrijf",
      support_email: "Support emailadres",
      invalid_email: "Het emailadres is niet valide.",
      forward_email: "Email forwarding",
      forward_emails: "Forward e-mailadressen",
      forward_email_message:
        "Voltooiings e-mails van deze training zullen worden doorgestuurd naar de onderstaande e-mailadressen. Druk op enter om een e-mailadres toe te voegen.",
    },
    invoice_list: {
      message:
        "Het aantal boekingen wordt automatisch bijgehouden door het systeem. De informatie is dus aan verandering onderhevig.",
      search: "Zoeken",
      columns: {
        invoice: "Factuur",
        period: "Periode",
        no_access: "Je hebt geen toegang tot de facturenmodule",
      },
    },
    participant_skills: {
      type: "Soort",
      after_intake: "Na de intake",
      after_finish_course: "Na afronden van de training",
      no_skills_found: "Geen vaardigheden gevonden.",
    },
    exambooking_widget: {
      examtype: "Examentype",
      location: "Locatie",
      disclaimer:
        "*De bovenstaande data zijn beschikbaar onder voorbehoud. Je wordt tijdig geïnformeerd indien er onverhoopt wijzigingen plaatsvinden.",
    },
    examselection_widget: {
      location: "LOCATIE",
      no_exams_found: "Geen examens gevonden",
      selected: "Geselecteerd",
      location_field: "Locatie:",
      disclaimer:
        "*De bovenstaande data zijn beschikbaar onder voorbehoud. Je wordt tijdig geïnformeerd indien er onverhoopt wijzigingen plaatsvinden.",
    },
    examviewer: {
      exam: "Examen",
      course: "Training",
      languages: "Beschikbare talen",
      update: "Wijzigen",
      delete: "Verwijderen",
    },
    gender_input: { pick_gender: "Kies een geslacht." },
    day_picker: {
      all_cities: "Alle steden",
      all_months: "Alle maanden",
      all_languages: "Alle talen",
      first_date: "Eerst beschikbare datum:",
    },
    contract_list: {
      search: "Zoeken",
      columns: {
        name: "Naam",
        company: "Bedrijf",
        startdate: "Ingangsdatum",
        enddate: "Einddatum",
        status: "Status",
      },
      states: { active: "ACTIEF", inactive: "beëindigd" },
    },
    booking_list_tab: {
      search: "Zoeken",
      columns: {
        booking: "Boeking",
        participant: "Deelnemer",
        course: "Training",
        type: "Type",
        language: "Taal",
        works_at: "Werkt bij",
        status: "Status",
        bookingdate: "Boekingsdatum",
        booked_by: "Geboekt door",
      },
      status_labels: {
        paid: "voltooid",
        expired: "verlopen",
        open: "open",
        created: "aangemaakt",
        failed: "gefaald",
        canceled: "geannuleerd",
        invoice: "voltooid",
        needs_processing: "verwerking nodig",
        exam: "EXAMEN",
      },
    },
    country_input: {
      country: "Land",
      country_error: "Selecteer een land.",
      goodmorning: "Goedemorgen",
    },
    feedback_list: {
      questions: "Vragen van deelnemers",
      status: "Status:",
      fields: {
        fullname: "Volledige naam:",
        email: "Emailadres:",
        page: "Pagina:",
        date: "Datum:",
        sent_to: "Verstuurd naar:",
        browser: "Browser:",
        send: "Verstuur",
        cancel: "Annuleren",
      },
      are_you_sure: "Weet je het zeker?",
      info_message: "Je verwijdert hiermee alle vragen.",
      cancel: "ANNULEREN",
      delete: "VERWIJDEREN",
    },
    booking_list: {
      status_labels: {
        paid: "betaald",
        expired: "verlopen",
        open: "open",
        created: "aangemaakt",
        failed: "gefaald",
        canceled: "geannuleerd",
        invoice: "factuur",
        needs_processing: "verwerking nodig",
      },
      has: "heeft",
      descriptions: {
        booking_created: "{type} geboekt.",
        retry_course:
          " {coursename} van {firstname} {lastname} opnieuw gestart.",
        cancel_course: "{coursename} van {firstname} {lastname} geannuleerd.",
        cancel_exam: "{name} van {firstname} {lastname} geannuleerd.",
        add_exam: "{name} geboekt voor {firstname} {lastname}.",
        update_exam: "{name} gewijzigd voor {firstname} {lastname}.",
        update_course: "{coursename}  gewijzigd voor {firstname} {lastname}.",
        extend_course: "{coursename}  verlengd voor {firstname} {lastname}.",
      },
    },
    catelogue_popup: {
      category: "Categorie",
      status: "Status",
      status_labels: {
        live: "live",
        available: "beschikbaar",
        in_construction: "in opbouw",
      },
    },
    update_booking: {
      info: "Boekingsgegevens",
      course: "E-learning:",
      flow: "Flow:",
      start_course: "Start e-learning:",
      expired_elearning: "Verloop e-learning:",
      examdate: "Examendatum:",
      examlocation: "Examenlocatie:",
      examstatus: "Examenstatus:",
      exam_status: {
        confirmed: "Bevestigd",
        refused: "Geweigerd",
        waiting: "Not niet bevestigd",
      },
      examsupplier: "Examenaanbieder:",
      examlanguage: "Examentaal:",
      participant: "Deelnemer:",
      invoice_email: "Factuur emailadres:",
      order_number: "Bonnummer:",
      payment_method: "Betaalwijze:",
      payment_status: { mollie: "Mollie", invoice: "Factuur" },
      booked_by: "Geboekt door:",
      contactperson: "Naam contactpersoon:",
      email_contactperson: "Emailadres contactpersoon",
      street: "Straat:",
      postal_code: "Postcode:",
      city: "Stad:",
      country: "Land:",
      history: "Historie",
      fully_cancelled: "De boeking is volledig geannuleerd.",
      failed: "De boeking is mislukt. Probeer het opnieuw.",
      back: "Terug",
      next: "Volgende",
      actions: {
        update_exam: "Examen wijzigen",
        add_exam: "Examen toevoegen",
        extend_course: "Training verlengen",
        retry_course: "Opnieuw starten",
        cancel_course: "Training annuleren",
        cancel_exam: "Examen annuleren",
        book_again: "Opnieuw boeken",
      },
      action_descriptions: {
        booking_created: "Boeking aangemaakt",
        retry_course: "E-learning opnieuw gestart",
        cancel_course: "E-learning geannuleerd",
        cancel_exam: "Examen geannuleerd",
        add_exam: "Examen toegevoegd",
        update_exam: "Examen gewijzigd",
        update_course: "E-learning gewijzigd",
        extend_course: "E-learning verlengd",
      },
      update_booking: "Wijziging van de boeking",
      cancelled_booking: "geannuleerde boeking",
      exam_only: "Examen only",
      exam: "Examen",
      booking: "Boeking",
      contract: "Contract",
      disruption_title: "Let op, je kan hiermee de flow verstoren.",
      disruption_message:
        "Deze training is onderdeel van een flow en kan niet los geannuleerd worden.",
      cancel_button: "ANNULEREN",
      confirm_button: "VERDER",
    },
    confirm_booking: {
      info: "Boekingsgegevens",
      invoice_info: "Factuurgegevens",
      fields: {
        company_name: "Bedrijfsnaam*",
        contactperson: "Contactpersoon*",
        invoice_email: "Factuur e-mailadres*",
        street: "Straatnaam en huisnummer*",
        city: "Stad*",
        postalcode: "Postcode*",
        order_number: "Bonnummer",
        extend_until: "Verlengen tot*",
      },
      free_action: "Geen kosten voor deze actie in rekening brengen.",
      price: "Prijs",
      total: "Totaal:",
      free: "Gratis",
      with_vat: "incl. BTW",
      errors: {
        company_name: "De bedrijfsnaam is verplicht.",
        contactperson: "Een contactpersoon is verplicht.",
        invoice_email: "Een emailadres is verplicht.",
        street: "De straatnaam en het huisnummer zijn verplicht.",
        city: "De stad is verplicht",
        postalcode: "De postcode is verplicht.",
        order_number: "Uw ordernummer is incorrect",
        no_extension: "De toegang is niet verlengd.",
        exam_not_cancelled: "Het examen is niet geannuleerd.",
      },
      terms: {
        agree_with: "Ik ga akkoord met de",
        general_terms: "algemene voorwaarden",
        and: "en",
        course_terms: "trainingsvoorwaarden",
        exam_terms: "examenvoorwaarden",
      },
      actions: {
        extend: "Verlengen bevestigen",
        retry_course: "Opnieuw starten bevestigen",
        cancel_course: "E-learning annuleren bevestigen",
        cancel_exam: "Examen annuleren bevestigen",
      },
      action_descriptions: {
        extend:
          "{coursename} voor {firstname} {lastname} verlengen met 14 dagen",
        retry_course:
          "{firstname} {lastname} opnieuw laten starten met {coursename}.",
        cancel_course: " {coursename} annuleren voor {firstname} {lastname}.",
        cancel_exam: "Examen annuleren voor {firstname} {lastname}.",
      },
      messages: {
        start_again: "De deelnemer kan opnieuw starten",
        extend_access: "De toegang is verlengd.",
        participant_cancel: "De deelnemer is uitgeschreven.",
        exam_cancel: "Het examen is geannuleerd.",
      },
      back: "Terug",
      confirm: "Bevestigen",
    },
    proctor_viewer: {
      attempt_for: "Examenpoging voor {coursename} op {attempted_at}",
      add_description:
        "Geef een toelichting waarom je de poging wel of niet geldig verklaard.",
      validate: "Goedkeuren",
      invalidate: "Afkeuren",
      finish: "Afronden",
    },
    participant_insights: {
      insights: "Inzichten",
      results: "Resultaten",
      progress: "Voortgang",
      quiz_attempts: "Quizpogingen",
      activity: " Activiteit",
      apart_of: " Onderdeel van",
      attempt: "Poging",
      status_label: "Status",
      grade: "Cijfer",
      end_date: "Einddatum",
      download_certificate: "Certificaat downloaden",
      status: {
        passed: "afgerond",
        failed: "gezakt",
        busy: "bezig",
        not_started: "niet gestart",
        no_show: "afwezig",
      },
    },
    contract: {
      admin: "Administratie",
      my_admin: "Mijn Administratie",
      overview: "Overzicht",
      update_contract: "Contract wijzigen",
      invoice_template: "Offerte template",
      info: {
        contract_info: "Contract informatie",
        company: "Bedrijf",
        email: "Emailadres",
        invoice_number: "Orderbonnummer",
        type: "Soort",
        startdate: "Startdatum",
        enddate: "Einddatum",
      },
      parameters: {
        title: "Contract parameters",
        amount_participants: "Aantal deelnemers",
        amount_custom_courses: "Aantal maatwerk trainingen",
        price_extra_participant: "Prijs per extra deelnemer",
        price_extra_course: "Prijs per extra maatwerk training",
        only_started: "Alleen deelnemers tellen die zijn gestart",
      },
    },
    proctor_overview: {
      welcome: "Welkom",
      startdate: "Starttijd",
      enddate: "Eindtijd",
      message_par1:
        "    In de lijst hieronder zie je examenpogingen die je kan beoordelen.",
      message_par2:
        "    Klik op het oogje om te starten met het beoordelen van een examenpoging.",
      course: "Training",
      activity: "Activiteit",
      no_attempts: "Er zijn geen examenpogingen om te beoordelen.",
      attempts_error: "Helaas, de pogingen konden niet worden geladen.",
    },
    dashboard: {
      recent_actions: "Recente acties van deelnemers",
      load_more_actions: "Meer acties laden",
      add_participant: "Deelnemers inschrijven",
      add_flow: "Flow maken",
      add_course: "Eigen e-learning maken (Bèta)",
      matrix_overview: "Trainingsmatrix bekijken",
      total: "Totaal",
      kpi: {
        new_participants: "Nieuwe deelnemers",
        new_enrollments: "Nieuwe aanmeldingen",
        passed_participants: "Geslaagde deelnemers",
        enrollments: "Aanmeldingen",
      },
      months: {
        january: "januari",
        february: "februari",
        march: "maart",
        april: "april",
        may: "mei",
        june: "juni",
        july: "juli",
        august: "augustus",
        september: "september",
        october: "oktober",
        november: "november",
        december: "december",
      },
      events: {
        passed: "{firstname} heeft de {coursename} afgerond.",
        opened: "{firstname} heeft de {coursename} geopend.",
        registered: "{firstname} is aangemeld voor de {coursename}. ",
        created: "{firstname} heeft een account aangemaakt. ",
      },
    },
    training_page: {
      settings: "Instellingen",
      search: "Zoeken",
      status: {
        failed: "Gezakt",
        expired: "Verlopen",
        planned: "Ingepland",
        busy: "Bezig",
        finished: "Afgerond",
        registered: "Niet gestart",
      },
      startdate: "Startdatum",
      view_results: "Resultaten inzien",
      update_booking: "Boeking wijzigen",
      participants: "Deelnemers",
      all_status: "Alle statussen",
      columns: {
        fullname: "Volledige naam",
        company: "Bedrijf",
        course_status: "Status",
        enddate: "Einddatum",
        startdate: "Startdatum",
        grade: "Cijfer",
      },
    },
    catalogue_popup: {
      sign_up: "Inschrijven +",
      duration: "Looptijd",
      language: "Taal",
      price_per_person: "Prijs p.p.",
      save: "opslaan",
      cancel: "annuleren",
      course_modified: "De training is gewijzigd.",
      course_not_modified: "De training kan niet worden gewijzigd.",
      status_labels: { available: "Beschikbaar", in_construction: "In opbouw" },
      category: "Categorie",
      status: "Status",
    },
    training_overview: {
      exams: "Examens",
      course_overview: "trainingsoverzicht",
      add_course: "Training toevoegen",
      requirements: "Voorwaarden",
      question_pool: "Vragenpool",
      search: "Zoeken",
      columns: {
        name: "Naam",
        shortname: "Afkorting",
        sector: "Sector",
        supported_languages: "Taal",
        status: "Status",
      },
      fields: {
        coursename: "Trainingsnaam",
        shortname: "Afkorting",
        description: "Trainingsbeschrijving",
        language: "Taal",
        course_price: "Prijs",
        course_photo: "Trainingsfoto uploaden",
        course_days_expired: "Na hoeveel dagen toegangsverlies?",
        course_days_reminder_not_opened: "Reminder ongeopende training",
        course_days_reminder_not_done: "Reminder onvoltooide training",
        course_days_reminder_expired:
          "Hoeveel dagen voor vervaldatum toegangsverlies reminder?",
        course_passed_grade:
          "Welk cijfer moet iemand halen voor deze training om te slagen? (percentage)",
        is_consumer_course:
          "De training is beschikbaar voor beheerders in de catalogus.",
        course_duration: "Doorlooptijd",
        is_project_instruction:
          "De training heeft direct access zonder deelnemer autorisatie",
      },
      view: "Bekijken",
      evaluations: "Evaluaties",
      update: "Wijzigen",
      duplicate: "Dupliceren",
      translate_add: "Taal toevoegen",
      delete: "Verwijderen",
      no_search_results: "Geen zoekresultaten gevonden.",
      add: "Toevoegen",
      cancel: "Annuleren",
      participant_preview: "Deelnemer Preview",
      settings: "Instellingen",
      errors: {
        coursename: "De naam van de training mag niet leeg zijn.",
        shortname: "De afkorting mag niet leeg zijn.",
        description: "Een beschrijving is verplicht.",
        primary_color: "De primaire kleur is geen HEX code.",
        secondary_color: "De secundaire kleur is geen HEX code.",
        integer_days: "De waarde is geen geheel aantal dagen.",
        certificate: "Upload een certificaat achtergrond (optioneel)",
        integer_price: "De waarde is geen geheel getal.",
        lead_time: "De doorlooptijd mag niet leeg zijn.",
        direct_access: "De training heeft direct access",
        no_watch_course: "De training kan niet worden bekeken.",
      },
      messages: {
        no_courses_to_export: "Geen trainingen om te exporteren.",
        preview_course: "Je bekijkt nu de training als deelnemer.",
      },
    },
    error_page: {
      general: "Oeps, er is iets foutgegaan.",
      not_found:
        "Je bent naar een pagina geleid die niet (meer) bestaat. Wij helpen je graag weer op weg.",
      invite_expired:
        "Deze uitnodigingslink is verouderd of onjuist. Controleer uw mailbox voor een andere uitnodigingslink. <br> Komt u er niet uit? Neem dan contact op met support@deftiq.com",
      direct_closed:
        "De direct access toegang voor uw organisatie tot deze training is afgesloten. <br>Hoort u wel toegang te hebben neem contact op met support@deftiq.com.",
    },
    login: {
      welcome: "Welkom bij ",
      fill_in_password: "Wachtwoord invullen",
      fill_in_email_below: "Vul hieronder je e-mailadres in.",
      fill_in_password_below: "Vul hieronder je wachtwoord in.",
      create_an_account: "Maak een account aan",
      forgot_password: "Wachtwoord vergeten",
      open_invite: "Openstaande uitnodiging",
      login: "inloggen",
      errors: {
        wrong_password_or_email: "E-mailadres of wachtwoord is onjuist.",
        no_account_yet: "Wij konden geen account met dit e-mailadres vinden.",
        open_invite:
          "Er staat nog een uitnodiging voor je klaar om jezelf te registreren. Deze is opnieuw verstuurd naar",
        open_invite_short:
          "Er staat nog een uitnodiging voor je klaar om jezelf te registreren.",
      },
      session_expired: "Je sessie is verlopen",
    },
    training: {
      attempts_left: "pogingen over",
      extra_info_required:
        "De onderstaande gegevens zijn nodig om met {coursename} te starten",
      activity_locked:
        "Deze activiteit is nog niet beschikbaar. Zorg dat je de voorgaande activiteiten hebt voltooid.",
      activity_finished: "afgerond",
      activity_failed: "gezakt",
      activity_not_started: "niet gestart",
      activity_doing: "bezig",
      course_passed: {
        first: "Je bent geslaagd voor de training ",
        second: " met een score van ",
        percent: " %.",
      },
      download_certificate: "Download hier je bewijs van deelname.",
      no_attempts_left:
        "Je hebt geen pogingen meer om de activiteit te starten.",
      no_rise_training_available: "De activiteit heeft geen RISE training.",
    },
    navbar: {
      student: {
        my_overview: "Mijn Overzicht",
        my_results: "Mijn Resultaten",
        my_profile: "Mijn Profiel",
        catalogue: "Catalogus",
        overview: "Overzicht",
        results: "Resultaten",
        profile: "Profiel",
      },
      supervisor: {
        my_dashboard: "Mijn Dashboard",
        my_courses: "Mijn Trainingen",
        my_participants: "Mijn Deelnemers",
        my_administration: "Mijn Administratie",
        my_flows: "Mijn Flows",
        catalogue: "Catalogus",
        my_profile: "Mijn Profiel",
        helpcenter: "Helpcentrum",
        dashboard: "Dashboard",
        profile: "Profiel",
        courses: "Trainingen",
        participants: "Deelnemers",
      },
      admin: {
        dashboard: "Dashboard",
        companies: "Bedrijven",
        courses: "Trainingen",
        flows: "Flows",
        participants: "Deelnemers",
        contracts: "Contracten",
        catalogue: "Catalogus",
        emails: "E-mails",
        profile: "Profiel",
      },
      system_status: "Systeem Status",
      logout: "Uitloggen",
      profile_admin: "Profielbeheer",
      add_course: "Training toewijzen",
      session_expires: {
        in_one_hour: "Je sessie verloopt over 1 uur.",
        expired: "Je sessie is verlopen.",
      },
    },
    results: {
      results: "Resultaten",
      title_course_name: "De naam van de training",
      course: "Training",
      title_course_status: "De status van de training",
      course_status: "Status",
      title_course_progress: "Uw progressie",
      course_progress: "Progressie(%)",
      title_course_grade: "Uw behaalde resultaat",
      course_grade: "Cijfer",
      title_course_finish_date: "Einddatum van de training",
      course_finish_date: "Einddatum",
      no_courses_finished_yet: "U heeft nog geen trainingen afgerond.",
      results_could_not_load: "De resultaten konden niet worden geladen.",
    },
    course_overview: {
      add_course: "Training toevoegen",
      access_expired: "Toegang verlopen",
      access_scheduled: "Ingepland",
      percent_completed: "% voltooid.",
      no_available_courses: "Je hebt geen beschikbare trainingen.",
      course_overview_cant_load: "Het cursusoverzicht kon niet worden geladen.",
      active_courses: "Actieve trainingen",
      expired_courses: "Verlopen trainingen",
      all_courses: "Alle trainingen",
    },
    evaluation: {
      your_anwser: "Jouw antwoord",
      anwser_question_mandatory: "Je moet deze vraag beantwoorden.",
      submit_evaluation: "Inleveren",
      thank_you_message: "Dank je wel voor het invullen van de evaluatie.",
      continue_with_course: "Je kan verdergaan met de training op het leerpad",
    },
    register: {
      email_code_auth:
        "Vul hier de code in die je hebt ontvangen via de e-mail.",
      code: "Code",
      incorrect_code: "De code is incorrect",
      enter_email_and_continue:
        "Vul hieronder jouw e-mailadres in en druk op volgende.",
      incorrect_email: "De e-mail is incorrect.",
      enter_password_for_login: "Vul je wachtwoord in om in te loggen.",
      firstname: "Voornaam",
      firstname_mandatory: "De voornaam is verplicht.",
      lastname: "Achternaam",
      lastname_mandatory: "De achternaam is verplicht.",
      company: "Bedrijf",
      company_mandatory: "Het bedrijf is verplicht.",
      preference_lang_courses: "Voorkeurstaal platform",
      conditions: {
        first: " Ik heb de ",
        second: " voorwaarden ",
        third: " en het ",
        fourth: " privacy beleid ",
        fifth: " gelezen en ga hiermee akkoord.",
      },
      accept_conditions_mandatory:
        "U moet akkoord gaan met de algemene voorwaarden.",
      password_must_contain:
        "Het wachtwoord moet minimaal 8 karakters bevatten.",
      repeat_password: "Herhaal jouw wachtwoord.",
      passwords_dont_match: "De wachtwoorden komen niet overeen.",
      identity_check: {
        first:
          "Even checken of jij het écht bent. We hebben een verificatiecode gestuurd naar ",
        second: " (check ook even je spam).",
      },
      resent_invitation:
        "Er staat nog een uitnodiging voor je klaar om jezelf te registreren. Deze is opnieuw verstuurd naar ",
      forgot_password: "Wachtwoord vergeten?",
      switch_case_data: {
        fill_in_code: "Vul jouw code in",
        welcome_to: "Welkom bij ",
        fill_in_data: "Vul jouw gegevens in",
        fill_in_password: "Wachtwoord invullen",
        think_of_password: "Bedenk een wachtwoord",
        open_invite: "Openstaande uitnodiging",
        extra_information: "Aanvullende informatie",
      },
      register: "Registreren",
      login: "Inloggen",
      page_doesnt_exist: "Oeps, deze pagina bestaat niet.",
      page_load_error: "Er is iets misgegeaan bij het laden van de pagina.",
      admin_account_already_exists: "Je hebt al een account als beheerder",
      invite_not_accepted: "Je hebt je uitnodiging nog niet geaccepteerd.",
      gone_wrong_contact_deftiq:
        "Oeps, er is iets misgegaan. Neem contact op met support@deftiq.com",
    },
    register_no_course: {
      become_supervisor: "Word beheerder!",
      become_student: "Word deelnemer",
      account_already_exists_login:
        "Je hebt al een bestaand account en kunt inloggen.",
      account_already_exists_as: "Je hebt al een bestaand account als ",
      give_your_organisation:
        "En geef jouw organisatie toegang tot onze interactieve leeromgeving en unieke trainingen (ook op maat, als je wilt).",
      get_access_courses:
        "En krijg toegang tot een rijke catalogus met unieke trainingen.",
      fill_in_email_supervisor:
        "Vul alleen even je e-mailgegevens in en neem snel een kijkje op ons platform. Helemaal gratis.",
      fill_in_email_participant:
        "Vul hieronder jouw e-mailadres in en druk op volgende.",
    },
    invite: {
      intro_message: "Je bent uitgenodigd om een account aan te maken.",
      password_message: "Het wachtwoord moet minimaal 8 karakters bevatten.",
      header: {
        intro: "Welkom bij ",
        password: "Bedenk een wachtwoord",
        check: "Controleer je gegevens",
      },
      fields: {
        email: "E-mail",
        email_validation: "De e-mail is incorrect.",
        password: "Wachtwoord",
        password_validation:
          "Het wachtwoord moet minimaal 8 karakters lang zijn.",
        repeat_password: "Herhaal jouw Wachtwoord",
        repeat_password_validation: "De wachtwoorden komen niet overeen.",
        firstname: "Voornaam",
        firstname_validation: "De voornaam is verplicht.",
        lastname: "Achternaam",
        lastname_validation: "De achternaam is verplicht.",
        language: "Taal",
      },
      terms: {
        first_sentence: " Ik heb de ",
        terms: "voorwaarden",
        second_part: "en het",
        privacy_policy: "privacy beleid",
        final_sentence: " gelezen en ga hiermee akkoord.",
        validation: "U moet akkoord gaan met de algemene voorwaarden.",
      },
      completed: "Afronden",
    },
    catalogue: {
      catalogue: "Catalogus",
      all_sectors: "Alle Sectoren",
      industry: "Industrie",
      construction_and_infra: "Bouw & Infra",
      ore: "Offshore Renewable Energy",
      sir: "SIR",
      overview_ore: "Overview of ORE Courses",
      overview_sir: "Overzicht SIR opleidingen",
      all_languages: "Alle Talen",
      course_type: "Training Type",
      no_courses_found: "Er zijn geen trainingen gevonden.",
      interested_in_course: {
        first: "Wij zijn op de hoogte gesteld van je interesse en",
        second: "zullen zo spoedig mogelijk contact met je opnemen.",
        third: "Met vriendelijke groet,",
        fourth: "Deftiq",
      },
      course_available: {
        first: "De training is nu voor je beschikbaar.",
        second: "Met vriendelijke groet,",
        third: "Deftiq",
      },
      catalogue_header_sir: "Overzicht SIR opleidingen",
      course_added_to: "De training is toegevoegd aan ",
      start_up_gone_wrong: "Oeps, er gaat iets mis bij het starten.",
      terms: { title: "Ben je akkoord met de voorwaarden?" },
    },
    invoice_viewer: {
      back: "terug",
      all_references: "Alle bonnummers",
      no_reference: "Geen bonnummer",
      contract_email: "Contract e-mail",
      all_invoice_emails: "Alle factuur e-mails",
      invoice_email: "Factuur e-mail",
      period: "Periode",
      fields: {
        description: "Omschrijving",
        amount: "Aantal",
        price: "Prijs",
        cost: "Bedrag",
        name: "Naam",
        startdate: "Startdatum",
        examdate: "Examendatum",
      },
      subtotal: "Subtotaal",
      participants_in: "E-learning deelnemers in",
      participants_with_exam: "Deelnemers met een examen in",
    },
    profile: {
      profile: "Profiel",
      skillset: "Skillset",
      terms: "Voorwaarden",
      firstname: "Voornaam",
      lastname: "Achternaam",
      receive_emails:
        "Ik wil emails van Deftiq ontvangen over mijn trainingen.",
      company: "Bedrijf",
      works_at: "Werkt bij",
      added_by: "Toegevoegd door",
      gender: {
        gender: "Geslacht",
        male: "Man",
        female: "Vrouw",
        unknown: "Onbekend",
      },
      reset_password: "Wachtwoord resetten",
      profile_cant_load: "Het profiel kon niet worden geladen.",
      profile_changes_saved: "Uw profiel wijzigingen zijn opgeslagen.",
      profile_changes_not_saved: "Uw profiel wijzigingen zijn niet opgeslagen.",
      are_you_sure: "Weet je het zeker?",
      delete_all_account_data:
        "Je verwijdert hiermee alle gegevens van je account.",
      cancel_button: "ANNULEREN",
      confirm_button: "VERWIJDEREN",
      account_deleted: "Uw account is verwijderd, tot ziens!",
      account_not_deleted: "Uw account is niet verwijderd.",
      legal: {
        description:
          "<p>Op deze pagina tref je onze voorwaarden en privacy policy aan. <br> Voor vragen kan je contact opnemen met      <a class='uk-text-primary' href='mailto:support@deftiq.com'>support@deftiq.com</a></p>",
        privacy_policy: "Privacy policy",
        general_terms: "Algemene voorwaarden",
        terms_of_use: "Gebruikersvoorwaarden",
      },
    },
    quiz: {
      previous: "Vorige",
      next_button: "volgende",
      close: "AFSLUITEN",
      close_message:
        "Je gaat de quiz vroegtijdig afsluiten. Jouw antwoorden gaan verloren.",
      are_you_sure: "Weet je het zeker?",
      no_changes_after_completion:
        "Als je de quiz inlevert kun je je antwoorden niet meer wijzigen.",
      cancel_button: "ANNULEREN",
      confirm_button: "INLEVEREN",
      finish: "Afronden",
      attempt_wasnt_saved: "Uw quizpoging kon niet worden opgeslagen.",
      fill_answer_first: "vul eerst een antwoord in",
      question: "Vraag",
    },
    quiz_results: {
      attempt_states: { best: "Beste", last: "Laatste", first: "Eerste" },
      course: "Training",
      quiz_results: "Quizresultaten",
      most_recent_grade: "Meest recente cijfer ",
      you_are: "Je bent ",
      passed: "Geslaagd",
      failed: "Gezakt",
      highest_grade: "Hoogst behaalde cijfer ",
      history: "Historie",
      quiz_attempt: "Quizpoging",
      attempt_date: "Datum van uw poging",
      grade: "Cijfer",
      attempt: "poging",
      date: "datum",
      back_to_statistics: "Terug naar statistieken",
      view_earlier_attempts: "Bekijk eerdere pogingen",
      max_attempts_reached: "Maximale aantal pogingen bereikt.",
      new_attempt: "Nieuwe poging",
      questions: "vragen",
      attempts: "pogingen",
      attempt_in_review: "poging wordt beoordeeld",
      needed_to_pass: "nodig voor slagen",
      start_quiz: "Starten",
      no_attempts_left: "Je hebt geen quizpogingen meer over.",
      expires_in_one_hour: "Je sessie verloopt over 1 uur.",
      login_again:
        "Je bent al lang ingelogd. Als je nu start met een quiz kan je progressie verloren gaan. Wij raden je aan eerst opnieuw in te loggen.",
      cancel_button: "ANNULEREN",
      confirm_button: "VERWIJDEREN",
      temporary_grade:
        "Let op, de uitslag van dit examen is nog niet definitief, want de video-opname is nog niet beoordeeld. Zodra de poging is beoordeeld, ontvang je een e-mail met de definitieve uitslag.",
    },
    quiz_feedback: {
      feedback_attempt: "Feedback op uw quizpoging",
      incorrect: "Incorrect",
      correct: "Correct",
      no_feedback_question: "Er is geen feedback bij deze vraag",
      results_overview: "Resultatenoverzicht",
      course: "Training",
      quiz_results: "Quizresultaten",
      quiz_attempt_feedback: "Quizpoging feedback",
      participant: "Deelnemer",
      results: "Resultaten",
    },
    breadcrumb: { overview: "Overzicht" },
    language_picker: {
      course_language: {
        first: "In welke taal wil je de ",
        second: " volgen?",
      },
      action_pick: "Selecteer de taal door op een blokje te drukken.",
      choice_saved: "Je taalkeuze is opgeslagen.",
      went_wrong: "Er is iets misgegaan: ",
    },
    course_expiry_message: {
      header:
        "De {coursename} heeft een looptijd van {days_until_expired} dagen.",
      description:
        "Zodra je een taal hebt gekozen heb je {days_until_expired} dagen de tijd om de training af te ronden. Veel succes!",
    },
    password_reset: {
      instructions:
        "Vul je email adres in, dan sturen wij je instructies om je wachtwoord te wijzigen.",
      no_attempts:
        "U heeft geen pogingen meer. Probeer het over 10 min nogmaals.",
      incorrect_email: "De e-mail is incorrect.",
      unknown_account: "Deftiq account is onbekend.",
      safety_update:
        "Om de veiligheid van het leerplatform te optimaliseren hebben we een veiligheidsupdate uitgevoerd. We helpen hiermee jouw account beter te beschermen.",
      fill_in_code_below: "Vul hieronder jouw 6-cijferige code in",
      fill_in_code: {
        first: "Er is een 6-cijferige code naar jouw e-mailadres ",
        second:
          " gestuurd. Vul deze hieronder in. Controleer ook even je spam folder.",
      },
      code: "Code",
      incorrect_code: "De code is incorrect.",
      extra_security_step:
        "Met deze extra stap wordt jouw account beveiligd. Vul hieronder jouw nieuwe wachtwoord in. Dit wachtwoord moet bestaan uit minimaal 8 karakters",
      fill_in_new_password_below:
        "Vul hieronder jouw nieuwe wachtwoord in. Dit wachtwoord moet bestaan uit minimaal 8 karakters.",
      password_minimum: "Het wachtwoord moet minimaal 8 karakters lang zijn.",
      repeat_password: "Herhaal jouw wachtwoord.",
      passwords_dont_match: "De wachtwoorden komen niet overeen.",
      change_password: "Wachtwoord wijzigen",
      check_email: "Controleer je e-mail",
      cybersecurity_update: "Cybersecurity update",
      new_password: "Bedenk een nieuw wachtwoord",
      send_reset_email: "Stuur reset email",
      change_your_password: "Wijzig wachtwoord",
      no_attempts_left: "U heeft geen pogingen meer.",
      password_changed: "Uw wachtwoord is gewijzigd.",
      something_went_wrong: "Oeps, er gaat iets mis.",
    },
    feedback_button: {
      question: "Heb je een vraag?",
      invalid_email: "Dit is geen valide e-mail.",
      subject: "Onderwerp",
      invalid_subject: "Het onderwerp mag niet leeg zijn.",
      message: "Bericht",
      invalid_message: "Het bericht mag niet leeg zijn.",
      send: "versturen",
      question_thanks: "Dankjewel voor je vraag.",
      support_opened:
        "Deftiq support is bereikbaar tussen 09:00 en 17:00 op werkdagen.",
      received_message_greetings: {
        first:
          "Wij hebben jouw bericht ontvangen. Wij doen ons best om zo snel mogelijk te reageren. Je ontvangt een reactie uiterlijk binnen 1 werkdag.",
        second: "Met vriendelijke groet,",
        third: "Anne,",
        fourth: "Support team",
      },
      ask_question: "Stel je vraag",
    },
    birth_date: {
      birthdate: "Geboortedatum",
      eightteen_or_older: "Ben je 18 jaar of ouder?",
      fill_in_birthdate: "Vul je geboortedatum in",
      birth_date_mandatory:
        "Om deze training te doen hebben wij je geboortedatum nodig.",
      birth_date_general:
        "Deze leeromgeving is alleen toegankelijk voor meerderjarige deelnemers.",
      birth_date_message:
        "Vul de geboortedata van de onderstaande deelnemers in. Dit is noodzakelijk voor deelname aan het examen.",
    },
    waiting_list: {
      fill_in_info: "Fill in your information",
      notification:
        "We will send you a notification when the course is published.",
      invalid_email: "The email is invalid",
      send: "SEND",
      added_to_list: "You have been added to the waiting list.",
      something_went_wrong: "Oops, something went wrong.",
    },
    register_popup: {
      firstname: "Voornaam",
      firstname_mandatory: "De voornaam is verplicht.",
      lastname: "Achternaam",
      lastname_mandatory: "De achternaam is verplicht.",
      company: "Bedrijf",
      company_mandatory: "Het bedrijf is verplicht.",
      language: "Taal",
      conditions_agree: {
        first: " Ik heb de ",
        second: " voorwaarden ",
        third: " en het",
        fourth: "privacy beleid",
        fifth: " gelezen en ga hiermee akkoord.",
      },
      agreed_mandatory: "U moet akkoord gaan met de algemene voorwaarden.",
      verification_code: {
        first: "We hebben een verificatiecode gestuurd naar ",
        second:
          " (check ook even je spam). Vul hieronder je code in en klik op verder.",
      },
      code: "Code",
      incorrect_code: "De code is incorrect.",
      fill_in_info: "Vul uw gegevens in",
      verify_email: "Verifieer uw email",
      check_info: "Controleer jouw gegevens",
      register: "Registreer",
      verify: "Verifieer",
      email_verified: "Uw email is geverifieerd.",
    },
    bookingpage: {
      select_all: "Selecteer alles",
      back: "Terug",
      cancel: "Annuleren",
      next: "Volgende",
      confirm: "Bevestigen",
      pay: "Betalen",
      search: "Zoeken",
      exam: "EXAMEN",
      exam_lowercase: "Examen",
      add_course_from_catalogue: "Training toevoegen uit catalogus",
      messages: {
        add_exam_completed: "Het toevoegen van het examen is geslaagd.",
        update_exam_completed: "Het wijzigen van het examen is geslaagd.",
        update_elearning_completed:
          "Het wijzigen van de e-learning is geslaagd.",
        booking_failed:
          "Er is iets misgegaan bij het verwerken van je boeking.\n Neem contact op met support@deftiq.com ",
        process_booking: "Je boeking wordt verwerkt.",
        pay_for_booking: "Je wordt doorgestuurd naar de betaalpagina.",
        patience_please: "Een ogenblik geduld alstublieft",
        booking_completed: "De deelnemers zijn ingeschreven.",
      },
      part_of_sir:
        "*Indien jouw organisatie lid is van de Stichting Industriële Reiniging (SIR) kan je contact opnemen voor een korting van 100 euro op het examen.",
      filters: {
        all_courses: "Alle trainingen",
        free_courses: "Gratis trainingen",
        premium_courses: "Premium trainingen",
        all_sectors: "Alle sectoren",
        all_languages: "Alle talen",
        industry: "Industrie",
        construction: "Bouw & Infra",
        offshore: "Offshore Renewable Energy",
      },
      close: {
        are_you_sure: "Weet je het zeker?",
        description:
          "Je staat op het punt de boeking vroegtijdig af te breken. De boekingsgegevens gaan verloren.",
        yes: "JA",
        no: "NEE",
      },
      header: {
        select_an_option: "Selecteer een optie",
        select_course: "Selecteer een training",
        select_participants: "Selecteer één of meerdere deelnemers",
        add_participant: "Voeg nieuwe deelnemers toe",
        select_wishes: "Selecteer jouw trainingswensen",
        confirm_info: "Gegevens bevestigen",
        course_details: "Details - ",
        add_courses: "Selecteer één of meer trainingen",
      },
      available_from: "vanaf",
      options: {
        e_learning: "E-learning",
        e_learning_and_exam: "E-learning met Examen",
        exam: "Examen",
      },
      payment_information: {
        header: "Boekingsgegevens",
        course: "Training",
        course_type: "Type training",
        amount_of_users: "Aantal deelnemers",
        location: "Locatie",
        examlanguage: "Examentaal",
        examdate: "Datum examen",
        e_learning_available: "Vrijgave e-learning",
        e_learning_expiry: "Verloop e-learning",
        payment_method: "Betaalwijze",
        payment_message:
          "Je kan direct online betalen via onze partner Mollie.",
      },
      invoice_information: {
        header: "Factuurgegevens",
        company_name: "Bedrijfsnaam*",
        company_required: "De bedrijfsnaam is verplicht.",
        contactperson: "Contactpersoon*",
        contactperson_required: "Een contactpersoon is verplicht.",
        invoice_email: "Factuur e-mailadres*",
        invoice_email_required: "Een email is verplicht.",
        contactperson_email: "E-mailadres contactpersoon*",
        contactperson_email_required: "Een email is verplicht.",
        street: "Straatnaam en huisnummer*",
        street_required: "De straatnaam en het huisnummer zijn verplicht.",
        city: "Stad*",
        city_required: "De stad is verplicht.",
        postalcode: "Postcode*",
        postalcode_required: "De postcode is verplicht.",
        company: "Bedrijf",
        free_users: "De deelnemers kunnen kosteloos deelnemen.",
        price: "Prijs",
        transaction_cost: "Transactiekosten",
        cancel_costs: "Annuleringskosten",
        payment_method: "Betaalwijze",
        ordernumber: "Bonnummer",
      },
      cost: { total: "Totaal", subtotal: "Subtotaal", vat: "BTW" },
      courses: {
        course: "Training",
        sector: "Sector",
        language: "Taal",
        type: "Type",
      },
      users: {
        enroll: "Inschrijven",
        fullname: "Volledige naam",
        email: "E-mailadres",
      },
      add_new_user: "Nieuwe deelnemer toevoegen",
      add_all_users: "Alle deelnemers selecteren",
      policy: {
        agree_with: " Ik ga akkoord met de",
        general_policy: "algemene voorwaarden",
        course_policy: "trainingsvoorwaarden",
        and: "en",
        exam_policy: "examenvoorwaarden",
      },
      moment_picker: {
        all_cities: "Alle steden",
        all_languages: "Alle talen",
        all_months: "Alle maanden",
        select_moment: "Selecteer het gewenste examenmoment",
        no_moments: "Geen beschikbare momenten gevonden.",
        months: {
          january: "januari",
          february: "februari",
          march: "maart",
          april: "april",
          may: "mei",
          june: "juni",
          july: "juli",
          august: "augustus",
          october: "october",
          september: "september",
          november: "november",
          december: "december",
        },
        months_short: {
          january: "JAN",
          february: "FEB",
          march: "MRT",
          april: "APR",
          may: "MEI",
          june: "JUN",
          july: "JUL",
          august: "AUG",
          october: "OKT",
          september: "SEPT",
          november: "NOV",
          december: "DEC",
        },
        days: {
          monday: "Ma",
          tuesday: "Di",
          wednesday: "Wo",
          thursday: "Do",
          friday: "Vr",
          saturday: "Za",
          sunday: "Zo",
        },
      },
    },
    add_user: {
      firstname: "Voornaam",
      lastname: "Achternaam",
      email: "E-mail",
      works_at: "Werkt bij",
      extra_info: "Extra informatie",
      language: "Taal",
      company: "Bedrijf",
      role: "Rol",
      birthdate: "Geboortedatum",
      required: {
        firstname: "Een voornaam is verplicht.",
        lastname: "Een achternaam is verplicht.",
        email: "De e-mail is ongeldig.",
        works_at: "Vul een bedrijf in.",
        birthdate:
          "Vul een geldige geboortedatum in. Een deelnemer moet 18 jaar of ouder zijn.",
        birthdate_short: "Vul een geldige geboortedatum in.",
      },
    },
    expired: "verlopen",
    course_language: {
      update: "Wijzig taal",
      are_you_sure: "Weet je het zeker?",
      description:
        "Je start opnieuw met de e-learning als je een andere taal kiest.",
      cancel: "ANNULEREN",
      pick_language: "TAAL WIJZIGEN",
    },
    administration_overview: {
      bookings: "Boekingen",
      consumption: "Verbruik",
      contracts: "Contracten",
      add_contract: "Contract toevoegen",
      purchase: "Inkoop",
      external_courses: "Externe trainingen",
      external_enrollments: "Externe aanmeldingen",
      admin: "Administratie",
      my_admin: "Mijn administratie",
    },
    participant_overview: {
      participants: "Deelnemersoverzicht",
      add_participant: "Deelnemer toevoegen",
      add_bulk: "Bulk toevoegen",
      delete_participant: "Deelnemer verwijderen",
      columns: {
        id: "Id",
        fullname: "Volledige naam",
        email: "E-mailadres",
        works_at: "Werkt bij",
        role: "Rol",
        status: "Status",
        added_by: "Toegevoegd door",
        registration_date: "Registratiedatum",
      },
      no_participants: "Je hebt nog geen deelnemers.",
      enrollments: "Inschrijvingen",
      profile_control: "Profielbeheer",
      roles: {
        participant: "Deelnemer",
        controller: "Beheerder",
        admin: "Admin",
        examinator: "Examinator",
      },
      state: { active: "actief", inactive: "inactief" },
      breadcrumb_user: "Deelnemers",
      breadcrumb_admin: "Mijn deelnemers",
      errors: { delete: "De deelnemers zijn niet verwijderd." },
      succes: { delete: "De deelnemers zijn verwijderd." },
    },
    total_overview: { participant: "Deelnemer", breadcrumb: "Totaaloverzicht" },
    participant_profile: {
      cannot_login:
        "Deze deelnemer heeft nog geen e-mail uitnodiging gehad, en kan nog niet inloggen.",
      profile: "Profiel",
      enrollments: "Inschrijvingen",
      extra_info: "Aanvullende informatie",
      interaction_time: "Leertijd statistieken",
      labels: {
        participants: "Deelnemers",
        my_participants: "Mijn Deelnemers",
        company_participants: "Bedrijfsdeelnemers",
      },
      state: {
        active: "actief",
        inactive: "inactief",
        label: "De deelnemer is",
      },
      search: "Zoeken",
      save: "Opslaan",
      cancel: "Annuleren",
      status: {
        all: "Alle statussen",
        passed: "Afgerond",
        failed: "Gezakt",
        expired: "Verlopen",
        busy: "Bezig",
        registered: "Niet gestart",
        scheduled: "Ingepland",
      },
      columns: {
        coursename: "Trainingsnaam",
        type: "Type training",
        status: "Status",
        booked_by: "Geboekt door",
        startdate: "Startdatum",
        enddate: "Einddatum",
      },
      actions: {
        see_results: "Resultaten inzien",
        update_booking: "Boeking wijzigen",
        reset_password: "Wachtwoord resetten",
        view_course: "Training bekijken",
        deftiq_emails:
          "De deelnemer wil emails van Deftiq ontvangen over de trainingen.",
      },
      fields: {
        firstname: "Voornaam",
        lastname: "Achternaam",
        company: "Bedrijf",
        added_by: "Toegevoegd door",
        works_at: "Werkt bij",
        role: "Rol",
        email: "E-mailadres",
        birthdate: "Geboortedatum",
        registration_date: "Registratiedatum",
      },
      errors: {
        firstname: "De voornaam mag niet leeg zijn.",
        lastname: "De achternaam mag niet leeg zijn.",
        no_results: "resultaten konden niet worden geladen.",
        user_updated: "de deelnemer is gewijzigd",
        user_not_updated: "de deelnemer is niet gewijzigd",
        password_changed: "Het wachtwoord is gewijzigd.",
        password_not_changed: "Het wachtwoord is niet gewijzigd.",
        invalid_email: "Dit e-mailadres wordt al gebruikt.",
      },
    },
    skill_overview: { total: "Totaaloverzicht", skils: "Vaardigheden" },
  },
  en: {
    next: "Next",
    email: "E-mail",
    password: "Password",
    error: {
      default: "Oops something went wrong. Please contact support@deftiq.com",
    },

    mail_overview: "E-mail overview",
    search: "Search",
    add: "Add",
    continue: "Continue",
    language: "Language",
    save: "Save",
    cancel: "Cancel",
    finished: "completed",
    busy: "busy",
    failed: "failed",
    scheduled: "scheduled",
    not_started: "not started",
    standard_table: {
      filter: "Filter",
      export: "Export",
      import: "Import",
      adjust_view: "Change view",
      column_select: "Column selection",
      column_order: "Column order",
      filter_view: "Filter view",
      select_minimal_column: "Select at least one value.",
    },
    scheduled_exams: {
      exams: "Exams",
      scheduled_exams: "Scheduled exams",
      columns: {
        name: "Examname",
        party: "Examprovider",
        confirmed: "Confirmed",
        examdate: "Examdate",
        participant: "Participant",
        status: "Status",
      },
      status: {
        passed: "passed",
        failed: "failed",
        not_started: "not started",
        no_show: "absent",
        waiting: "awaiting",
        confirmed: "confirmed",
        not_confirmed: "not confirmed",
      },
    },
    reports: {
      report: "Report",
      create_report: "Create Report",
      build_steps: {
        select_report_type: "Select a report type",
        select_template: "Select an existing or new report",
        select_participant: "Select one or more participants",
        select_training: "Select one or more trainings",
        select_column: "Select one or more columns",
      },
      templates: {
        all_grades: "Grades for all trainings",
        learn_duration: "Learning duration for all trainings",
      },
      columns: {
        added_by: "Added to training by",
        expired_date: "Expiration date",
        total_interaction_time: "Total interaction time",
      },
      errors: {
        load_training: "The trainings could not be loaded.",
      },
    },
    company_page: {
      company_information: "company information",
      direct_access: "direct access",
      company_name: "company name",
      legal_company_name: "Legal company name",
      errors: {
        company_name: "The company name is incorrectly entered.",
        legal_company_name: "The legal company name is not correctly entered.",
        primairy_color: "The primary color is not a HEX code.",
        secondary_color: "The secondary color is not a HEX code.",
        street: "A street is required.",
        city: "City",
        country: "Country",
        postal_code: "Enter a valid postal code.",
        email: "The email is incorrect.",
        no_participants: "This company has no participants yet.",
        course_change: "Your changes have failed.",
        changed_company: "The company has been changed.",
      },
      messages: {
        in_construction: "The training is under construction.",
        course_added: "The training has been added.",
        course_deleted: "The training has been deleted.",
        copy_url: "The URL has been copied.",
        changed_company: "The company has not been changed.",
      },
      sender: "Sender (name beneath email)",
      login_url: "Unique login URL",
      primairy_color: "#Primary color",
      secondary_color: "#Secondary color",
      street: "Enter a street.",
      city: "City",
      country: "Country",
      postal_code: "Postal code",
      catalogue_access:
        "Participants of this company have access to the catalogue.",
      SIR_member: "is a member of the Industrial Cleaning Foundation (SIR)",
      exam_provider: "is an exam provider.",
      exam_provider_settings: "Settings for exam providers.",
      no_direct_communication:
        "does not communicate directly with participants.",
      no_terms: "No terms",
      exam_terms: "Exam terms",
      exam_state_mail: "Email for exam status",
      exam_confirmation_mail: "Email for exam confirmation",
      exam_billing_mail: "Email for exam purchase orders",
      exam_application_mail: "Email for exam registration",
      exam_finish_mail: "Email after completing exams",
      click: "Click",
      to_add_participant: "to add a participant.",
      abbreviation: "Abbreviation",
      direct_access_url: "Direct Access URL",
      direct_access_code: "Direct Access code",
      name: "name",
      copy_url: "copy URL",
      code: "code",
      standard_direct_access: "Standard Direct Access (via app.deftiq.com)",
      bouwpas_direct_access: "Bouwpas Direct Access (via Bouwpas)",
      goworkforce_direct_access: "Goworkforce Direct Access (via Goworkforce)",
      no_access_instructions: "This company has no project instructions.",
      my_company: "My Company",
    },
    error_page: {
      general: "Oops, something went wrong.",
      not_found:
        "You have been directed to a page that does not exist (anymore). We are happy to get you back on track.",
      invite_expired:
        "This invitation link is expired or incorrect. Check your mailbox for another invitation link. <br> Can't figure it out? Please reach out to support@deftiq.com",
      direct_closed:
        "Your organization's direct access to this training course has been closed. <br>Contact support@deftiq.com if you are supposed to have access.",
    },
    interaction_time: {
      coursename: "Coursename",
      year: "Year",
      message:
        "Overview of the learning time in minutes. The learning time is the time that a participant spends on activities within an e-learning.",
    },
    login: {
      welcome: "Welcome to ",
      fill_in_password: "Enter your password",
      fill_in_email_below: "Please enter your e-mail address below.",
      fill_in_password_below: "Please enter your password below.",
      create_an_account: "Create an account",
      forgot_password: "Password forgotten",
      open_invite: "Open invitation",
      login: "login",
      errors: {
        wrong_password_or_email: "E-mail address or password is incorrect.",
        no_account_yet:
          "We could not find an account with this e-mail address.",
        open_invite:
          "There is an invitation waiting for you to register yourself. This was sent again to",
        open_invite_short:
          "There is an invitation waiting for you to register yourself.",
      },
      session_expired: "Your session has expired",
    },
    bulk_upload: {
      csv_upload: "upload CSV file",
      download_template: "download template",
      direct_invite: "Directly send an e-mail invite to new participants.",
      steps: {
        upload_file: "Upload a CSV file",
        check_file: "Check your CSV file",
        process_file: "Your file is being processed",
      },
      columns: { language: "Language", status: "Status", reason: "Reason" },
    },
    upload_popup: {
      attachment_upload: "Upload attachment",
      filename: "Filename",
      description: "Description",
    },
    external_course_list: {
      external_courses: "External courses",
      common_info: "General information",
      attachments: "Attachments",
      add_new_attachment: "Add new attachment",

      coursename: "Coursename",
      participant: "Participant",
      status: "Status",
      grade: "Grade",
      valid_until: "Valid until",
      description: "Description",
      uploads: "Attachment count",
    },
    invoice_viewer: {
      back: "back",
      all_references: "All receipt numbers",
      no_reference: "No receipt number",
      contract_email: "Contract email",
      all_invoice_emails: "All invoice emails",
      invoice_email: "Invoice email",
      period: "Period",
      fields: {
        description: "Description",
        amount: "Amount",
        price: "Price",
        cost: "Cost",
        name: "Name",
        startdate: "Starting date",
        examdate: "Exam date",
      },
      subtotal: "Subtotal",
      participants_in: "E-learning participants in",
      participants_with_exam: "Participants with an exam in",
    },
    training: {
      attempts_left: "attempts left",
      extra_info_required:
        "The information below is required to start with {coursename}",
      activity_locked:
        "This activity is not available yet. Make sure you have completed the previous activities.",
      activity_finished: "Completed",
      activity_failed: "Failed",
      activity_not_started: "not started",
      activity_doing: "busy",
      course_passed: {
        first: "You have passed the course",
        second: " with a score of ",
        percent: " %.",
      },
      download_certificate: " Download your proof of participation here.",
      no_attempts_left: "You have no more attempts to start the activity.",
      no_rise_training_available: "The activity contains no RISE training.",
    },
    navbar: {
      student: {
        my_overview: "My Overview",
        my_results: "My Results",
        my_profile: "My Profile",
        catalogue: "Catalogue",
        overview: "Overview",
        results: "Results",
        profile: "Profile",
      },
      supervisor: {
        my_dashboard: "My Dashboard",
        my_courses: "My Courses",
        my_participants: "My Participants",
        my_administration: "My Administration",
        catalogue: "Catalogue",
        my_profile: "My Profile",
        helpcenter: "Help Center",
        dashboard: "Dashboard",
        profile: "Profile",
        courses: "Courses",
        participants: "Participants",
        my_flows: "My Flows",
      },
      admin: {
        dashboard: "Dashboard",
        companies: "Companies",
        courses: "Courses",
        participants: "Participants",
        contracts: "Contracts",
        flows: "Flows",
        catalogue: "Catalogue",
        emails: "E-mails",
        profile: "Profile",
      },
      system_status: "System Status",
      logout: "Logout",
      profile_admin: "Profile",
      add_course: "Book a course",
      session_expires: {
        in_one_hour: "Your session expires in 1 hour.",
        expired: "Your session has expired.",
      },
    },
    question_editor: {
      activity: "Activity",
      rich_text: "Rich Text",
      question: "Question",
      are_you_sure: "Are you sure?",
      warning_message: "This will completely delete the question.",
      question_deleted: "The question has been deleted.",
      question_not_deleted: "The question has not been deleted",
      good_answer: "Good answer",
      wrong_answer: "Wrong answer",
      correct_feedback: "Feedback if correct (optional)",
      bad_feedback: "Feedback if incorrect (optional)",
      errors: {
        question: "A question cannot be blank.",
        answer: "An answer cannot be blank.",
        question_not_changed: "The question was not changed",
        question_not_added: "The question was not added",
      },
      messages: {
        question_changed: "The question has been changed",
        question_added: "The question has been added",
      },
    },
    update_quiz: {
      courses: "Training courses",
      update_quiz: "Edit quiz",
      add_questions: "Add questions",
      structure: "Quiz structure",
      fields: {
        name: "Name",
        description: "The description of the quiz",
        progress: "The quiz counts toward the progress of the training course.",
        has_video: "The quiz has video recordings.",
        correct_answer_visible:
          "The correct answer will be visible during perusal.",
        feedback_visible:
          "Feedback for the questions is visible during perusal.",
        attempts: "Attempts management",
        max_attempts: "Maximum number of attempts",
        amount_questions: "Number of questions during quiz attempt",
        quiz_completion: "Completion quiz",
        endless_attempts:
          "New quiz attempts will be available until someone passes.",
        num_days_after_attempts:
          "Number of days until new attempts are available.",
        attempts_example:
          " Example: a value of 1 means a participant can make another attempt 1 day after the last quiz attempt.",
        percentage_to_pass: "Percentage needed to pass quiz",
        valid_percentage: "Enter a number between 0 and 100",
        restrictions: "Access restrictions",
      },
      states: { opened: "Geopend", passed: "Geslaagd", completed: "Afgerond" },
      restrictions: {
        hover_text: "Hover text of a locked activity.",
        if: "If",
        is: "is",
        open_for: ", then open the access for",
      },
      messages: {
        update_failed: "The quiz could not be edited.",
        restriction_impossible: "The restriction is impossible.",
        restriction_exists: "The restriction already exists.",
        restriction_added: "The restriction has been added.",
        restriction_not_added: "The restriction cannot be added.",
        restriction_deleted: "The restriction has been removed.",
        restriction_not_deleted: "The restriction has not been removed.",
        quiz_changed: "The quiz has been edited.",
        quiz_not_changed: "The quiz was not edited.",
        are_you_sure: "Are you sure?",
        quiz_not_deleted: "The quiz cannot be deleted.",
      },
    },
    richtext_activity: {
      continue_learning: "  Press the button and continue your learning path",
      continue: "Continue",
    },
    updaterichtext: {
      update_course: "Update training course",
      update_activity: "Update activity",
      update: "update",
      structure: "Activity structure",
      settings: "Settings",
      configuration: "Configuration",
      name: "Name",
      progress: "Progress",
      counts_for_progress:
        "The activity counts towards the progress of the training course.",
      restrictions: {
        title: "Access restrictions",
        locked_text: "Hover text when activity locked.",
        is: "is",
        if: "If",
        open_access_for: ", then open access for the activity",
        restriction_added: "The restriction has been added.",
        restriction_not_added: "The restriction cannot be added.",
        restriction_removed: "The restriction has been removed.",
        restriction_not_removed: "The restriction has not been removed.",
      },
      activity_updated: "The activity has been updated.",
      activity_update_failed: "The activity could not be updated.",
    },
    richtextbuilder: {
      options: {
        paragraph: "paragraph",
        italic: "italic",
        bold: "bold",
        ordered_list: "ordered list",
        horizontal_rule: "Horizontal rule",
        undo: "Undo",
        redo: "Redo",
      },
      headers: {
        text: "Text",
        uploads: "Uploads",
        alignment: "Alignment",
        listview: "List view",
        layout: "Layout",
        general: "General",
        preview: "Preview",
      },
      preview_message_desktop: "You are viewing the desktop preview.",
      preview_message_mobile: "You are viewing the mobile preview.",
    },
    richtextbuilder_popup: {
      give_url_for: "Enter a URL for the",
      or_give_url: "Or enter a URL",
      upload_a: "Upload a",
      upload: "Upload",
      cancel: "Cancel",
      add: "Add",
    },
    flow: {
      which_course: "Which training course?",
      input_placeholder: "Flow name",
      days_placeholder: "Number of days",
      error_message: "A flow cannot have a blank name.",
      confirmation: {
        title: "Are you certain?",
        description:
          "Some rules have been filled in, but have not been added yet.",
      },
      tooltip: {
        name: "Training course name.",
        sector: "Training course sector.",
        language: "Training course languages.",
        type: "Training course type.",
      },
      settings: "Flow settings",
      expired_content_policy:
        " Add new participants to training courses with a starting date in the past",
      finished_list: {
        title: "Flow is finished when:",
        all: "All training courses have been completed.",
        particular: "A specific training course has been completed.",
      },
      restriction: "Restrictions",
      condition_list: {
        title: "Type of condition",
        direct: { title: "Directly available" },
        status: {
          title: "Status training course",
          start_expression: "Start as",
          middle_expression: "is",
        },
        date: {
          title: "Starting date",
          start_from: "Start from",
          start_at: "Start at",
        },
        relative_date: {
          title: "Relative starting date",
          start_expression: "Start",
          middle_expression: "days after",
          end_expression: "is",
          opened: "Opened",
          finished: "Completed",
        },
      },
      create: "Create flow",
      details: "View training course details",
      description_list: {
        first: "Name your flow",
        second:
          "Select at least two training courses that are part of the flow",
        third: "Add restrictions to {flowname}",
        fourth: "View training course details.",
      },
      rule_conflict:
        "The rules are in conflict with each other. Remove rules to let participants start.",
      missing_error: "Please fill in all fields.",
      past_date_error: "The rule cannot be created. The date is in the past.",
      recursive_error:
        "A training course cannot have a rule in relation to itself.",
      already_exists_error: "A similar rule has already been added.",
      other_rule_error: "Use the 'Status training course' rule",
      reminder_title: "Are you certain?",
      reminder_message:
        "This will remove any rules that have been added to this training course.",
      created_message: "The flow has been created.",
      failed_message: "Oops, the flow has not been created.",
      updated_message: "The flow was updated.",
      not_updated_message: "The flow was not updated.",
    },
    flow_details: {
      overview: "Overview",
      settings: "Settings",
      status: "Status",
      flow_settings: "Flow settings",
      flow_name: "Flowname",
      flow_is_active: "This flow is active.",
      edit_courses: "Update courses and restrictions of this flow.",
      no_course_access:
        "The participant does not have access to this e-learning yet.",
      label_list: {
        not_started: "NOT STARTED",
        busy: "IN PROGRESS",
        completed: "COMPLETED",
        started: "STARTED",
        passed: "PASSED",
        not_passed: "NOT PASSED",
      },
    },
    flow_overview: {
      columns: {
        name: "Name",
        status: "Status",
        amount_courses: "Number of training courses",
        amount_users: "Number of participants",
      },
      no_flows_to_export: "There are no flows to export",
      reminder_title: "Are you certain?",
      reminder_message:
        "This will also remove all future flow components. Existing components remain available.",
      cancel_button: "CANCEL",
      confirm_button: "REMOVE",
      created_message: "The flow has been removed.",
      failed_message: "Oops, the flow has not been removed.",
    },
    flow_booking: {
      title_list: {
        first: "Add participants to ",
        second: "Overview and confirmation",
        third: "Add participant",
      },
      description_list: {
        first: "Select participants to add to the flow ",
        second: "View and confirm your booking.",
        third: "Add a participant",
      },
      created_message: "The participants have been added to the flow.",
      failed_message: "Oops, the participants have not been added to the flow.",
    },
    update_course: {
      passed_for: "You passed the course {coursename} with a score of ... % 🎉",
      headers: {
        course_structure: "Training course structure",
        edit_course: "Update training course",
      },
      select_language: "Select a language to change.",
      language_description:
        "Select a language of the training course whose content you wish to update.",
      delete: "Remove",
      update: "Change",
      duplicate: "Duplicate",
      reach_out_deftiq: "Reach out to Deftiq to update this activity.",
      coursename: "Training course name",
      no_empty_coursename: "The training course name cannot be blank.",
      description: "Training course description",
      language: "Language",
      status: {
        status: "Status",
        live: "Ready for use",
        building: "In progress",
      },
      messages: {
        course_updated: "The course has changed.",
        course_not_updated: "The training course cannot be updated.",
        module_added: "The module has been added.",
        module_not_added: "The module could not be added.",
        activity_added: "The activity has been added.",
        quiz_added: "The quiz has been added.",
      },
      delete_module: {
        are_you_sure: "Are you sure?",
        description:
          "This will also remove all registrations, modules & activities associated with this training.",
        cancel: "CANCEL",
        delete: "REMOVE",
      },
      delete_activity: {
        description:
          "This will also delete all activities associated with this module.",
      },
    },
    results: {
      results: "Results",
      title_course_name: "The name of the course",
      course: "Course",
      title_course_status: "Training status",
      course_status: "Status",
      title_course_progress: "Your progression",
      course_progress: "Progression(%)",
      title_course_grade: "Your achieved result",
      course_grade: "Grade",
      title_course_finish_date: "Course end date",
      course_finish_date: "End date",
      no_courses_finished_yet: "You have not yet completed any courses.",
      results_could_not_load: "The results could not be loaded.",
    },
    booking_course_details: {
      category: "Category",
      status: "Status",
      no_info_available:
        "   There is no information available for this e-learning. Reach out to us, if you have any questions.",
    },
    course_settings: {
      select_company: "Select a company",
      support_email: "Support e-mail address",
      invalid_email: "The e-mail address is not valid.",
      forward_email: "E-mail forwarding",
      forward_emails: "Forward e-mail addresses",
      forward_email_message:
        "Completion e-mails from this course will be forwarded to the e-mail addresses below. Press enter to add an e-mail address.",
    },
    invoice_list: {
      message:
        "The number of bookings is automatically tracked by the system. The information is subject to change.",
      search: "Search",
      columns: {
        invoice: "Invoice",
        period: "Period",
        no_access: "You do not have access to the invoices module",
      },
    },
    participant_skills: {
      type: "Type",
      after_intake: "After the intake",
      after_finish_course: "After completing the course",
      no_skills_found: "No skills found",
    },
    exambooking_widget: {
      examtype: "Exam type",
      location: "Location",
      disclaimer:
        "*The available dates above are subject to change. You will be informed as soon as possible if unexpected changes occur.",
    },
    examselection_widget: {
      location: "LOCATION",
      no_exams_found: "No exams found",
      selected: "Selected",
      location_field: "Location:",
      disclaimer:
        "*The available dates above are subject to change. You will be informed as soon as possible if unexpected changes occur.",
    },
    examviewer: {
      exam: "Exam",
      course: "Course",
      languages: "Available languages",
      update: "Change",
      delete: "Remove",
    },
    gender_input: { pick_gender: "Choose a gender." },
    day_picker: {
      all_cities: "All cities",
      all_months: "All months",
      all_languages: "All languages",
      first_date: "First available date:",
    },
    contract_list: {
      search: "Search",
      columns: {
        name: "Name",
        company: "Organisation",
        startdate: "Effective date",
        enddate: "End date",
        status: "Status",
      },
      states: { active: "ACTIVE", inactive: "terminated" },
    },
    booking_list_tab: {
      search: "Search",
      columns: {
        booking: "Booking",
        participant: "Participant",
        course: "Course",
        type: "Type",
        language: "Language",
        works_at: "Works at",
        status: "Status",
        bookingdate: "Booking date",
        booked_by: "Booked by",
      },
      status_labels: {
        paid: "completed",
        expired: "expired",
        open: "open",
        created: "created",
        failed: "failed",
        canceled: "cancelled",
        invoice: "completed",
        needs_processing: "processing required",
        exam: "EXAM",
      },
    },
    country_input: {
      country: "Country",
      country_error: "Select a country.",
      goodmorning: "Good morning",
    },
    feedback_list: {
      questions: "Questions from participants",
      status: "Status:",
      fields: {
        fullname: "Full name:",
        email: "E-mail address:",
        page: "Page:",
        date: "Date:",
        browser: "Browser:",
        sent_to: "Sent to:",
        send: "Send",
        cancel: "Cancel",
      },
      are_you_sure: "Are you sure?",
      info_message: "This will remove all questions.",
      cancel: "CANCEL",
      delete: "REMOVE",
    },
    booking_list: {
      status_labels: {
        paid: "paid",
        expired: "expired",
        open: "open",
        created: "created",
        failed: "failed",
        canceled: "cancelled",
        invoice: "invoice",
        needs_processing: "processing required",
      },
      has: "has",
      descriptions: {
        booking_created: "booked {type} .",
        retry_course: "restarted  {coursename} of {firstname} {lastname} .",
        cancel_course: "cancelled {coursename} of {firstname} {lastname} .",
        cancel_exam: " cancelled {name} of {firstname} {lastname}.",
        add_exam: "booked {name}  for {firstname} {lastname}.",
        update_exam: "changed {name}  for {firstname} {lastname}.",
        update_course: "changed {coursename}  for {firstname} {lastname}.",
        extend_course: "extended {coursename}  for {firstname} {lastname}.",
      },
    },
    catelogue_popup: {
      category: "Category",
      status: "Status",
      status_labels: {
        live: "live",
        available: "available",
        in_construction: "under construction",
      },
    },
    update_booking: {
      info: "Booking details",
      course: "E-learning:",
      flow: "Flow:",
      start_course: "Start e-learning:",
      expired_elearning: "Expiry e-learning:",
      examdate: "Exam date:",
      examlocation: "Exam location:",
      examstatus: "Exam status:",
      exam_status: {
        confirmed: "Confirmed",
        refused: "Denied",
        waiting: "Not confirmed",
      },
      examsupplier: "Exam provider:",
      examlanguage: "Exam language:",
      participant: "Participant:",
      invoice_email: "Invoice e-mail address:",
      order_number: "Receipt number:",
      payment_method: "Payment method:",
      payment_status: { mollie: "Mollie", invoice: "Invoice" },
      booked_by: "Booked by:",
      contactperson: "Contact name:",
      email_contactperson: "E-mail address contact",
      street: "Street:",
      postal_code: "Zip code:",
      city: "City:",
      country: "Country:",
      history: "History",
      fully_cancelled: "The booking has been cancelled completely.",
      failed: "The booking has failed. Try again.",
      back: "Back",
      next: "Next",
      actions: {
        update_exam: "Modify exam",
        add_exam: "Add exam",
        extend_course: "Extend course",
        retry_course: "Restart",
        cancel_course: "Cancel course",
        cancel_exam: "Cancel exam",
        book_again: "Rebooking",
      },
      action_descriptions: {
        booking_created: "Booking created",
        retry_course: "E-learning restarted",
        cancel_course: "E-learning cancelled",
        cancel_exam: "Exam cancelled",
        add_exam: "Exam added",
        update_exam: "Exam modified",
        update_course: "E-learning modified",
        extend_course: "E-learning extended",
      },
      update_booking: "Modification of booking",
      cancelled_booking: "cancelled booking",
      exam_only: "Exam only",
      exam: "Exam",
      booking: "Booking",
      contract: "Contract",
      disruption_title: "You can disrupt the flow with this action.",
      disruption_message:
        "This course is part of a flow and cannot be cancelled individually.",
      cancel_button: "BACK",
      confirm_button: "CONTINUE",
    },
    confirm_booking: {
      info: "Booking details",
      invoice_info: "Invoice data",
      fields: {
        company_name: "Company name*",
        contactperson: "Contact*",
        invoice_email: "Invoice e-mail address*",
        street: "Street name and house number*",
        city: "City*",
        postalcode: "Zip code*",
        order_number: "Receipt number",
        extend_until: "Extend until*",
      },
      free_action: "You will not be charged for this action.",
      price: "Price",
      total: "Total:",
      free: "Free",
      with_vat: "incl. VAT",
      errors: {
        company_name: "Company name is required.",
        contactperson: "A contact person is required.",
        invoice_email: "An e-mail address is required.",
        street: "Street name and house number are required.",
        city: "The city is required.",
        postalcode: "Zip code is required.",
        order_number: "Your order number is incorrect",
        no_extension: "Access was not renewed.",
        exam_not_cancelled: "The exam has not been cancelled.",
      },
      terms: {
        agree_with: "I agree to the",
        general_terms: "general terms and conditions",
        and: "and",
        course_terms: "course conditions",
        exam_terms: "exam conditions",
      },
      actions: {
        extend: "Confirm extension",
        retry_course: "Confirm restart",
        cancel_course: "Confirm e-learning cancellation ",
        cancel_exam: "Confirm exam cancellation",
      },
      action_descriptions: {
        extend: "extend {coursename} for {firstname} {lastname} by 14 days",
        retry_course: "have {firstname} {lastname} start {coursename} again.",
        cancel_course: "  cancel {coursename} for {firstname} {lastname}.",
        cancel_exam: "Exam cancellation for {firstname} {lastname}.",
      },
      messages: {
        start_again: "The participant can start again",
        extend_access: "Access has been extended.",
        participant_cancel: "The participant has been deregistered.",
        exam_cancel: "The exam has been cancelled.",
      },
      back: "Back",
      confirm: "Confirm",
    },
    proctor_viewer: {
      attempt_for: "Exam attempt for {coursename} at {attempted_at}",
      add_description: "Clarify why you are (not) declaring the attempt valid.",
      validate: "Approve",
      invalidate: "Reject",
      finish: "Complete",
    },
    participant_insights: {
      insights: "Insights",
      results: "Results",
      progress: "Progress",
      quiz_attempts: "Quiz attempts",
      activity: " Activity",
      apart_of: " Part of",
      attempt: "Attempt",
      status_label: "Status",
      grade: "Grade",
      end_date: "End date",
      download_certificate: "Download certificate",
      status: {
        passed: "completed",
        busy: "in progress",
        failed: "failed",
        not_started: "not started",
        no_show: "absent",
      },
    },
    contract: {
      admin: "Administration",
      my_admin: "My Administration",
      overview: "Overview",
      update_contract: "Edit contract",
      invoice_template: "Quotation template",
      info: {
        contract_info: "Contract information",
        company: "Organisation",
        email: "E-mail address",
        invoice_number: "Purchase order number",
        type: "Type",
        startdate: "Start date",
        enddate: "End date",
      },
      parameters: {
        title: "Contract parameters",
        amount_participants: "Number of participants",
        amount_custom_courses: "Number of tailor-made e-learnings",
        price_extra_participant: "Price per additional participant",
        price_extra_course: "Price per additional tailor-made e-learning",
        only_started: "Only count participants who have started",
      },
    },
    proctor_overview: {
      welcome: "Welcome",
      startdate: "Start time",
      enddate: "End time",
      message_par1: "    The list below shows exam attempts you can review.",
      message_par2: "    Press the eye to start reviewing an exam attempt.",
      course: "Course",
      activity: "Activity",
      no_attempts: "There are no exam attempts to review.",
      attempts_error: "Oops! The attempts could not be loaded.",
    },
    dashboard: {
      add_flow: "Add flow",
      add_course: "Create your own e-learning (Beta)",
      recent_actions: "Recent actions by participants",
      load_more_actions: "Load more actions",
      add_participant: "Enroll participants",
      matrix_overview: "View Matrix Overview",
      total: "Total",
      kpi: {
        new_participants: "New participants",
        new_enrollments: "New applications",
        passed_participants: "Successful participants",
        enrollments: "Applications",
      },
      months: {
        january: "January",
        february: "February",
        march: "March",
        april: "April",
        may: "May",
        june: "June",
        july: "July",
        august: "August",
        september: "September",
        october: "October",
        november: "November",
        december: "December",
      },
      events: {
        passed: "{firstname} has completed the {coursename}.",
        opened: "{firstname} has opened the {coursename}.",
        registered: "{firstname} has been signed up for the {coursename}. ",
        created: "{firstname} has created an account. ",
      },
    },
    training_page: {
      settings: "Settings",
      search: "Search",
      status: {
        failed: "Failed",
        expired: "Expired",
        planned: "Scheduled",
        busy: "In progress",
        finished: "Completed",
        registered: "Not started",
      },
      startdate: "start date",
      view_results: "View results",
      update_booking: "Change booking",
      participants: "Participants",
      all_status: "All statuses",
      columns: {
        fullname: "Full name",
        company: "Organisation",
        course_status: "Status",
        enddate: "End date",
        startdate: "Start date",
        grade: "Grade",
      },
    },
    catalogue_popup: {
      sign_up: "Enroll +",
      duration: "Duration",
      language: "Language",
      price_per_person: "Price p.p.",
      save: "save",
      cancel: "cancel",
      course_modified: "The course has changed.",
      course_not_modified: "The course cannot be changed.",
      status_labels: {
        available: "Available",
        in_construction: "In construction",
      },
      category: "Category",
      status: "Status",
    },
    training_overview: {
      exams: "Exams",
      course_overview: "course overview",
      add_course: "Add course",
      requirements: "Conditions",
      question_pool: "Question pool",
      search: "Search",
      columns: {
        name: "Name",
        shortname: "Abbreviation",
        sector: "Sector",
        supported_languages: "Language",
        status: "Status",
      },
      translate_add: "Add language",
      fields: {
        coursename: "Course name",
        shortname: "Abbreviation",
        description: "Course description",
        language: "Language",
        course_price: "Price",
        course_photo: "Upload course photo",
        course_days_expired:
          "After how many days do participants lose access to the course?",
        course_days_reminder_not_opened: "Reminder unopened course",
        course_days_reminder_not_done: "Reminder unfinished course",
        course_days_reminder_expired:
          "After how many days do participants receive a reminder about losing access to the course?",
        course_passed_grade:
          "What grade must a person get for this course to pass?(percentage)",
        is_consumer_course:
          "The course is available to administrators in the catalog.",
        course_duration: "Course duration",
        is_project_instruction:
          "The course has direct access without participant authorization",
      },
      view: "View",
      evaluations: "Evaluations",
      update: "Change",
      duplicate: "Duplicate",
      delete: "Remove",
      no_search_results: "No search results found.",
      add: "Add",
      cancel: "Cancel",
      participant_preview: "Participant Preview",
      settings: "Settings",
      errors: {
        coursename: "The course name cannot be blank.",
        shortname: "The abbreviation cannot be blank.",
        description: "A description is required.",
        primary_color: "The primary color is not a HEX code.",
        secondary_color: "The secondary color is not a HEX code.",
        integer_days: "The value is not a whole number of days.",
        certificate: "Upload a certificate background (optional)",
        integer_price: "The value is not a whole number.",
        lead_time: "The course duration cannot be blank.",
        direct_access: "The course has direct access",
        no_watch_course: "The course cannot be viewed.",
      },
      messages: {
        no_courses_to_export: "No course to export.",
        preview_course: "You are now viewing the course as a participant.",
      },
    },
    evaluation: {
      your_anwser: "Your answer",
      anwser_question_mandatory: "You must answer this question.",
      submit_evaluation: "Submit",
      thank_you_message: "Thank you for completing the evaluation.",
      continue_with_course: "You can continue your learning path",
    },
    register: {
      email_code_auth: "Enter the code you received via e-mail here.",
      code: "Code",
      incorrect_code: "The code is incorrect",
      enter_email_and_continue:
        "Enter your e-mail address below and press next.",
      incorrect_email: "The e-mail address is incorrect.",
      enter_password_for_login: "Enter your password to log in.",
      firstname: "First name",
      firstname_mandatory: "Your first name is required.",
      lastname: "Surname",
      lastname_mandatory: "Your last name is required.",
      company: "Organisation",
      company_mandatory: "Organisation is required.",
      preference_lang_courses: "Preferred platform language",
      conditions: {
        first: " I have read and agree to ",
        second: " terms ",
        third: " and the ",
        fourth: " privacy policy ",
        fifth: " .",
      },
      accept_conditions_mandatory:
        "You must agree to the terms and conditions.",
      password_must_contain: "The password must contain at least 8 characters.",
      repeat_password: "Re-enter your password.",
      passwords_dont_match: "The passwords do not match.",
      identity_check: {
        first:
          "Just checking if it's really you. We sent a verification code to ",
        second: " (Check your spam too).",
      },
      resent_invitation:
        "There is another invitation waiting for you to register yourself. This was sent again to ",
      forgot_password: "Password forgotten?",
      switch_case_data: {
        fill_in_code: "Enter your code",
        welcome_to: "Welcome to ",
        fill_in_data: "Enter your details",
        fill_in_password: "Enter your password",
        think_of_password: "Create a password",
        open_invite: "Open invitation",
        extra_information: "Extra information",
      },
      register: "Register",
      login: "Login",
      page_doesnt_exist: "Oops, this page doesn't exist.",
      page_load_error: "Something went wrong while loading the page.",
      admin_account_already_exists: "You already have an administrator account",
      invite_not_accepted: "You haven't accepted your invitation yet.",
      gone_wrong_contact_deftiq:
        "Oops, something went wrong. Contact support@deftiq.com",
    },
    register_no_course: {
      become_supervisor: "Become an administrator!",
      become_student: "Become a participant",
      account_already_exists_login:
        "You already have an existing account to log in.",
      account_already_exists_as: "You already have an existing account as ",
      give_your_organisation:
        "And give your organisation access to our interactive learning environment and unique courses.",
      get_access_courses:
        "And gain access to a rich catalogue filled with unique courses.",
      fill_in_email_supervisor:
        "Fill in your e-mail and take a look at our platform. Free of charge.",
      fill_in_email_participant: "Fill in your e-mail and press next.",
    },
    invite: {
      intro_message: "You have been invited to create an account. ",
      password_message: "A password must contain at least 8 characters ",
      header: {
        intro: "Welcome to ",
        password: "Think of a password",
        check: "Check your information",
      },
      fields: {
        email: "E-mail",
        email_validation: "The e-mail is incorrect.",
        password: "Password",
        password_validation: "A password must have at least 8 characters.",
        repeat_password: "Repeat your password",
        repeat_password_validation: "The passwords do not match",
        firstname: "Firstname",
        firstname_validation: "The firstname is required.",
        lastname: "Lastname",
        lastname_validation: "The lastname is required ",
        language: "Language",
      },
      terms: {
        first_sentence: " I have read the ",
        terms: "terms",
        second_part: "and the ",
        privacy_policy: "privacy policy",
        final_sentence: " and accept the contents.",
        validation:
          "You have to accept the privacy policy and terms and conditions.",
      },
      completed: "Register",
    },
    catalogue: {
      catalogue: "Catalogue",
      all_sectors: "All Sectors",
      industry: "Industry",
      construction_and_infra: "Construction & Infra",
      ore: "Offshore Renewable Energy",
      sir: "SIR",
      overview_ore: "Overview of ORE Courses",
      overview_sir: "Overview of SIR Courses",
      all_languages: "All Languages",
      course_type: "Course Type",
      no_courses_found: "No courses were found.",
      interested_in_course: {
        first: "We are aware of your interest and",
        second: "will contact you as soon as possible.",
        third: "Sincerely,",
        fourth: "Deftiq",
      },
      course_available: {
        first: "The course is now available to you.",
        second: "Sincerely,",
        third: "Deftiq",
      },
      catalogue_header_sir: "Overview of SIR Courses",
      course_added_to: "The course has been added to ",
      start_up_gone_wrong: "Oops, something goes wrong when starting.",
      terms: "Conditions",
    },
    profile: {
      profile: "Profile",
      skillset: "Skillset",
      firstname: "First name",
      lastname: "Surname",
      gender: {
        gender: "Gender",
        male: "Male",
        female: "Female",
        unknown: "Unknown",
      },
      receive_emails: "I want to receive emails from Deftiq about my courses.",
      company: "Organisation",
      reset_password: "Reset password",
      profile_cant_load: "The profile could not be loaded.",
      profile_changes_saved: "Your profile changes have been saved.",
      profile_changes_not_saved: "Your profile changes have not been saved.",
      are_you_sure: "Are you sure?",
      delete_all_account_data:
        "You will thereby delete all data from your account.",
      cancel_button: "CANCEL",
      confirm_button: "DELETE",
      account_deleted: "Your account has been deleted. Goodbye!",
      account_not_deleted: "Your account has not been deleted.",
      legal: {
        description:
          "<p>On this page you can find our terms and conditions. <br> For questions please reach out to     <a class='uk-text-primary' href='mailto:support@deftiq.com'>support@deftiq.com</a></p>",
        privacy_policy: "Privacy policy",
        general_terms: "General terms and conditions",
        terms_of_use: "Terms of use",
      },
      terms: "Conditions",
      works_at: "Works at",
      added_by: "Added by",
    },
    quiz: {
      previous: "Previous",
      next_button: "next",
      close: "CLOSE",
      close_message:
        "You are about to close the quiz before it has been completed, your answers will be lost. ",
      are_you_sure: "Are you sure?",
      no_changes_after_completion:
        "You cannot change answers once you have submitted the test.",
      cancel_button: "CANCEL",
      confirm_button: "SUBMIT",
      finish: "Complete",
      attempt_wasnt_saved: "Your quiz attempt could not be saved.",
      fill_answer_first: "Fill in an answer first.",
      question: "Question",
    },
    quiz_results: {
      attempt_states: { best: "Best", last: "Last", first: "First" },
      course: "Course",
      quiz_results: "Quiz Results",
      most_recent_grade: "Most recent grade ",
      you_are: "You have ",
      passed: "Passed",
      failed: "Failed",
      highest_grade: "Highest grade achieved ",
      history: "History",
      quiz_attempt: "Quiz attempt",
      attempt_date: "Date of your attempt",
      grade: "Grade",
      attempt: "attempt",
      date: "date",
      back_to_statistics: "Back to statistics",
      view_earlier_attempts: "View attempts",
      max_attempts_reached: "Maximum number of attempts reached.",
      new_attempt: "New attempt",
      questions: "questions",
      attempts: "attempts",
      attempt_in_review: "attempt is assessed",
      needed_to_pass: "needed to pass",
      start_quiz: "Start",
      no_attempts_left: "You have no quiz attempts left.",
      expires_in_one_hour: "Your session expires in 1 hour.",
      login_again:
        "You've been logged in for a long time. Your progress may be lost if you start a quiz right now. We recommend you log in again first.",
      cancel_button: "CANCEL",
      confirm_button: "DELETE",
      temporary_grade:
        "The result of this exam is subject to change, once the video recording has been graded, you will receive an email with the final result. ",
    },
    quiz_feedback: {
      feedback_attempt: "Feedback on your quiz attempt",
      incorrect: "Incorrect",
      correct: "Correct",
      no_feedback_question: "There is no feedback with this question",
      results_overview: "Results Overview",
      course: "Course",
      quiz_results: "Quiz results",
      quiz_attempt_feedback: "Quiz attempt feedback",
      participant: "Participant",
      results: "Results",
    },
    breadcrumb: { overview: "Overview" },
    language_picker: {
      course_language: {
        first: "In which language would you like to take ",
        second: " ?",
      },
      action_pick: "Select the language by pressing a box.",
      choice_saved: "Your language selection has been saved.",
      went_wrong: "Something went wrong: ",
    },
    course_expiry_message: {
      header: "The {coursename} has a duration of {days_until_expired} days.",
      description:
        "Once you have chosen a language, you have {days_until_expired} days to complete the course. Good luck!",
    },
    password_reset: {
      instructions:
        "Enter your email address and we will send you instructions to reset your password.",
      no_attempts: "You have no more attempts. Please try again in 10 minutes.",
      incorrect_email: "The e-mail address is incorrect.",
      unknown_account: "Deftiq account is unknown.",
      safety_update:
        "We performed a security update to optimise the security of the learning platform. This makes your account even better protected.",
      fill_in_code_below: "Enter your 6-digit code below",
      fill_in_code: {
        first: "A 6-digit code has been sent to your e-mail address ",
        second:
          " . Enter the code below. Make sure to also check your spam folder.",
      },
      code: "Code",
      incorrect_code: "The code is incorrect.",
      extra_security_step:
        "This additional step secures your account. Please enter a new password below. This password must consist of at least 8 characters",
      fill_in_new_password_below:
        "Please enter a new password below. This password must consist of at least 8 characters.",
      password_minimum: "The password must consist of at least 8 characters.",
      repeat_password: "Re-enter your password.",
      passwords_dont_match: "The passwords do not match.",
      change_password: "Change password",
      check_email: "Check your email",
      cybersecurity_update: "Cybersecurity update",
      new_password: "Create a new password",
      send_reset_email: "Send reset e-mail",
      change_your_password: "Change password",
      no_attempts_left: "You have no more attempts.",
      password_changed: "Your password has been changed.",
      something_went_wrong: "Oops, something is going wrong.",
    },
    feedback_button: {
      question: "Do you have a question?",
      invalid_email: "This is not a valid e-mail address.",
      subject: "Subject",
      invalid_subject: "The subject should not be blank.",
      message: "Message",
      invalid_message: "The message should not be blank.",
      send: "send",
      question_thanks: "Thank you for your question.",
      support_opened:
        "Deftiq support is available during 09:00 en 17:00 on working days.",
      received_message_greetings: {
        first:
          "We have received your question. We will try our best to answer your question as soon as possible.",
        second: "Kind regards,",
        third: "Anna,",
        fourth: "On behalf of team ",
      },
      ask_question: "Ask your question",
    },
    birth_date: {
      birthdate: "Date of birth",
      eightteen_or_older: "Are you over 18 years old?",
      fill_in_birthdate: "Enter your date of birth",
      birth_date_mandatory:
        "Your date of birth is required to be able to participate in this course.",
      birth_date_general:
        "This learning environment is only accessible to participants of legal age.",
      birth_date_message:
        "Enter the dates of birth of the participants listed below. This is necessary to be able to participate in the exam.",
    },
    waiting_list: {
      fill_in_info: "Enter your details",
      notification:
        "We will send you a notification when the course is published.",
      invalid_email: "The e-mail address is invalid",
      send: "SEND",
      added_to_list: "You have been added to the waiting list.",
      something_went_wrong: "Oops, something went wrong.",
    },
    register_popup: {
      firstname: "First name",
      firstname_mandatory: "Your first name is required.",
      lastname: "Surname",
      lastname_mandatory: "Your last name is required.",
      company: "Organisation",
      company_mandatory: "Organisation is required.",
      language: "Language",
      conditions_agree: {
        first: " I have read and agree to ",
        second: " terms ",
        third: " and the",
        fourth: "privacy policy",
        fifth: " .",
      },
      agreed_mandatory: "You must agree to the terms and conditions.",
      verification_code: {
        first: "We sent a verification code to ",
        second:
          " (Check your spam too). Enter your code below and press continue.",
      },
      code: "Code",
      incorrect_code: "The code is incorrect.",
      fill_in_info: "Enter your details",
      verify_email: "Verify your e-mail address",
      check_info: "Check your details",
      register: "Register",
      verify: "Verify",
      email_verified: "Your e-mail address has been verified.",
    },
    bookingpage: {
      select_all: "Select all",
      back: "Back",
      cancel: "Cancel",
      next: "Next",
      confirm: "Confirm",
      pay: "Pay",
      search: "Search",
      exam: "EXAM",
      exam_lowercase: "Exam",
      add_course_from_catalogue: "Add course from catalogue",
      messages: {
        add_exam_completed: "You have succesfully added the exam.",
        update_exam_completed: "You have succesfully updated the exam.",
        update_elearning_completed:
          "You have succesfully updated the e-learning.",
        booking_failed:
          "Something went wrong while processing your booking.\n Contact support@deftiq.com ",
        process_booking: "Your booking will be processed.",
        pay_for_booking: "You will be redirected to the payment page.",
        patience_please: "One moment, please.",
        booking_completed: "The participants have been enrolled.",
      },
      part_of_sir:
        "*If your organisation is part of the Stichting Industriële Reiniging (SIR), then you can reach out to us for a 100 euro discount on the exam.",
      filters: {
        all_courses: "All courses",
        free_courses: "Free courses",
        premium_courses: "Premium courses",
        all_sectors: "All sectors",
        all_languages: "All languages",
        industry: "Industry",
        construction: "Construction & Infrastructure",
        offshore: "Offshore Renewable Energy",
      },
      close: {
        are_you_sure: "Are you sure?",
        description:
          "You are about to cancel the booking early. The booking data will be lost.",
        yes: "YES",
        no: "NO",
      },
      header: {
        select_an_option: "Select an option",
        select_course: "Select a training course",
        select_participants: "Select one or more participants",
        add_participant: "Add a new participant",
        select_wishes: "Select your training needs",
        confirm_info: "Confirm data",
        course_details: "Details - ",
        add_courses: "Select one of more courses",
      },
      available_from: "from",
      options: {
        e_learning: "E-learning",
        e_learning_and_exam: "E-learning with Exam",
        exam: "Exam",
      },
      payment_information: {
        header: "Booking details",
        course: "Course",
        course_type: "Type of training",
        amount_of_users: "Number of participants",
        location: "Location",
        examlanguage: "Examlanguage",
        examdate: "Date of exam",
        e_learning_available: "Release e-learning",
        e_learning_expiry: "Expiry e-learning",
        payment_method: "Payment method",
        payment_message: "You can pay directly online via our partner Mollie.",
      },
      invoice_information: {
        ordernumber: "Receipt number",
        header: "Invoice data",
        company_name: "Company name*",
        company_required: "Company name is required.",
        contactperson: "Contact*",
        contactperson_required: "A contact person is required.",
        invoice_email: "Invoice email address*",
        invoice_email_required: "An email address is required.",
        contactperson_email: "Contact email address*",
        contactperson_email_required: "An email address is required.",
        street: "Street name and house number*",
        street_required: "Street name and house number are required.",
        city: "City*",
        city_required: "City is required.",
        postalcode: "Zip code*",
        postalcode_required: "Zip code is required.",
        company: "Organisation",
        free_users: "Participants can access the course free of charge.",
        price: "Price",
        transaction_cost: "Transaction costs",
        cancel_costs: "Cancellation costs",
        payment_method: "Payment method",
      },
      cost: { total: "Total", subtotal: "Subtotal", vat: "VAT" },
      courses: {
        course: "Course",
        sector: "Sector",
        language: "Language",
        type: "Type",
      },
      users: {
        enroll: "Enroll",
        fullname: "Full name",
        email: "E-mail address",
      },
      add_new_user: "Add new participant",
      add_all_users: "Select all participants",
      policy: {
        agree_with: "I agree to the",
        general_policy: "terms and conditions,",
        course_policy: "training conditions",
        and: "and",
        exam_policy: "exam conditions",
      },
      moment_picker: {
        all_cities: "All cities",
        all_languages: "Alle languages",
        all_months: "Alle months",
        select_moment: "Select the desired moment of examination",
        no_moments: "No options are available.",
        months: {
          january: "January",
          february: "February",
          march: "March",
          april: "April",
          may: "May",
          june: "June",
          july: "July",
          august: "August",
          october: "October",
          september: "September",
          november: "November",
          december: "December",
        },
        months_short: {
          january: "JAN",
          february: "FEB",
          march: "MAR",
          april: "APR",
          may: "MAY",
          june: "JUN",
          july: "JUL",
          august: "AUG",
          october: "OCT",
          september: "SEP",
          november: "NOV",
          december: "DEC",
        },
        days: {
          monday: "Mon",
          tuesday: "Tue",
          wednesday: "Wed",
          thursday: "Thu",
          friday: "Fri",
          saturday: "Sat",
          sunday: "Sun",
        },
      },
    },
    add_user: {
      firstname: "First name",
      lastname: "Last name",
      email: "E-mail address",
      works_at: "Works at",
      extra_info: "Additional information",
      language: "Language",
      company: "Organisation",
      role: "Role",
      birthdate: "Date of birth",
      required: {
        firstname: "A first name is required.",
        lastname: "A last name is required.",
        email: "The e-mail address is invalid.",
        works_at: "Enter a company.",
        birthdate:
          "Enter a valid date of birth. A participant must be 18 years of age or older.",
        birthdate_short: "Enter a valid date of birth.",
      },
    },
    expired: "expired",
    course_language: {
      update: "Edit language",
      are_you_sure: "Are you sure?",
      description:
        "You will restart the e-learning if you choose another language.",
      cancel: "CANCEL",
      pick_language: "EDIT LANGUAGE",
    },
    course_overview: {
      add_course: "Add a course",
      access_expired: "Access expired",
      access_scheduled: "Scheduled",
      percent_completed: "% completed.",
      no_available_courses: "You have no available courses.",
      course_overview_cant_load: "The course overview could not be loaded.",
      active_courses: "Active courses",
      expired_courses: "Expired courses",
      all_courses: "All courses",
    },
    administration_overview: {
      bookings: "Bookings",
      consumption: "Consumption",
      contracts: "Contracts",
      add_contract: "Add contract",
      purchase: "Procurement",
      external_courses: "External courses",
      admin: "Administration",
      my_admin: "My Administration",
    },
    participant_overview: {
      participants: "Participant overview",
      add_participant: "Add participant",
      add_bulk: "Add bulk",
      delete_participant: "Delete participant",
      columns: {
        id: "Id",
        fullname: "Full name",
        email: "E-mail address",
        works_at: "works at",
        role: "Role",
        status: "Status",
        added_by: "Added by",
        registration_date: "Registration date",
      },
      no_participants: "You don't have any participants yet.",
      enrollments: "Registrations",
      profile_control: "Profile Management",
      roles: {
        participant: "Participant",
        controller: "Administrator",
        admin: "Admin",
        examinator: "Examiner",
      },
      state: { active: "active", inactive: "inactive" },
      breadcrumb_user: "Participants",
      breadcrumb_admin: "My participants",
      errors: { delete: "The participants are not removed." },
      succes: { delete: "The participants are removed." },
    },
    total_overview: {
      participant: "Participant",
      breadcrumb: "Complete overview",
    },
    participant_profile: {
      cannot_login:
        "This user cannot log in yet, as no invite e-mail has been sent",
      profile: "Profile",
      enrollments: "Registrations",
      labels: {
        participants: "Participants",
        my_participants: "My Participants",
        company_participants: "Company participants",
      },
      state: {
        active: "active",
        inactive: "inactive",
        label: "The participant is",
      },
      interaction_time: "Study time statistics",
      extra_info: "Additional information",
      search: "Search",
      save: "Save",
      cancel: "Cancel",
      status: {
        all: "All statuses",
        passed: "Completed",
        failed: "Failed",
        expired: "Expired",
        busy: "In progress",
        registered: "Not started",
        scheduled: "Scheduled",
      },
      columns: {
        coursename: "Course name",
        type: "Type of course",
        status: "Status",
        booked_by: "Booked by",
        startdate: "Start date",
        enddate: "End date",
      },
      actions: {
        see_results: "View results",
        update_booking: "Change booking",
        reset_password: "Reset password",
        view_course: "View course",
        deftiq_emails:
          "The participant wants to receive e-mails from Deftiq about their courses.",
      },
      fields: {
        firstname: "First name",
        lastname: "Last name",
        company: "Organisation",
        added_by: "Added by",
        works_at: "Works at",
        role: "Role",
        email: "E-mail address",
        birthdate: "Date of birth",
        registration_date: "Registration date",
      },
      errors: {
        firstname: "The first name cannot be blank.",
        lastname: "The last name cannot be blank.",
        no_results: "results could not be loaded.",
        user_updated: "the participant has been changed",
        user_not_updated: "the participant has not been changed",
        password_changed: "The password has been changed.",
        password_not_changed: "The password has not been changed.",
        invalid_email: "The email address is already in use.",
      },
    },
    skill_overview: {
      total: "Complete overview",
      translate_skills: "Vaardigheden vertalen",
      add_skills: "Vaardigheden toevoegen",
      skils: "Skills",
    },
  },
  de: {
    next: "Weiter",
    email: "E-Mail",
    password: "Passwort",
    error: {
      default:
        "Upps, da ist was schief gelaufen. Bitte kontaktieren Sie support@deftiq.com",
    },
    search: "Suchen",
    add: "Hinzufügen",
    continue: "Fortfahren",
    language: "Sprache",
    save: "Speichern",
    cancel: "Abbrechen",
    finished: "abgeschlossen",
    busy: "In Bearbeitung",
    scheduled: "geplant",
    not_started: "nicht gestartet",
    company_page: {},
    error_page: {
      general: "Upps, etwas ist schief gelaufen.",
      not_found:
        "Sie wurden zu einer Seite weitergeleitet, die nicht (mehr) existiert. Wir bringen Sie gerne wieder auf den richtigen Weg.",
      invite_expired:
        "Dieser Einladungslink ist abgelaufen oder falsch. Überprüfen Sie Ihr Postfach auf einen weiteren Einladungslink. <br> Sie finden es nicht heraus? Bitte wenden Sie sich an support@deftiq.com",
      direct_closed:
        "Der direkte Zugang Ihrer Organisation zu diesem Lehrgang wurde geschlossen. <br>Wenden Sie sich an support@deftiq.com, wenn Sie Zugriff haben sollen.",
    },
    login: {
      welcome: "Willkommen bei ",
      fill_in_password: "Geben Sie Ihr Passwort ein",
      fill_in_email_below: "Bitte geben Sie unten Ihre E-Mail-Adresse ein.",
      fill_in_password_below: "Bitte geben Sie unten Ihr Passwort ein.",
      create_an_account: "Konto erstellen",
      forgot_password: "Passwort vergessen",
      open_invite: "Einladung öffnen",
      login: "einloggen",
      errors: {
        wrong_password_or_email: "E-Mail-Adresse oder Passwort ist falsch.",
        no_account_yet:
          "Wir konnten kein Konto mit dieser E-Mail-Adresse finden.",
        open_invite:
          "Sie haben eine Einladung erhalten, um sich zu registrieren. Folgendes wurde erneut gesendet an",
        open_invite_short:
          "Sie haben eine Einladung erhalten, um sich zu registrieren.",
      },
      session_expired: "Ihre Sitzung ist abgelaufen",
    },
    richtext_activity: {
      continue_learning:
        "  Drücken Sie die Taste und fahren Sie mit Ihrem Lernpfad fort",
      continue: "Fortfahren",
    },
    booking_course_details: { category: "Kategorie", status: "Status" },
    invoice_viewer: {
      back: "zurück",
      all_references: "Alle Belegnummern",
      no_reference: "Keine Belegnummer",
      contract_email: "Vertrags-E-Mail",
      all_invoice_emails: "Alle Rechnungs-E-Mails",
      invoice_email: "Rechnungs-E-Mail",
      period: "Zeitraum",
      fields: {
        description: "Beschreibung",
        amount: "Betrag",
        price: "Preis",
        cost: "Kosten",
        name: "Name",
        startdate: "Startdatum",
        examdate: "Prüfungsdatum",
      },
      subtotal: "Zwischensumme",
      participants_in: "E-Learning-Teilnehmer in",
      participants_with_exam: "Teilnehmer mit einer Prüfung in",
    },
    course_settings: {
      select_company: "Ein Unternehmen auswählen",
      support_email: "E-Mail-Adresse des Kundendienstes",
      invalid_email: "Die E-Mail-Adresse ist ungültig.",
      forward_email: "E-Mail-Weiterleitung",
      forward_emails: "E-Mail-Adressen weiterleiten",
      forward_email_message:
        "Die E-Mails zum Kursabschluss werden an die unten stehenden E-Mail-Adressen weitergeleitet. Drücken Sie die Eingabetaste, um eine E-Mail-Adresse hinzuzufügen.",
    },
    invoice_list: {
      message:
        "Die Anzahl der Buchungen wird vom System automatisch nachverfolgt. Die Angaben können sich ändern.",
      search: "Suchen",
      columns: {
        invoice: "Rechnung",
        period: "Zeitraum",
        no_access: "Sie haben keinen Zugang auf das Rechnungsmodul",
      },
    },
    participant_skills: {
      type: "Typ",
      after_intake: "Nach der Aufnahme",
      after_finish_course: "Nach dem Kursabschluss",
      no_skills_found: "Keine Fähigkeiten gefunden",
    },
    exambooking_widget: {
      examtype: "Prüfungstyp",
      location: "Standort",
      disclaimer:
        "*Die oben angegebenen verfügbaren Daten können sich ändern. Sie werden so schnell wie möglich informiert, wenn unerwartete Änderungen auftreten.",
    },
    examselection_widget: {
      location: "STANDORT",
      no_exams_found: "Keine Prüfungen gefunden",
      selected: "Ausgewählt",
      location_field: "Standort:",
      disclaimer:
        "*Die oben angegebenen verfügbaren Daten können sich ändern. Sie werden so schnell wie möglich informiert, wenn unerwartete Änderungen auftreten.",
    },
    examviewer: {
      exam: "Prüfung",
      course: "Kurs",
      languages: "Verfügbare Sprachen",
      update: "Ändern",
      delete: "Entfernen",
    },
    gender_input: { pick_gender: "Ein Geschlecht auswählen." },
    day_picker: {
      all_cities: "Alle Städte",
      all_months: "Alle Monate",
      all_languages: "Alle Sprachen",
    },
    contract_list: {
      search: "Suchen",
      columns: {
        name: "Name",
        company: "Organisation",
        startdate: "Gültigkeitsdatum",
        enddate: "Enddatum",
        status: "Status",
      },
      states: { active: "AKTIV", inactive: "beendet" },
    },
    booking_list_tab: {
      search: "Suchen",
      columns: {
        booking: "Buchen",
        participant: "Teilnehmer",
        course: "Kurs",
        type: "Typ",
        language: "Sprache",
        works_at: "Arbeitet bei",
        status: "Status",
        bookingdate: "Buchungsdatum",
        booked_by: "Gebucht von",
      },
      status_labels: {
        paid: "abgeschlossen",
        expired: "abgelaufen",
        open: "öffnen",
        created: "erstellt",
        failed: "fehlgeschlagen",
        canceled: "abgebrochen",
        invoice: "abgeschlossen",
        needs_processing: "Verarbeitung erforderlich",
        exam: "PRÜFUNG",
      },
    },
    updaterichtext: {
      update_course: "Kurs aktualisieren",
      update_activity: "Aktivität aktualisieren",
      update: "aktualisieren",
      structure: "Aktivitätsstruktur",
      settings: "Einstellungen",
      configuration: "Konfiguration",
      name: "Name",
      progress: "Fortschritt",
      counts_for_progress:
        "Die Aktivität zählt für den Fortschritt des Kurses.",
      restrictions: {
        title: "Zugriffsbeschränkungen",
        locked_text:
          "Bewegen Sie den Mauszeiger über den Text, wenn die Aktivität gesperrt ist.",
        is: "ist",
        if: "Wenn",
        open_access_for: ", dann öffnen Sie den Zugang für die Aktivität",
        restriction_added: "Die Einschränkung wurde hinzugefügt.",
        restriction_not_added:
          "Die Einschränkung kann nicht hinzugefügt werden.",
        restriction_removed: "Die Einschränkung wurde entfernt.",
        restriction_not_removed: "Die Einschränkung wurde nicht entfernt.",
      },
      activity_updated: "Die Aktivität wurde aktualisiert.",
      activity_update_failed: "Die Aktivität konnte nicht aktualisiert werden.",
    },
    richtextbuilder: {
      options: {
        paragraph: "absatz",
        italic: "kursiv",
        bold: "fett",
        ordered_list: "geordnete Liste",
        horizontal_rule: "Horizontale Linie",
        undo: "Rückgängig machen",
        redo: "Wiederherstellen",
      },
      headers: {
        text: "Text",
        uploads: "Uploads",
        alignment: "Ausrichtung",
        listview: "Listenansicht",
        layout: "Layout",
        general: "Allgemein",
        preview: "Vorschau",
      },
      preview_message_desktop: "Sie sehen die Desktop-Vorschau.",
      preview_message_mobile: "Sie sehen die mobile Vorschau.",
    },
    richtextbuilder_popup: {
      give_url_for: "Geben Sie eine URL für die",
      or_give_url: "Oder geben Sie eine URL ein",
      upload_a: "Hochladen von",
      upload: "Hochladen",
      cancel: "Abbrechen",
      add: "Hinzufügen",
    },
    flow: {
      which_course: "Welcher Kurs?",
      input_placeholder: "Flow-Name",
      days_placeholder: "Anzahl der Tage",
      error_message: "Ein Flow darf keinen leeren Namen haben.",
      confirmation: {
        title: "Sind Sie sicher?",
        description:
          "Einige Regeln wurden ausgefüllt, aber noch nicht hinzugefügt.",
      },
      tooltip: {
        name: "Name des Kurses.",
        sector: "Bereich des Kurses.",
        language: "Sprachen des Kurses.",
        type: "Art des Kurses.",
      },
      settings: "Flow-Einstellungen",
      expired_content_policy:
        " Neue Teilnehmer zu Kursen mit einem Startdatum in der Vergangenheit hinzufügen",
      finished_list: {
        title: "Der Flow ist beendet, wenn:",
        all: "Alle Kurse abgeschlossen wurden.",
        particular: "Eine spezifischer Kurs abgeschlossen wurde.",
      },
      restriction: "Einschränkungen",
      condition_list: {
        title: "Art der Bedingung",
        direct: { title: "Direkt verfügbar" },
        status: {
          title: "Status des Kurses",
          start_expression: "Starten als",
          middle_expression: "ist",
        },
        date: {
          title: "Startdatum",
          start_from: "Start von",
          start_at: "Starten bei",
        },
        relative_date: {
          title: "Relatives Startdatum",
          start_expression: "Start",
          middle_expression: "Tage nach",
          end_expression: "ist",
          opened: "Offen",
          finished: "Abgeschlossen",
        },
      },
      create: "Flow erstellen",
      details: "Kursdetails anzeigen",
      description_list: {
        first: "Ihren Flow benennen",
        second: "Wählen Sie mindestens zwei Kurse aus, die Teil des Flows sind",
        third: "Einschränkungen zu {flowname} hinzufügen",
        fourth: "Kursdetails anzeigen.",
      },
      rule_conflict:
        "Die Einschränkungen stehen im Widerspruch zueinander. Entfernen Sie Einschränkungen, damit die Teilnehmer starten können.",
      missing_error: "Bitte alle Felder ausfüllen.",
      past_date_error:
        "Die Einschränkung kann nicht erstellt werden. Das Datum liegt in der Vergangenheit.",
      recursive_error:
        "Ein Kurs kann keine Einschränkung in Bezug auf sich selbst haben.",
      already_exists_error:
        "Eine ähnliche Einschränkung wurde bereits hinzugefügt.",
      other_rule_error: "Verwenden Sie die Einschränkung „Status des Kurses“",
      reminder_title: "Sind Sie sicher?",
      reminder_message:
        "Dadurch werden alle Einschränkungen entfernt, die zu diesem Kurs hinzugefügt wurden.",
      created_message: "Der Flow wurde erstellt.",
      failed_message: "Hoppla, der Flow wurde nicht erstellt.",
    },
    flow_details: {
      status: "Status",
      flow_settings: "Flusseinstellungen",
      flow_name: "Flussname",
      flow_is_active: "Dieser Fluss ist aktiv.",
      edit_courses:
        "Aktualisieren Sie Kurse und Einschränkungen dieses Flusses.",
      no_course_access:
        "Der Teilnehmer hat noch keinen Zugang zu diesem E-Learning.",
      label_list: {
        not_started: "NICHT GESTARTET",
        busy: "IN BEARBEITUNG",
        completed: "ABGESCHLOSSEN",
        started: "GESTARTET",
        passed: "BESTANDEN",
        not_passed: "NICHT BESTANDEN",
      },
    },
    flow_overview: {
      columns: {
        name: "Name",
        status: "Status",
        amount_courses: "Anzahl der Kurse",
        amount_users: "Anzahl der Teilnehmer",
      },
      no_flows_to_export: "Es gibt keine Flows zum Exportieren",
      reminder_title: "Sind Sie sicher?",
      reminder_message:
        "Dadurch werden auch alle zukünftigen Flow-Komponenten entfernt. Vorhandene Komponenten bleiben verfügbar.",
      cancel_button: "ABBRECHEN",
      confirm_button: "ENTFERNEN",
      created_message: "Der Flow wurde entfernt.",
      failed_message: "Hoppla, der Flow wurde nicht entfernt.",
    },
    flow_booking: {
      title_list: {
        first: "Teilnehmer hinzufügen zu ",
        second: "Übersicht und Bestätigung",
        third: "Teilnehmer hinzufügen",
      },
      description_list: {
        first: "Teilnehmer auswählen, die zum Flow hinzugefügt werden sollen ",
        second: "Buchung ansehen und bestätigen.",
        third: "Einen Teilnehmer hinzufügen",
      },
      created_message: "Die Teilnehmer wurden dem Flow hinzugefügt.",
      failed_message:
        "Hoppla, die Teilnehmer wurden dem Flow nicht hinzugefügt.",
    },
    country_input: {
      country: "Land",
      country_error: "Ein Land auswählen.",
      goodmorning: "Guten Morgen",
    },
    feedback_list: {
      questions: "Fragen von Teilnehmern",
      status: "Status:",
      fields: {
        fullname: "Vollständiger Name:",
        email: "E-Mail-Adresse:",
        page: "Seite:",
        date: "Datum:",
        browser: "Browser:",
        sent_to: "Gesendet an:",
        send: "Senden",
        cancel: "Abbrechen",
      },
      are_you_sure: "Sind Sie sicher?",
      info_message: "Dadurch werden alle Fragen entfernt.",
      cancel: "ABBRECHEN",
      delete: "ENTFERNEN",
    },
    booking_list: {
      status_labels: {
        paid: "bezahlt",
        expired: "abgelaufen",
        open: "öffnen",
        created: "erstellt",
        failed: "fehlgeschlagen",
        canceled: "abgebrochen",
        invoice: "rechnung",
        needs_processing: "Verarbeitung erforderlich",
      },
      descriptions: {
        booking_created: "{type} gebucht.",
        retry_course:
          " {coursename} von {firstname} {lastname} wurde neu gestartet.",
        cancel_course:
          "{coursename} von {firstname} {lastname} wurde abgebrochen.",
        cancel_exam: "{name} von {firstname} {lastname} wurde abgebrochen.",
        add_exam: "{name} wurde gebucht für {firstname} {lastname}.",
        update_exam: "{name} wurde geändert für {firstname} {lastname}.",
        update_course:
          "{coursename} wurde geändert für {firstname} {lastname}.",
        extend_course:
          "{coursename} wurde erweitert für {firstname} {lastname}.",
      },
    },
    catelogue_popup: {
      category: "Kategorie",
      status: "Status",
      status_labels: {
        live: "live",
        available: "verfügbar",
        in_construction: "im Bau",
      },
    },
    update_course: {
      passed_for:
        "Sie haben den Kurs {coursename} mit einer Bewertung von ... % bestanden 🎉",
      headers: {
        course_structure: "Aufbau des Kurses",
        edit_course: "Kurs aktualisieren",
      },
      select_language: "Wählen Sie eine Sprache, die geändert werden soll.",
      language_description:
        "Wählen Sie eine Sprache des Kurses, deren Inhalte Sie aktualisieren möchten.",
      delete: "Entfernen",
      update: "Ändern",
      duplicate: "Duplizieren",
      reach_out_deftiq:
        "Wenden Sie sich an Deftiq, um diese Aktivität zu aktualisieren.",
      coursename: "Name des Kurses",
      no_empty_coursename: "Der Name des Kurses darf nicht leer sein.",
      description: "Kursbeschreibung",
      language: "Sprache",
      status: { status: "Status", live: "Bereit", building: "In Bearbeitung" },
      messages: {
        course_updated: "Der Kurs wurde geändert.",
        course_not_updated: "Der Kurs kann nicht aktualisiert werden.",
        module_added: "Das Modul wurde hinzugefügt.",
        module_not_added: "Das Modul konnte nicht hinzugefügt werden.",
        activity_added: "Die Aktivität wurde hinzugefügt.",
        quiz_added: "Das Quiz wurde hinzugefügt.",
      },
      delete_module: {
        are_you_sure: "Sind Sie sicher?",
        description:
          "Dadurch werden auch alle Registrierungen, Module und Aktivitäten entfernt, die mit diesem Kurs verbunden sind.",
        cancel: "ABBRECHEN",
        delete: "ENTFERNEN",
      },
      delete_activity: {
        description:
          "Dadurch werden auch alle mit diesem Modul verbundenen Aktivitäten gelöscht.",
      },
    },
    update_booking: {
      info: "Buchungsdetails",
      course: "E-Learning:",
      start_course: "E-Learning starten:",
      examdate: "Prüfungsdatum:",
      examlocation: "Prüfungsort:",
      examstatus: "Prüfungsstatus:",
      exam_status: {
        confirmed: "Bestätigt",
        refused: "Abgelehnt",
        waiting: "Nicht bestätigt",
      },
      examsupplier: "Prüfungsanbieter:",
      examlanguage: "Prüfungssprache:",
      participant: "Teilnehmer:",
      invoice_email: "Rechnungs-E-Mail-Adresse:",
      order_number: "Eingangsnummer:",
      payment_method: "Zahlungsmethode:",
      payment_status: { mollie: "Mollie", invoice: "Rechnung" },
      booked_by: "Gebucht von:",
      contactperson: "Kontaktname:",
      email_contactperson: "E-Mail-Adresse Kontakt",
      street: "Straße:",
      postal_code: "Postleitzahl:",
      city: "Stadt:",
      country: "Land:",
      history: "Historie",
      fully_cancelled: "Die Buchung wurde vollständig abgebrochen.",
      failed: "Die Buchung ist fehlgeschlagen. Versuchen Sie es noch einmal.",
      back: "Zurück",
      next: "Weiter",
      actions: {
        update_exam: "Prüfung ändern",
        add_exam: "Prüfung hinzufügen",
        extend_course: "Kurs verlängern",
        retry_course: "Neustart",
        cancel_course: "Kurs abbrechen",
        cancel_exam: "Prüfung abbrechen",
        book_again: "Umbuchen",
      },
      action_descriptions: {
        booking_created: "Buchung erstellt",
        retry_course: "E-Learning neu gestartet",
        cancel_course: "E-Learning abgebrochen",
        cancel_exam: "Prüfung abgebrochen",
        add_exam: "Prüfung hinzugefügt",
        update_exam: "Prüfung geändert",
        update_course: "E-Learning geändert",
        extend_course: "E-Learning erweitert",
      },
      update_booking: "Änderung der Buchung",
      cancelled_booking: "abgebrochene Buchung",
      exam_only: "Nur Prüfung",
      exam: "Prüfung",
      booking: "Buchen",
      contract: "Vertrag",
    },
    confirm_booking: {
      info: "Buchungsdetails",
      invoice_info: "Rechnungsdaten",
      fields: {
        company_name: "Firmenname*",
        contactperson: "Kontakt*",
        invoice_email: "Rechnungs-E-Mail-Adresse*",
        street: "Straße und Hausnummer*",
        city: "Stadt*",
        postalcode: "Postleitzahl*",
        order_number: "Eingangsnummer",
        extend_until: "Verlängern bis*",
      },
      free_action: "Diese Aktion wird Ihnen nicht in Rechnung gestellt.",
      price: "Preis",
      total: "Insgesamt:",
      free: "Kostenlos",
      with_vat: "inkl. MwSt.",
      errors: {
        company_name: "Firmenname ist erforderlich.",
        contactperson: "Ein Ansprechpartner ist erforderlich.",
        invoice_email: "Eine E-Mail-Adresse ist erforderlich.",
        street: "Straße und Hausnummer sind erforderlich.",
        city: "Die Stadt ist erforderlich.",
        postalcode: "Postleitzahl ist erforderlich.",
        order_number: "Ihre Bestellnummer ist falsch",
        no_extension: "Der Zugang wurde nicht erneuert.",
        exam_not_cancelled: "Die Prüfung wurde nicht abgebrochen.",
      },
      terms: {
        agree_with: "Ich stimme den",
        general_terms: "allgemeinen Geschäftsbedingungen",
        and: "und",
        course_terms: "den Kursbedingungen",
        exam_terms: "den Prüfungsbedingungen zu",
      },
      actions: {
        extend: "Verlängerung bestätigen",
        retry_course: "Neustart bestätigen",
        cancel_course: "E-Learning-Abbruch bestätigen ",
        cancel_exam: "Prüfungsabbruch bestätigen",
      },
      action_descriptions: {
        extend: "{coursename} für {firstname} {lastname} um 14 Tage verlängern",
        retry_course:
          "{firstname} {lastname} bitten, {coursename} wieder zu starten.",
        cancel_course: "  {coursename} für {firstname} {lastname} abbrechen.",
        cancel_exam: "Prüfungsabbruch für {firstname} {lastname}.",
      },
      messages: {
        start_again: "Der Teilnehmer kann neu starten",
        extend_access: "Der Zugang wurde erweitert.",
        participant_cancel: "Der Teilnehmer wurde abgemeldet.",
        exam_cancel: "Die Prüfung wurde abgebrochen.",
      },
      back: "Zurück",
      confirm: "Bestätigen",
    },
    proctor_viewer: {
      attempt_for: "Prüfungsversuch für {coursename} um {attemptted_at}",
      add_description:
        "Stellen Sie klar, warum Sie den Versuch (nicht) für gültig erklären.",
      validate: "Genehmigen",
      invalidate: "Ablehnen",
      finish: "Fertig",
    },
    participant_insights: {
      insights: "Einblicke",
      results: "Ergebnisse",
      progress: "Fortschritt",
      quiz_attempts: "Quizversuche",
      activity: " Aktivität",
      apart_of: " Teil von",
      attempt: "Versuch",
      status_label: "Status",
      grade: "Note",
      end_date: "Enddatum",
      download_certificate: "Zertifikat herunterladen",
      status: {
        passed: "abgeschlossen",
        busy: "in Bearbeitung",
        not_started: "nicht gestartet",
      },
    },
    contract: {
      admin: "Verwaltung",
      my_admin: "Meine Verwaltung",
      overview: "Übersicht",
      update_contract: "Vertrag bearbeiten",
      invoice_template: "Angebotsvorlage",
      info: {
        contract_info: "Vertragsinformationen",
        company: "Organisation",
        email: "E-Mail-Adresse",
        invoice_number: "Bestellnummer",
        type: "Typ",
        startdate: "Startdatum",
        enddate: "Enddatum",
      },
      parameters: {
        title: "Vertragsparameter",
        amount_participants: "Anzahl der Teilnehmer",
        amount_custom_courses: "Anzahl der maßgeschneiderten E-Learnings",
        price_extra_participant: "Preis pro zusätzlichem Teilnehmer",
        price_extra_course:
          "Preis pro zusätzlichem maßgeschneidertem E-Learning",
        only_started: "Nur Teilnehmer zählen, die begonnen haben",
      },
    },
    proctor_overview: {
      welcome: "Willkommen",
      startdate: "Startzeit",
      enddate: "Endzeit",
      message_par1:
        "    In der folgenden Liste finden Sie die Prüfungsversuche, die Sie einsehen können.",
      message_par2:
        "    Drücken Sie auf das Symbol Auge, um die Überprüfung eines Prüfungsversuchs zu starten.",
      course: "Kurs",
      activity: "Aktivität",
      no_attempts: "Es gibt keine Prüfungsversuche zu überprüfen.",
      attempts_error: "Hoppla! Die Versuche konnten nicht geladen werden.",
    },
    dashboard: {
      recent_actions: "Letzte Aktionen der Teilnehmer",
      load_more_actions: "Weitere Aktionen laden",
      add_participant: "Teilnehmer zum Kurs hinzufügen",
      add_flow: "Flow hinzufügen",
      add_course: "Ihr eigenes E-Learning erstellen (Beta)",
      matrix_overview: "Schulungsmatrix anzeigen",
      total: "Insgesamt",
      kpi: {
        new_participants: "Neue Teilnehmer",
        new_enrollments: "Neue Anwendungen",
        passed_participants: "Erfolgreiche Teilnehmer",
        enrollments: "Anwendungen",
      },
      months: {
        january: "Januar",
        february: "Februar",
        march: "März",
        april: "April",
        may: "Mai",
        june: "Juni",
        july: "Juli",
        august: "August",
        september: "September",
        october: "Oktober",
        november: "November",
        december: "Dezember",
      },
      events: {
        passed: "{firstname} hat den {coursename} abgeschlossen.",
        opened: "{firstname} hat den {coursename} geöffnet.",
        registered: "{firstname} wurde für den {coursename} angemeldet. ",
        created: "{firstname} hat ein Konto erstellt. ",
      },
    },
    training_page: {
      settings: "Einstellungen",
      search: "Suchen",
      status: {
        failed: "Fehlgeschlagen",
        expired: "Abgelaufen",
        planned: "Geplant",
        busy: "In bearbeitung",
        finished: "Abgeschlossen",
        registered: "Nicht gestartet",
      },
      startdate: "startdatum",
      view_results: "Ergebnisse anzeigen",
      update_booking: "Buchung ändern",
      participants: "Teilnehmer",
      all_status: "Alle Status",
      columns: {
        fullname: "Vollständiger Name",
        company: "Organisation",
        course_status: "Status",
        enddate: "Enddatum",
        startdate: "Startdatum",
        grade: "Note",
      },
    },
    training_overview: {
      exams: "Prüfungen",
      course_overview: "Kursübersicht",
      add_course: "Kurs hinzufügen",
      requirements: "Bedingungen",
      question_pool: "Fragenpool",
      search: "Suchen",
      columns: {
        name: "Name",
        shortname: "Abkürzung",
        sector: "Bereich",
        supported_languages: "Sprache",
        status: "Status",
      },
      translate_add: "Sprache hinzufügen",
      fields: {
        coursename: "Kursname",
        shortname: "Abkürzung",
        description: "Kursbeschreibung",
        language: "Sprache",
        course_price: "Preis",
        course_photo: "Kursfoto hochladen",
        course_days_expired:
          "Nach wie vielen Tagen verlieren die Teilnehmer den Zugang zum Kurs?",
        course_days_reminder_not_opened:
          "Erinnerung an einen ungeöffneten Kurs",
        course_days_reminder_not_done:
          "Erinnerung an einen nicht beendeten Kurs",
        course_days_reminder_expired:
          "Nach wie vielen Tagen erhalten die Teilnehmer eine Erinnerung, wenn sie den Zugang zum Kurs verlieren?",
        course_passed_grade:
          "Welche Note muss eine Person erhalten, damit dieser Kurs bestanden wird? (Prozentsatz)",
        is_consumer_course:
          "Der Kurs ist für Administratoren im Katalog verfügbar.",
        course_duration: "Kursdauer",
        is_project_instruction:
          "Der Kurs hat direkten Zugang ohne Teilnehmerberechtigung",
      },
      view: "Anzeigen",
      evaluations: "Bewertungen",
      update: "Ändern",
      duplicate: "Duplizieren",
      delete: "Entfernen",
      no_search_results: "Keine Suchergebnisse gefunden.",
      add: "Hinzufügen",
      cancel: "Abbrechen",
      participant_preview: "Teilnehmervorschau",
      settings: "Einstellungen",
      errors: {
        coursename: "Der Kursname darf nicht leer sein.",
        shortname: "Die Abkürzung darf nicht leer sein.",
        description: "Eine Beschreibung ist erforderlich.",
        primary_color: "Die Primärfarbe ist kein HEX-CODE.",
        secondary_color: "Die Sekundärfarbe ist kein HEX-CODE.",
        integer_days: "Der Wert ist keine ganze Anzahl von Tagen.",
        certificate: "Zertifikatshintergrund hochladen (optional)",
        integer_price: "Der Wert ist keine ganze Zahl.",
        lead_time: "Die Kursdauer darf nicht leer sein.",
        direct_access: "Der Kurs hat direkten Zugang",
        no_watch_course: "Der Kurs kann nicht angesehen werden.",
      },
      messages: {
        no_courses_to_export: "Kein Kurs zum Exportieren.",
        preview_course: "Sie sehen sich den Kurs jetzt als Teilnehmer an.",
      },
    },
    training: {
      activity_locked:
        "Diese Aktivität ist noch nicht verfügbar. Stellen Sie sicher, dass Sie die vorherigen Aktivitäten abgeschlossen haben.",
      activity_finished: "Abgeschlossen",
      activity_not_started: "nicht gestartet",
      activity_doing: "In Bearbeitung",
      course_passed: {
        first: "Sie haben den Kurs bestanden",
        second: " mit einer Punktzahl von ",
        percent: " %.",
      },
      download_certificate: " Laden Sie hier Ihren Teilnahmebeweis herunter.",
      no_attempts_left:
        "Sie haben keine weiteren Versuche, die Aktivität zu starten.",
      no_rise_training_available: "Die Aktivität enthält kein RISE Training.",
    },
    navbar: {
      student: {
        my_overview: "Meine Übersicht",
        my_results: "Meine Ergebnisse",
        my_profile: "Mein Profil",
        catalogue: "Katalog",
        overview: "Übersicht",
        results: "Ergebnisse",
        profile: "Profil",
      },
      supervisor: {
        my_dashboard: "Mein Dashboard",
        my_courses: "Meine Kurse",
        my_participants: "Meine Teilnehmer",
        my_administration: "Meine Verwaltung",
        my_flows: "Meine Flows",
        catalogue: "Katalog",
        my_profile: "Mein Profil",
        helpcenter: "Hilfe-Center",
        dashboard: "Dashboard",
        profile: "Profil",
        courses: "Kurse",
        participants: "Teilnehmer",
      },
      admin: {
        dashboard: "Dashboard",
        companies: "Unternehmen",
        courses: "Kurse",
        participants: "Teilnehmer",
        contracts: "Verträge",
        catalogue: "Katalog",
        emails: "E-Mails",
        profile: "Profil",
      },
      add_course: "Kurs buchen",
      system_status: "Systemstatus",
      logout: "Abmelden",
      session_expires: {
        in_one_hour: "Ihre Sitzung läuft in 1 Stunde ab.",
        expired: "Ihre Sitzung ist abgelaufen.",
      },
    },
    results: {
      results: "Ergebnisse",
      title_course_name: "Kursname",
      course: "Kurs",
      title_course_status: "Trainingsstatus",
      course_status: "Status",
      title_course_progress: "Ihr Fortschritt",
      course_progress: "Fortschritt (%)",
      title_course_grade: "Ihr erreichtes Ergebnis",
      course_grade: "Note",
      title_course_finish_date: "Kursenddatum",
      course_finish_date: "Enddatum",
      no_courses_finished_yet: "Sie haben noch keine Kurse abgeschlossen.",
      results_could_not_load: "Die Ergebnisse konnten nicht geladen werden.",
    },
    evaluation: {
      your_anwser: "Ihre Antwort",
      anwser_question_mandatory: "Sie müssen diese Frage beantworten.",
      submit_evaluation: "Absenden",
      thank_you_message:
        "Vielen Dank, dass Sie die Bewertung abgeschlossen haben.",
      continue_with_course: "Sie können Ihr Lernprogramm fortsetzen",
    },
    register: {
      email_code_auth:
        "Geben Sie hier den Code ein, den Sie per E-Mail erhalten haben.",
      code: "Code",
      incorrect_code: "Der Code ist falsch",
      enter_email_and_continue:
        "Geben Sie unten Ihre E-Mail-Adresse ein und klicken Sie auf Weiter.",
      incorrect_email: "Die E-Mail-Adresse ist falsch.",
      enter_password_for_login:
        "Geben Sie Ihr Passwort ein, um sich anzumelden.",
      firstname: "Vorname",
      firstname_mandatory: "Ihr Vorname ist erforderlich.",
      lastname: "Nachname",
      lastname_mandatory: "Ihr Nachname ist erforderlich.",
      company: "Organisation",
      company_mandatory: "Die Organisation ist erforderlich.",
      preference_lang_courses: "Bevorzugte Platformsprache",
      conditions: {
        first: " Ich habe die ",
        second: " Allgemeinen Geschäftsbedingungen ",
        third: " und die ",
        fourth: " Datenschutzerklärung ",
        fifth: " gesendet.",
      },
      accept_conditions_mandatory:
        "Sie müssen den Allgemeinen Geschäftsbedingungen zustimmen.",
      password_must_contain:
        "Das Passwort muss mindestens 8 Zeichen lang sein.",
      repeat_password: "Geben Sie Ihr Passwort erneut ein.",
      passwords_dont_match: "Die Passwörter stimmen nicht überein.",
      identity_check: {
        first:
          "Nur eine kurze Überprüfung, ob Sie es wirklich sind. Wir haben einen Bestätigungscode gesendet an ",
        second: " (Überprüfen Sie auch Ihren Spam-Ordner).",
      },
      resent_invitation:
        "Es wartet eine weitere Einladung auf Sie, sich zu registrieren. Folgendes wurde erneut gesendet an ",
      forgot_password: "Passwort vergessen?",
      switch_case_data: {
        fill_in_code: "Geben Sie Ihren Code ein",
        welcome_to: "Willkommen bei ",
        fill_in_data: "Geben Sie Ihre Daten ein",
        fill_in_password: "Geben Sie Ihr Passwort ein",
        think_of_password: "Passwort erstellen",
        open_invite: "Einladung öffnen",
        extra_information: "Zusätzliche Informationen",
      },
      register: "Registrieren",
      login: "Einloggen",
      page_doesnt_exist: "Upps, diese Seite existiert nicht.",
      page_load_error: "Beim Laden der Seite ist ein Fehler aufgetreten.",
      admin_account_already_exists: "Sie haben bereits ein Administratorkonto",
      invite_not_accepted: "Sie haben Ihre Einladung noch nicht angenommen.",
      gone_wrong_contact_deftiq:
        "Upps, etwas ist schief gelaufen. Kontaktieren Sie support@deftiq.com",
    },
    register_no_course: {
      become_supervisor: "Administrator werden!",
      become_student: "Teilnehemer werden",
      account_already_exists_login:
        "Sie haben bereits ein Konto, mit dem Sie sich anmelden können.",
      account_already_exists_as: "Sie haben bereits ein Konto als ",
      give_your_organisation:
        "Und geben Sie Ihrer Organisation Zugang zu unserer interaktiven Lernumgebung und einzigartigen Kursen.",
      get_access_courses:
        "Und erhalten Sie Zugang zu einem vielfältigen Katalog an einzigartigen Kursen.",
      fill_in_email_supervisor:
        "Geben Sie Ihre E-Mail an und sehen Sie sich unsere Plattform an. Kostenlos.",
      fill_in_email_participant:
        "Geben Sie Ihre E-Mail an und klicken Sie auf Weiter.",
    },
    catalogue: {
      catalogue: "Katalog",
      all_sectors: "Alle Sektoren",
      industry: "Industrie",
      construction_and_infra: "Bau & Infra",
      ore: "Offshore Erneuerbare Energien",
      sir: "SIR",
      overview_ore: "Übersicht der ORE-Kurse",
      overview_sir: "Übersicht der SIR-Kurse",
      all_languages: "Alle Sprachen",
      course_type: "Kurstyp",
      no_courses_found: "Es wurden keine Kurse gefunden.",
      interested_in_course: {
        first: "Wir kennen Ihre Interessen und",
        second:
          "werden uns so schnell wie möglich mit Ihnen in Verbindung setzen.",
        third: "Mit freundlichen Grüßen,",
        fourth: "Deftiq",
      },
      course_available: {
        first: "Der Kurs steht Ihnen nun zur Verfügung.",
        second: "Mit freundlichen Grüßen,",
        third: "Deftiq",
      },
      catalogue_header_sir: "Übersicht der SIR-Kurse",
      course_added_to: "Der Kurs wurde hinzugefügt zu ",
      start_up_gone_wrong: "Ups, beim Start läuft etwas schief.",
    },
    profile: {
      profile: "Profil",
      skillset: "Fähigkeiten",
      firstname: "Vorname",
      lastname: "Nachname",
      receive_emails:
        "Ich möchte E-Mails von Deftiq über meine Kurse erhalten.",
      company: "Organisation",
      reset_password: "Passwort zurücksetzen",
      profile_cant_load: "Das Profil konnte nicht geladen werden.",
      profile_changes_saved: "Ihre Profiländerungen wurden gespeichert.",
      profile_changes_not_saved:
        "Ihre Profiländerungen wurden nicht gespeichert.",
      are_you_sure: "Sind Sie sicher?",
      delete_all_account_data: "Damit löschen Sie alle Daten aus Ihrem Konto.",
      cancel_button: "ABBRECHEN",
      confirm_button: "LÖSCHEN",
      account_deleted: "Ihr Konto wurde gelöscht. Auf Wiedersehen!",
      account_not_deleted: "Ihr Konto wurde nicht gelöscht.",
      gender: {
        gender: "Geschlecht",
        male: "Männlich",
        female: "Weiblich",
        unknown: "Unbekannt",
      },
      terms: "Bedingungen",
      works_at: "Arbeitet bei",
      added_by: "Hinzugefügt von",
    },
    quiz: {
      previous: "Zurück",
      next_button: "weiter",
      are_you_sure: "Sind Sie sicher?",
      no_changes_after_completion:
        "Sie können die Antworten nicht mehr ändern, nachdem Sie den Test abgegeben haben.",
      cancel_button: "ABBRECHEN",
      confirm_button: "ABSENDEN",
      finish: "Fertig",
      attempt_wasnt_saved: "Ihr Quiz-Versuch konnte nicht gespeichert werden.",
      fill_answer_first: "Geben Sie zuerst eine Antwort ein.",
    },
    quiz_results: {
      course: "Kurs",
      quiz_results: "Quiz-Ergebnisse",
      most_recent_grade: "Letzte Note ",
      you_are: "Sie haben ",
      passed: "Bestanden",
      failed: "Nicht bestanden",
      highest_grade: "Höchste erreichte Note ",
      history: "Historie",
      quiz_attempt: "Quiz-Versuch",
      attempt_date: "Datum Ihres Versuchs",
      grade: "Note",
      attempt: "Versuch",
      date: "Datum",
      back_to_statistics: "Zurück zur Statistik",
      view_earlier_attempts: "Vorherige Versuche anzeigen",
      max_attempts_reached: "Maximale Anzahl der Versuche erreicht.",
      new_attempt: "Neuer Versuch",
      questions: "Fragen",
      attempts: "Versuche",
      attempt_in_review: "Versuch wurde beurteilt",
      needed_to_pass: "Minimum, um zu bestehen",
      start_quiz: "Start",
      no_attempts_left: "Sie haben keine Quiz-Versuche mehr.",
      expires_in_one_hour: "Ihre Sitzung läuft in 1 Stunde ab.",
      login_again:
        "Sie sind schon lange eingeloggt. Ihr Fortschritt kann verloren gehen, wenn Sie jetzt ein Quiz starten. Wir empfehlen Ihnen, sich zuerst erneut anzumelden.",
      cancel_button: "ABBRECHEN",
      confirm_button: "LÖSCHEN",
    },
    quiz_feedback: {
      feedback_attempt: "Feedback zu Ihrem Quiz-Versuch",
      incorrect: "Falsch",
      correct: "Richtig",
      no_feedback_question: "Es gibt kein Feedback zu dieser Frage",
      results_overview: "Ergebnisübersicht",
      course: "Kurs",
      quiz_results: "Quiz-Ergebnisse",
      quiz_attempt_feedback: "Feedback zum Quiz-Versuch",
      participant: "Teilnehmer",
      results: "Ergebnisse",
    },
    breadcrumb: { overview: "Übersicht" },
    language_picker: {
      course_language: {
        first: "In welcher Sprache möchten Sie ",
        second: " belegen?",
      },
      action_pick: "Wählen Sie unten Ihre bevorzugte Sprache aus.",
      choice_saved: "Ihre Sprachauswahl wurde gespeichert.",
      went_wrong: "Etwas ist schief gelaufen: ",
    },
    course_expiry_message: {
      header: "Der {coursename} hat eine Dauer von {days_until_expired} Tagen.",
      description:
        "Nachdem Sie eine Sprache ausgewählt haben, haben Sie {days_until_expired} Tage Zeit, um den Kurs abzuschließen. Viel Glück!",
    },
    password_reset: {
      instructions:
        "Geben Sie Ihre E-Mail-Adresse ein und wir werden Ihnen Anweisungen zum Zurücksetzen Ihres Passworts senden.",
      no_attempts:
        "Sie haben keine weiteren Versuche mehr. Bitte versuchen Sie es in 10 Minuten erneut.",
      incorrect_email: "Die E-Mail-Adresse ist falsch.",
      unknown_account: "Das Deftiq-Konto ist unbekannt.",
      safety_update:
        "Wir haben ein Sicherheitsupdate durchgeführt, um die Sicherheit der Lernplattform zu optimieren. Dadurch wird Ihr Konto noch besser geschützt.",
      fill_in_code_below: "Geben Sie unten Ihren 6-stelligen Code ein",
      fill_in_code: {
        first: "Ein 6-stelliger Code wurde an Ihre E-Mail-Adresse ",
        second: " gesendet. Geben Sie den unten stehenden Code ein.",
      },
      code: "Code",
      incorrect_code: "Der Code ist falsch.",
      extra_security_step:
        "Dieser zusätzliche Schritt sichert Ihr Konto. Bitte geben Sie unten ein neues Passwort ein. Dieses Passwort muss aus mindestens 8 Zeichen bestehen",
      fill_in_new_password_below:
        "Bitte geben Sie unten ein neues Passwort ein. Dieses Passwort muss aus mindestens 8 Zeichen bestehen.",
      password_minimum: "Das Passwort muss aus mindestens 8 Zeichen bestehen.",
      repeat_password: "Geben Sie Ihr Passwort erneut ein.",
      passwords_dont_match: "Die Passwörter stimmen nicht überein.",
      change_password: "Passwort ändern",
      check_email: "Überprüfen Sie Ihre E-Mail",
      cybersecurity_update: "Cybersicherheits-Update",
      new_password: "Neues Passwort erstellen",
      send_reset_email: "E-Mail zum Zurücksetzen senden",
      change_your_password: "Passwort ändern",
      no_attempts_left: "Sie haben keine weiteren Versuche mehr.",
      password_changed: "Ihr Passwort wurde geändert.",
      something_went_wrong: "Upps, irgendetwas läuft schief.",
    },
    feedback_button: {
      support_opened:
        "Der Deftiq-Support ist werktags zwischen 09:00 und 17:00 Uhr verfügbar.",
      question: "Haben Sie eine Frage?",
      invalid_email: "Dies ist keine gültige E-Mail-Adresse.",
      subject: "Betreff",
      invalid_subject: "Der Betreff sollte nicht leer sein.",
      message: "Nachricht",
      invalid_message: "Die Nachricht darf nicht leer sein.",
      send: "Senden",
      question_thanks: "Vielen Dank für Ihre Frage.",
      received_message_greetings: {
        first:
          "Wir haben Ihre Frage erhalten. Wir werden uns bemühen, Ihre Frage so schnell wie möglich zu beantworten.",
        second: "Mit freundlichen Grüßen,",
        third: "Anna,",
        fourth: "Im Auftrag des Teams",
      },
      ask_question: "Stellen Sie Ihre Frage",
    },
    birth_date: {
      birthdate: "Geburtsdatum",
      eightteen_or_older: "Sind Sie über 18 Jahre alt?",
      fill_in_birthdate: "Geben Sie Ihr Geburtsdatum ein",
      birth_date_mandatory:
        "Ihr Geburtsdatum ist erforderlich, um an diesem Kurs teilnehmen zu können.",
      birth_date_general:
        "Diese Lernumgebung ist nur für volljährige Teilnehmer zugänglich.",
      birth_date_message:
        "Geben Sie die Geburtsdaten der unten aufgeführten Teilnehmer ein. Dies ist notwendig, um an der Prüfung teilnehmen zu können.",
    },
    catalogue_popup: {
      sign_up: "Anmelden +",
      duration: "Dauer",
      language: "Sprache",
      price_per_person: "Preis p.P.",
      save: "speichern",
      cancel: "abbrechen",
      course_modified: "Der Kurs wurde geändert.",
      course_not_modified: "Der Kurs kann nicht geändert werden.",
    },
    waiting_list: {
      fill_in_info: "Geben Sie Ihre Daten ein",
      notification:
        "Wir senden Ihnen eine Benachrichtigung, wenn der Kurs veröffentlicht wird.",
      invalid_email: "Die E-Mail-Adresse ist ungültig",
      send: "SENDEN",
      added_to_list: "Sie wurden zur Warteliste hinzugefügt.",
      something_went_wrong: "Upps, etwas ist schief gelaufen.",
    },
    register_popup: {
      firstname: "Vorname",
      firstname_mandatory: "Ihr Vorname ist erforderlich.",
      lastname: "Nachname",
      lastname_mandatory: "Ihr Nachname ist erforderlich.",
      company: "Organisation",
      company_mandatory: "Die Organisation ist erforderlich.",
      language: "Sprache",
      conditions_agree: {
        first: " Ich habe die ",
        second: " Allgemeinen Geschäftsbedingungen ",
        third: " und die",
        fourth: "Datenschutzerklärung",
        fifth: " gesendet.",
      },
      agreed_mandatory:
        "Sie müssen den Allgemeinen Geschäftsbedingungen zustimmen.",
      verification_code: {
        first: "Wir haben einen Bestätigungscode gesendet an ",
        second:
          " (Überprüfen Sie auch Ihren Spam-Ordner). Geben Sie Ihren Code unten ein und klicken Sie auf Weiter.",
      },
      code: "Code",
      incorrect_code: "Der Code ist falsch.",
      fill_in_info: "Geben Sie Ihre Daten ein",
      verify_email: "Bestätigen Sie Ihre E-Mail-Adresse",
      check_info: "Überprüfen Sie Ihre Angaben",
      register: "Registrieren",
      verify: "Bestätigen",
      email_verified: "Ihre E-Mail-Adresse wurde verifiziert.",
    },
    bookingpage: {
      back: "Zurück",
      cancel: "Abbrechen",
      next: "Weiter",
      confirm: "Bestätigen",
      pay: "Bezahlen",
      search: "Suchen",
      exam: "PRÜFUNG",
      exam_lowercase: "Prüfung",
      messages: {
        add_exam_completed: "Sie haben die Prüfung erfolgreich hinzugefügt.",
        update_elearning_completed:
          "Sie haben die Prüfung erfolgreich hinzugefügt.",
        booking_failed:
          "Bei der Bearbeitung deiner Buchung ist ein Fehler aufgetreten.\n Kontaktieren Sie support@deftiq.com ",
        process_booking: "Ihre Buchung wird bearbeitet.",
        pay_for_booking: "Sie werden zur Zahlungsseite weitergeleitet.",
        patience_please: "Einen Moment, bitte.",
        booking_completed: "Die Teilnehmer sind eingeschrieben.",
      },
      close: {
        are_you_sure: "Sind Sie sicher?",
        description:
          "Sie sind dabei, die Buchung vorzeitig zu stornieren. Die Buchungsdaten gehen verloren.",
        yes: "JA",
        no: "Nein",
      },
      filters: {
        all_courses: "Alle Schulungskurse",
        free_courses: "Kostenlose Schulungskurse",
        premium_courses: "Premium-Schulungskurse",
        all_sectors: "Alle Sektoren",
        industry: "Industrie",
        construction: "Bau & Infra",
        offshore: "Offshore Erneuerbare Energien",
      },
      moment_picker: {
        all_cities: "Alle Städte",
        all_languages: "Alle Sprachen",
        all_months: "Alle Monate",
        select_moment: "Wählen Sie das gewünschte Zeitfenster für Ihre Prüfung",
        no_moments: "Keine verfügbaren Zeitfenster gefunden.",
        months: {
          january: "Januar",
          february: "Februar",
          march: "März",
          april: "April",
          may: "Mai",
          june: "Juni",
          july: "Juli",
          august: "August",
          october: "Oktober",
          september: "September",
          november: "November",
          december: "Dezember",
        },
        days: {
          monday: "Mo",
          tuesday: "Di",
          wednesday: "Mi",
          thursday: "Do",
          friday: "Fr",
          saturday: "Sa",
          sunday: "So",
        },
      },
      header: {
        select_an_option: "Wählen Sie eine Option",
        select_course: "Wählen Sie einen Schulungskurs",
        select_participants: "Wählen Sie einen oder mehrere Teilnehmer aus",
        add_participant: "Neuen Teilnehmer hinzufügen",
        select_wishes: "Wählen Sie Ihren Schulungsbedarf",
        confirm_info: "Daten bestätigen",
      },
      available_from: "von",
      options: {
        e_learning: "E-Learning",
        e_learning_and_exam: "E-Learning mit Prüfung",
        exam: "Prüfung",
      },
      payment_information: {
        header: "Buchungsdetails",
        course: "Kurs",
        course_type: "Art der Unterweisung",
        amount_of_users: "Anzahl der Teilnehmer",
        location: "Standort",
        examdate: "Datum der Prüfung",
        e_learning_available: "E-Learning freigeben",
        payment_method: "Zahlungsmethode",
        payment_message:
          "Sie können direkt online mit unserem Partner Mollie bezahlen.",
        examlanguage: "Prüfungssprache",
      },
      invoice_information: {
        ordernumber: "Eingangsnummer",
        header: "Rechnungsdaten",
        company_name: "Firmenname*",
        company_required: "Firmenname ist erforderlich.",
        contactperson: "Kontakt*",
        contactperson_required: "Ein Ansprechpartner ist erforderlich.",
        invoice_email: "Rechnungs-E-Mail-Adresse*",
        invoice_email_required: "Eine E-Mail-Adresse ist erforderlich.",
        contactperson_email: "Kontakt-E-Mail-Adresse*",
        contactperson_email_required: "Eine E-Mail-Adresse ist erforderlich.",
        street: "Straße und Hausnummer*",
        street_required: "Straße und Hausnummer sind erforderlich.",
        city: "Stadt*",
        city_required: "Stadt ist erforderlich.",
        postalcode: "Postleitzahl*",
        postalcode_required: "Postleitzahl ist erforderlich.",
        company: "Organisation",
        free_users: "Die Teilnahme an dem Kurs ist kostenfrei.",
        price: "Preis",
        transaction_cost: "Transaktionskosten",
        cancel_costs: "Stornokosten",
        payment_method: "Zahlungsmethode",
      },
      cost: { total: "Gesamtsumme", subtotal: "Zwischensumme", vat: "USt." },
      courses: { course: "Kurs", sector: "Branche", language: "Sprache" },
      users: {
        enroll: "Anmelden",
        fullname: "Vollständiger Name",
        email: "E-Mail-Adresse",
      },
      add_new_user: "Neuen Teilnehmer hinzufügen",
      policy: {
        agree_with: "Ich stimme den",
        general_policy: "allgemeine Geschäftsbedingungen,",
        course_policy: "Ausbildungsbedingungen",
        and: "und",
        exam_policy: "Prüfungsbedingungen zu",
      },
    },
    add_user: {
      firstname: "Vorname",
      lastname: "Nachname",
      email: "E-Mail-Adresse",
      works_at: "Arbeitet bei",
      extra_info: "Zusätzliche Informationen",
      language: "Sprache",
      company: "Organisation",
      role: "Position",
      birthdate: "Geburtsdatum",
      required: {
        firstname: "Ein Vorname ist erforderlich.",
        lastname: "Ein Nachname ist erforderlich.",
        email: "Die E-Mail-Adresse ist ungültig.",
        works_at: "Geben Sie ein Unternehmen ein.",
        birthdate:
          "Geben Sie ein gültiges Geburtsdatum ein. Ein Teilnehmer muss mindestens 18 Jahre alt sein.",
        birthdate_short: "Geben Sie ein gültiges Geburtsdatum ein.",
      },
    },
    expired: "abgelaufen",
    course_language: {
      update: "Sprache bearbeiten",
      are_you_sure: "Sind Sie sicher?",
      description:
        "Sie werden das E-Learning neu starten, wenn Sie eine andere Sprache wählen.",
      cancel: "ABBRECHEN",
      pick_language: "SPRACHE BEARBEITEN",
    },
    course_overview: {
      add_course: "Training hinzufügen",
      access_expired: "Zugriff abgelaufen",
      access_scheduled: "Geplant",
      percent_completed: "% abgeschlossen.",
      no_available_courses: "Sie haben keine verfügbaren Kurse.",
      course_overview_cant_load:
        "Die Kursübersicht konnte nicht geladen werden.",
      active_courses: "Aktive Kurse",
      expired_courses: "Abgelaufene Kurse",
      all_courses: "Alle Kurse",
    },
    administration_overview: {
      bookings: "Buchungen",
      consumption: "Verbrauch",
      contracts: "Verträge",
      add_contract: "Vertrag hinzufügen",
      purchase: "Beschaffung",
      external_courses: "Externe Kurse",
      admin: "Verwaltung",
      my_admin: "Meine Verwaltung",
    },
    participant_overview: {
      participants: "Teilnehmerübersicht",
      add_participant: "Teilnehmer hinzufügen",
      add_bulk: "Bulk hinzufügen",
      delete_participant: "Teilnehmer löschen",
      columns: {
        id: "ID",
        fullname: "Vollständiger Name",
        email: "E-Mail-Adresse",
        works_at: "arbeitet bei",
        role: "Position",
        status: "Status",
        added_by: "Hinzugefügt von",
        registration_date: "Registrierungsdatum",
      },
      no_participants: "Sie haben noch keine Teilnehmer.",
      enrollments: "Anmeldungen",
      profile_control: "Profilverwaltung",
      roles: {
        participant: "Teilnehmer",
        controller: "Administrator",
        admin: "Admin",
        examinator: "Prüfer",
      },
      state: { active: "aktiv", inactive: "inaktiv" },
      breadcrumb_user: "Teilnehmer",
      breadcrumb_admin: "Meine Teilnehmer",
      errors: { delete: "Die Teilnehmer werden nicht entfernt." },
      succes: { delete: "Die Teilnehmer werden entfernt." },
    },
    total_overview: {
      participant: "Teilnehmer",
      breadcrumb: "Gesamtübersicht",
    },
    participant_profile: {
      profile: "Profil",
      enrollments: "Anmeldungen",
      labels: {
        participants: "Teilnehmer",
        my_participants: "Meine Teilnehmer",
        company_participants: "Firmenteilnehmer",
      },
      state: { active: "aktiv", inactive: "inaktiv" },
      extra_info: "Zusätzliche Informationen",
      search: "Suchen",
      save: "Speichern",
      cancel: "Abbrechen",
      status: {
        all: "Alle Status",
        passed: "Abgeschlossen",
        failed: "Fehlgeschlagen",
        expired: "Abgelaufen",
        busy: "In bearbeitung",
        registered: "Nicht gestartet",
        scheduled: "Geplant",
      },
      columns: {
        coursename: "Kursname",
        type: "Art des Kurses",
        status: "Status",
        booked_by: "Gebucht von",
        startdate: "Startdatum",
        enddate: "Enddatum",
      },
      actions: {
        see_results: "Ergebnisse anzeigen",
        update_booking: "Buchung ändern",
        reset_password: "Passwort zurücksetzen",
        view_course: "Kurs ansehen",
        deftiq_emails:
          "Der Teilnehmer möchte E-Mails von Deftiq über seine Kurse erhalten.",
      },
      fields: {
        firstname: "Vorname",
        lastname: "Nachname",
        company: "Organisation",
        added_by: "Hinzugefügt von",
        works_at: "Arbeitet bei",
        role: "Position",
        email: "E-Mail-Adresse",
        birthdate: "Geburtsdatum",
        registration_date: "Registrierungsdatum",
      },
      errors: {
        firstname: "Der Vorname darf nicht leer sein.",
        lastname: "Der Nachname darf nicht leer sein.",
        no_results: "die Ergebnisse konnten nicht geladen werden.",
        user_updated: "der Teilnehmer wurde geändert",
        user_not_updated: "der Teilnehmer wurde nicht geändert",
        password_changed: "Das Passwort wurde geändert.",
        password_not_changed: "Das Passwort wurde nicht geändert.",
        invalid_email: "Die E-Mail-Adresse wird bereits verwendet.",
      },
    },
    skill_overview: { total: "Gesamtübersicht", skils: "Fähigkeiten" },
  },
  bg: {
    next: "Следващ",
    email: "Имейл",
    password: "Парола",
    error: {
      default: "Упс, нещо се обърка. Моля, свържете се със support@deftiq.com",
    },
    search: "Търсене",
    add: "Добави",
    continue: "Продължи",
    language: "Език",
    save: "Запиши",
    cancel: "Отказ",
    finished: "завършен",
    busy: "зает",
    scheduled: "планиран",
    not_started: "не е започнало",
    company_page: {},
    login: {
      welcome: "Добре дошли в ",
      fill_in_password: "Въведете паролата си",
      fill_in_email_below: "Моля, въведете своя имейл адрес по-долу.",
      fill_in_password_below: "Моля, въведете вашата парола по-долу.",
      create_an_account: "Създай профил",
      forgot_password: "Забравена парола",
      open_invite: "Отворена покана",
      login: "Вход",
      errors: {
        wrong_password_or_email: "Имейл адресът или паролата са неправилни.",
        no_account_yet: "Не можахме да открием акаунт с този имейл адрес.",
        open_invite:
          "Има покана, която ви очаква да се регистрирате. Изпратено отново до",
        open_invite_short: "Има покана, която ви очаква да се регистрирате.",
      },
      session_expired: "Сесията ви е изтекла",
    },
    error_page: {
      general: "Упс, нещо се обърка.",
      not_found:
        "Бяхте насочени към страница, която (вече) не съществува. Щастливи сме да Ви върнем на правилния път.",
      invite_expired:
        "Тази връзка за покана е изтекла или е неправилна. Проверете пощенската си кутия за друга връзка за покана. <br> Не можете да го разберете? Моля, свържете се с support@deftiq.com",
      direct_closed:
        "Директният достъп на Вашата организация до този курс на обучение е затворен. <br>Свържете се с support@deftiq.com, ако се предполага, че имате достъп.",
    },
    training: {
      activity_locked:
        "Тази дейност все още не е налична. Уверете се, че сте завършили предишните дейности.",
      activity_finished: "Завършен",
      activity_not_started: "не е започнало",
      activity_doing: "зает",
      course_passed: {
        first: "Вие преминахте курса",
        second: " с резултат от ",
        percent: " %.",
      },
      download_certificate: " Изтеглете вашето доказателство за участие тук.",
      no_attempts_left: "Нямате повече опити да започнете дейността.",
      no_rise_training_available: "Дейността не съдържа RISE обучение.",
    },
    navbar: {
      student: {
        my_overview: "Моят преглед",
        my_results: "Моите резултати",
        my_profile: "Моят профил",
        catalogue: "Каталог",
        overview: "Преглед",
        results: "Резултати",
        profile: "Профил",
      },
      supervisor: {
        my_dashboard: "Моето табло",
        my_courses: "Моите курсове",
        my_participants: "Моите участници",
        my_administration: "My Administration",
        catalogue: "Каталог",
        my_profile: "Моят профил",
        helpcenter: "Помощен център",
        dashboard: "Табло",
        profile: "Профил",
        courses: "Курсове",
        participants: "Участници",
      },
      admin: {
        dashboard: "Табло",
        companies: "Компании",
        courses: "Курсове",
        participants: "Участници",
        contracts: "Договори",
        catalogue: "Каталог",
        emails: "Имейли",
        profile: "Профил",
      },
      system_status: "Състояние на системата",
      logout: "Изход",
      session_expires: {
        in_one_hour: "Вашата сесия изтича след 1 час.",
        expired: "Сесията ви е изтекла.",
      },
    },
    results: {
      results: "Резултати",
      title_course_name: "Името на курса",
      course: "Курс",
      title_course_status: "Състояние на обучението",
      course_status: "Статус",
      title_course_progress: "Вашия прогрес",
      course_progress: "Прогрес (%)",
      title_course_grade: "Вашият постигнат резултат",
      course_grade: "Оценка",
      title_course_finish_date: "Крайна дата на курса",
      course_finish_date: "Крайна дата",
      no_courses_finished_yet: "Все още не сте завършили никакви курсове.",
      results_could_not_load: "Неуспешно зареждане на резултатите.",
    },
    course_overview: {
      access_expired: "Достъпът е изтекъл",
      access_scheduled: "Планирано",
      percent_completed: "% изпълнени.",
      no_available_courses: "Нямате налични курсове.",
      course_overview_cant_load: "Прегледът на курса не можа да бъде зареден.",
    },
    evaluation: {
      your_anwser: "Вашият отговор",
      anwser_question_mandatory:
        "Трябва да отговорите на този въпрос, за да завършите оценката",
      submit_evaluation: "Изпрати",
      thank_you_message: "Благодарим ви, че завършихте оценката.",
      continue_with_course: "Можете да продължите пътя си на обучение",
    },
    register: {
      email_code_auth: "Въведете тук кода, който сте получили по имейл.",
      code: "Код",
      incorrect_code: "Кодът е неправилен",
      enter_email_and_continue:
        "Въведете своя имейл адрес по-долу и натиснете Следващ.",
      incorrect_email: "Имейл адресът е неправилен.",
      enter_password_for_login: "Въведете вашата парола, за да влезете.",
      firstname: "Име",
      firstname_mandatory: "Името е задължително.",
      lastname: "Фамилия",
      lastname_mandatory: "Фамилията е задължителна.",
      company: "Организация",
      company_mandatory: "Изисква се организация.",
      preference_lang_courses: "Желан език на платформата",
      conditions: {
        first: " Прочетох и съм съгласен с ",
        second: " условията ",
        third: " и ",
        fourth: " политиката за поверителност ",
        fifth: " .",
      },
      accept_conditions_mandatory:
        "Трябва да се съгласите с правилата и условията.",
      password_must_contain: "Паролата трябва да съдържа поне 8 знака.",
      repeat_password: "Въведете отново паролата си.",
      passwords_dont_match: "Паролите не съвпадат.",
      identity_check: {
        first:
          "Просто проверяваме, дали наистина сте вие. Изпратихме код за потвърждение до ",
        second: " (Проверете и вашия спам).",
      },
      resent_invitation:
        "Има още една покана, която ви очаква да се регистрирате. Изпратено отново до ",
      forgot_password: "Забравена парола?",
      switch_case_data: {
        fill_in_code: "Въведете кода си",
        welcome_to: "Добре дошли в ",
        fill_in_data: "Въведете своите данни",
        fill_in_password: "Въведете паролата си",
        think_of_password: "Създайте парола",
        open_invite: "Отворена покана",
        extra_information: "Допълнителна информация",
      },
      register: "Регистрация",
      login: "Вход",
      page_doesnt_exist: "Упс, тази страница не съществува.",
      page_load_error: "Нещо се обърка при зареждане на страницата.",
      admin_account_already_exists: "Вече имате администраторски акаунт",
      invite_not_accepted: "Все още не сте приели поканата си.",
      gone_wrong_contact_deftiq:
        "Упс, нещо се обърка. Свържете се със support@deftiq.com",
    },
    register_no_course: {
      become_supervisor: "Станете администратор!",
      become_student: "Станете участник",
      account_already_exists_login:
        "Вече имате съществуващ акаунт, с който да влезете.",
      account_already_exists_as: "Вече имате съществуващ акаунт като ",
      give_your_organisation:
        "И дайте на вашата организация достъп до нашата интерактивна среда за обучение и уникални курсове.",
      get_access_courses:
        "И получете достъп до богат каталог, пълен с уникални курсове.",
      fill_in_email_supervisor:
        "Попълнете имейла си и разгледайте нашата платформа. Безплатно.",
      fill_in_email_participant: "Попълнете своя имейл и натиснете Следващ.",
    },
    catalogue: {
      catalogue: "Каталог",
      all_sectors: "Всички сектори",
      industry: "Индустрия",
      construction_and_infra: "Строителство и инфраструктура",
      ore: "Офшорна енергия от възобновяеми източници",
      sir: "СЪР",
      overview_ore: "Преглед на ORE курсове",
      overview_sir: "Преглед на SIR курсове",
      all_languages: "Всички езици",
      course_type: "Тип курс",
      no_courses_found: "Няма намерени курсове.",
      interested_in_course: {
        first: "Наясно сме с вашия интерес и",
        second: "ще се свържем с вас възможно най-скоро.",
        third: "Искрено ваши,",
        fourth: "Deftiq",
      },
      course_available: {
        first: "Курсът вече е достъпен за вас.",
        second: "Искрено ваши,",
        third: "Deftiq",
      },
      catalogue_header_sir: "Преглед на SIR курсове",
      course_added_to: "Курсът е добавен към ",
      start_up_gone_wrong: "Упс, нещо се обърка при стартиране.",
    },
    profile: {
      profile: "Профил",
      skillset: "Набор от умения",
      firstname: "Име",
      lastname: "Фамилия",
      receive_emails: "Искам да получавам имейли от Deftiq за моите курсове.",
      company: "Организация",
      reset_password: "Нулирайте паролата",
      profile_cant_load: "Профилът не можа да бъде зареден.",
      profile_changes_saved: "Промените в профила ви са запазени.",
      profile_changes_not_saved: "Промените в профила ви не са запазени.",
      are_you_sure: "Сигурни ли сте?",
      delete_all_account_data:
        "По този начин ще изтриете всички данни от вашия акаунт.",
      cancel_button: "ОТКАЗ",
      confirm_button: "ИЗТРИЙ",
      account_deleted: "Вашият акаунт е изтрит. Довиждане!",
      account_not_deleted: "Вашият акаунт не е изтрит.",
      gender: {
        gender: "Пол",
        male: "Мъжки",
        female: "Женски",
        unknown: "Неизвестен",
      },
    },
    quiz: {
      previous: "Предишен",
      next_button: "следващ",
      are_you_sure: "Сигурни ли сте?",
      no_changes_after_completion:
        "Не можете да променяте отговорите, след като изпратите теста.",
      cancel_button: "ОТКАЗ",
      confirm_button: "ИЗПРАТИ",
      finish: "Завърши",
      attempt_wasnt_saved:
        "Вашият опит за решаване на теста не можа да бъде запазен.",
      fill_answer_first: "Първо попълнете отговор.",
    },
    quiz_results: {
      course: "Курс",
      quiz_results: "Резултати от теста",
      most_recent_grade: "Най-скорошна оценка ",
      you_are: "Вие имате ",
      passed: "Преминат",
      failed: "Неуспешен",
      highest_grade: "Най-висока постигната оценка ",
      history: "История",
      quiz_attempt: "Опит за решаване",
      attempt_date: "Дата на вашия опит",
      grade: "Оценка",
      attempt: "опит",
      date: "дата",
      back_to_statistics: "Обратно към статистиката",
      view_earlier_attempts: "Вижте предишни опити",
      max_attempts_reached: "Достигнат е максималният брой опити.",
      new_attempt: "Нов опит",
      questions: "въпроси",
      attempts: "опити",
      attempt_in_review: "опитът се оценява",
      needed_to_pass: "необходимо за преминаване",
      start_quiz: "Начало",
      no_attempts_left: "Нямате останали опити за тест.",
      expires_in_one_hour: "Вашата сесия изтича след 1 час.",
      login_again:
        "Прекарахте много време влезли в профила си. Вашият напредък може да бъде загубен, ако започнете тест точно сега. Препоръчваме ви първо да влезете отново.",
      cancel_button: "ОТКАЗ",
      confirm_button: "ИЗТРИЙ",
    },
    quiz_feedback: {
      feedback_attempt: "Обратна връзка за вашия опит за тест",
      incorrect: "Неправилно",
      correct: "Правилно",
      no_feedback_question: "Няма обратна връзка за този въпрос",
      results_overview: "Преглед на резултатите",
      course: "Курс",
      quiz_results: "Резултати от теста",
      quiz_attempt_feedback: "Обратна връзка за опит за тест",
      participant: "Участник",
      results: "Резултати",
    },
    breadcrumb: {
      overview: "Преглед",
    },
    language_picker: {
      course_language: {
        first: "На кой език бихте искали да преминете теста ",
        second: " ?",
      },
      action_pick: "Изберете предпочитания от вас език по-долу.",
      choice_saved: "Вашият избор на език е запазен.",
      went_wrong: "Нещо се обърка: ",
    },

    course_expiry_message: {
      header: "The {coursename} has a duration of {days_until_expired} days.",
      description:
        "Once you have chosen a language, you have {days_until_expired} days to complete the course. Good luck!",
    },

    password_reset: {
      instructions:
        "Въведете вашия имейл адрес и ние ще ви изпратим инструкции за възстановяване на паролата ви.",
      no_attempts: "Нямате повече опити. Моля, опитайте отново след 10 минути.",
      incorrect_email: "Имейл адресът е неправилен.",
      unknown_account: "Този профил в Deftiq е неизвестен.",
      safety_update:
        "Извършихме актуализация на защитата, за да оптимизираме сигурността на платформата за обучение. Това прави вашия акаунт още по-добре защитен.",
      fill_in_code_below: "Въведете вашия 6-цифрен код по-долу",
      fill_in_code: {
        first: "На вашия имейл адрес е изпратен 6-цифрен код ",
        second: " . Въведете кода по-долу.",
      },
      code: "Код",
      incorrect_code: "Кодът е неправилен.",
      extra_security_step:
        "Тази допълнителна стъпка защитава вашия акаунт. Моля, въведете нова парола по-долу. Тази парола трябва да се състои от поне 8 знака",
      fill_in_new_password_below:
        "Моля, въведете нова парола по-долу. Тази парола трябва да се състои от поне 8 знака.",
      password_minimum: "Паролата трябва да се състои от поне 8 знака.",
      repeat_password: "Въведете отново паролата си.",
      passwords_dont_match: "Паролите не съвпадат.",
      change_password: "Промяна на паролата",
      check_email: "Проверявате електронната си поща",
      cybersecurity_update: "Актуализация на киберсигурността",
      new_password: "Създайте нова парола",
      send_reset_email: "Изпратете имейл за нулиране",
      change_your_password: "Промяна на паролата",
      no_attempts_left: "Нямате повече опити.",
      password_changed: "Вашата парола е сменена.",
      something_went_wrong: "Ами сега, нещо не е наред.",
    },
    feedback_button: {
      question: "Имате въпроси?",
      invalid_email: "Това е невалиден имейл адрес.",
      subject: "Тема",
      invalid_subject: "Темата не трябва да се попълва.",
      message: "Съобщение",
      invalid_message: "Съобщението не трябва да е празно.",
      send: "изпрати",
      question_thanks: "Благодарим ви, за вашия въпрос.",
      received_message_greetings: {
        first:
          "Получихме вашия въпрос. Ще се постараем да отговорим на въпроса ви възможно най-скоро.",
        second: "Поздрави,",
        third: "Анна,",
        fourth: "От името на екипа на ",
      },
      ask_question: "Задайте въпроса си",
    },
    birth_date: {
      birthdate: "Дата на раждане",
      eightteen_or_older: "Над 18 години ли сте?",
      fill_in_birthdate: "Въведете Вашата дата на раждане",
      birth_date_mandatory:
        "Вашата дата на раждане е необходима, за да можете да участвате в този курс.",
      birth_date_general:
        "Тази учебна среда е достъпна само за участници, навършили законова възраст.",
      birth_date_message:
        "Въведете датите на раждане на участниците, посочени по-долу. Това е необходимо, за да можете да участвате в изпита.",
    },
    catalogue_popup: {
      sign_up: "Запишете се +",
      duration: "Продължителност",
      language: "Език",
      price_per_person: "Цена стр",
      save: "запиши",
      cancel: "отказ",
      course_modified: "Курсът се промени.",
      course_not_modified: "Курсът не може да бъде променен.",
    },
    waiting_list: {
      fill_in_info: "Въведете своите данни",
      notification: "Ще ви изпратим известие, когато курсът бъде публикуван.",
      invalid_email: "Имейл адресът е невалиден",
      send: "ИЗПРАТИ",
      added_to_list: "Бяхте добавен в списъка на чакащите.",
      something_went_wrong: "Упс, нещо се обърка.",
    },
    register_popup: {
      firstname: "Име",
      firstname_mandatory: "Името е задължително.",
      lastname: "Фамилия",
      lastname_mandatory: "Фамилията е задължителна.",
      company: "Организация",
      company_mandatory: "Изисква се организация.",
      language: "Език",
      conditions_agree: {
        first: " Прочетох и съм съгласен с ",
        second: " условията ",
        third: " и",
        fourth: "политиката за поверителност",
        fifth: " .",
      },
      agreed_mandatory: "Трябва да се съгласите с правилата и условията.",
      verification_code: {
        first: "Изпратихме код за потвърждение до ",
        second:
          " (Проверете и вашия спам). Въведете вашия код по-долу и натиснете продължи.",
      },
      code: "Код",
      incorrect_code: "Кодът е неправилен.",
      fill_in_info: "Въведете своите данни",
      verify_email: "Потвърдете имейл адреса си",
      check_info: "Проверете данните си",
      register: "Регистрация",
      verify: "Потвърди",
      email_verified: "Вашият имейл адрес е потвърден.",
    },

    bookingpage: {
      back: "Назад",
      cancel: "Отказ",
      next: "Следващ",
      confirm: "Потвърди",
      pay: "Плащане",
      search: "Търсене",
      exam: "ИЗПИТ",
      exam_lowercase: "Изпит",
      messages: {
        add_exam_completed: "Успешно добавихте изпита.",
        update_elearning_completed: "Успешно добавихте изпита.",
        booking_failed:
          "Нещо се обърка при обработката на вашата резервация.\n Свържете се със support@deftiq.com ",
        process_booking: "Вашата резервация ще бъде обработена.",
        pay_for_booking: "Ще бъдете пренасочени към страницата за плащане.",
        patience_please: "Един момент моля.",
        booking_completed: "Участниците са записани.",
      },
      close: {
        are_you_sure: "Сигурни ли сте?",
        description:
          "На път сте да анулирате резервацията по-рано. Данните за резервацията ще бъдат загубени.",
        yes: "ДА",
        no: "НЕ",
      },
      filters: {
        all_courses: "Всички курсове за обучение",
        free_courses: "Безплатни курсове за обучение",
        premium_courses: "Премиум курсове за обучение",
        all_sectors: "Всички категории",
        industry: "Индустрия",
        construction: "Строителство и инфраструктура",
        offshore: "Офшорна енергия от възобновяеми източници",
      },
      moment_picker: {
        all_cities: "Всички градове",
        all_languages: "Всички езици",
        all_months: "Всички месеци",
        select_moment: "Изберете желания времеви интервал за Вашия изпит",
        no_moments: "Няма намерени свободни времеви интервали.",
        months: {
          january: "Януари",
          february: "Февруари",
          march: "Март",
          april: "Април",
          may: "Май",
          june: "Юни",
          july: "Юли",
          august: "Август",
          october: "Октомври",
          september: "Септември",
          november: "Ноември",
          december: "Декември",
        },
        days: {
          monday: "Пон",
          tuesday: "Вт",
          wednesday: "Ср",
          thursday: "Четв",
          friday: "Пет",
          saturday: "Съб",
          sunday: "Нед",
        },
      },
      header: {
        select_an_option: "Изберете опция",
        select_course: "Изберете курс на обучение",
        select_participants: "Изберете един или повече участници",
        add_participant: "Добавяне на нов участник",
        select_wishes: "Изберете вашите нужди от обучение",
        confirm_info: "Потвърдете данните",
      },
      available_from: "от",
      options: {
        e_learning: "Електронно обучение",
        e_learning_and_exam: "Електронно обучение с изпит",
        exam: "Изпит",
      },
      payment_information: {
        header: "Подробности за резервацията",
        course: "Курс",
        course_type: "Тип обучение",
        amount_of_users: "Брой участници",
        location: "Местоположение",
        examdate: "Дата на изпита",
        e_learning_available: "Пускане на електронно обучение",
        payment_method: "Начин на плащане",
        payment_message:
          "Можете да платите директно онлайн чрез iDeal с нашия партньор Mollie.",
        examlanguage: "Език на изпита",
      },
      invoice_information: {
        header: "Данни за фактура",
        company_name: "Име на компанията*",
        company_required: "Името на фирмата е задължително.",
        contactperson: "Данни за връзка*",
        contactperson_required: "Необходимо е лице за контакт.",
        invoice_email: "Имейл адрес за фактура*",
        invoice_email_required: "Изисква се имейл адрес.",
        contactperson_email: "Имейл адрес за контакт*",
        contactperson_email_required: "Изисква се имейл адрес.",
        street: "Име на улица и номер на сграда*",
        street_required: "Име на улица и номер на сграда са задължителни.",
        city: "Град*",
        city_required: "Изисква се град.",
        postalcode: "Пощенски код*",
        postalcode_required: "Изисква се пощенски код.",
        company: "Организация",
        free_users: "Участниците имат достъп до курса безплатно.",
        price: "Цена",
        transaction_cost: "Разходи за транзакция",
        cancel_costs: "Разходи за анулиране",
        payment_method: "Начин на плащане",
      },
      cost: {
        total: "Обща сума",
        subtotal: "Междинна сума",
        vat: "ДДС",
      },
      courses: {
        course: "Курс",
        sector: "Сектор",
        language: "Език",
      },
      users: {
        enroll: "Записвам се",
        fullname: "Трите имена",
        email: "Имейл адрес",
      },
      add_new_user: "Добавете нов участник",
      policy: {
        agree_with: "Съгласявам се с",
        general_policy: "правилата и условията,",
        course_policy: "условията за обучение",
        and: "и",
        exam_policy: "условията за изпит",
      },
    },
    add_user: {
      firstname: "Име",
      lastname: "Фамилия",
      email: "Имейл адрес",
      works_at: "Работи в",
      extra_info: "Допълнителна информация",
      language: "Език",
      company: "Организация",
      role: "Длъжност",
      birthdate: "Дата на раждане",
      required: {
        firstname: "Изисква се име.",
        lastname: "Изисква се фамилия.",
        email: "Имейл адресът е невалиден.",
        works_at: "Въведете фирма.",
        birthdate:
          "Въведете валидна дата на раждане. Участникът трябва да е навършил 18 години.",
        birthdate_short: "Въведете валидна дата на раждане.",
      },
    },
  },
  es: {
    next: "Siguiente",
    email: "Correo electrónico",
    password: "Contraseña",
    error: {
      default: "Uy, algo salió mal. Póngase en contacto con support@deftiq.com",
    },
    search: "Buscar",
    add: "Añadir",
    continue: "Continuar",
    language: "Idioma",
    save: "Guardar",
    cancel: "Cancelar",
    finished: "completado",
    busy: "en curso",
    scheduled: "programado",
    not_started: "no iniciado",
    company_page: {},
    login: {
      welcome: "Bienvenido a ",
      fill_in_password: "Introduzca su contraseña",
      fill_in_email_below:
        "Por favor, introduzca su dirección de correo electrónico a continuación.",
      fill_in_password_below:
        "Por favor, introduzca su contraseña a continuación.",
      create_an_account: "Crear una cuenta",
      forgot_password: "Contraseña olvidada",
      open_invite: "Invitación abierta",
      login: "inicio de sesión",
      errors: {
        wrong_password_or_email:
          "La dirección de correo electrónico o la contraseña son incorrectas.",
        no_account_yet:
          "No hemos podido encontrar una cuenta con esta dirección de correo electrónico.",
        open_invite:
          "Hay una invitación esperándole para que se registre. Esto fue enviado de nuevo a",
        open_invite_short:
          "Hay una invitación esperándole para que se registre.",
      },
      session_expired: "Su sesión ha expirado",
    },
    booking_course_details: { category: "Categoría", status: "Estado" },
    course_settings: {
      select_company: "Seleccione una empresa",
      support_email: "Dirección de correo electrónico de asistencia",
      invalid_email: "La dirección de correo electrónico no es válida.",
      forward_email: "Reenvío de correo electrónico",
      forward_emails: "Reenviar direcciones de correo electrónico",
      forward_email_message:
        "Los correos electrónicos de finalización de este curso se enviarán a las direcciones de correo electrónico indicadas a continuación. Pulse Intro para añadir una dirección de correo electrónico.",
    },
    invoice_list: {
      message:
        "El sistema realiza un seguimiento automático del número de reservas. La información está sujeta a cambios.",
      search: "Buscar",
      columns: {
        invoice: "Factura",
        period: "Periodo",
        no_access: "No tiene acceso al módulo de facturas",
      },
    },
    participant_skills: {
      type: "Tipo",
      after_intake: "Después de la entrada",
      after_finish_course: "Tras completar el curso",
      no_skills_found: "No se han encontrado competencias",
    },
    exambooking_widget: {
      examtype: "Tipo de examen",
      location: "Ubicación",
      disclaimer:
        "*Las fechas disponibles arriba indicadas están sujetas a cambios. Se le informará lo antes posible si se producen cambios inesperados.",
    },
    examselection_widget: {
      location: "UBICACIÓN",
      no_exams_found: "No se han encontrado exámenes",
      selected: "Selección",
      location_field: "Ubicación:",
      disclaimer:
        "*Las fechas disponibles arriba indicadas están sujetas a cambios. Se le informará lo antes posible si se producen cambios inesperados.",
    },
    examviewer: {
      exam: "Examen ",
      course: "Curso",
      languages: "Idiomas disponibles",
      update: "Cambiar",
      delete: "Eliminar",
    },
    gender_input: { pick_gender: "Elige un género." },
    day_picker: {
      all_cities: "Todas las ciudades",
      all_months: "Todos los meses",
      all_languages: "Todos los idiomas",
    },
    contract_list: {
      search: "Buscar",
      columns: {
        name: "Nombre",
        company: "Organización",
        startdate: "Fecha de entrada en vigor",
        enddate: "Fecha de finalización",
        status: "Estado",
      },
      states: { active: "ACTIVO", inactive: "terminado" },
    },
    updaterichtext: {
      update_course: "Actualizar el curso de formación",
      update_activity: "Actualizar la actividad",
      update: "actualización",
      structure: "Estructura de la actividad",
      settings: "Ajustes",
      configuration: "Configuración",
      name: "Nombre",
      progress: "Progreso",
      counts_for_progress:
        "La actividad cuenta para el progreso del curso de formación.",
      restrictions: {
        title: "Restricciones de acceso",
        locked_text:
          "Sitúe el cursor sobre el texto cuando la actividad esté bloqueada.",
        is: "es",
        if: "Si",
        open_access_for: "a continuación, abra el acceso a la actividad",
        restriction_added: "Se ha añadido la restricción.",
        restriction_not_added: "No se puede añadir la restricción.",
        restriction_removed: "Se ha eliminado la restricción.",
        restriction_not_removed: "La restricción no se ha eliminado.",
      },
      activity_updated: "Se ha actualizado la actividad.",
      activity_update_failed: "No se ha podido actualizar la actividad.",
    },
    richtextbuilder: {
      options: {
        paragraph: "párrafo",
        italic: "cursiva",
        bold: "negrita",
        ordered_list: "lista ordenada",
        horizontal_rule: "Línea horizontal",
        undo: "Deshacer",
        redo: "Rehacer",
      },
      headers: {
        text: "Texto",
        uploads: "Cargas",
        alignment: "Alineación",
        listview: "Vista de la lista",
        layout: "Diseño",
        general: "General",
        preview: "Vista previa",
      },
      preview_message_desktop: "Está viendo la vista previa para escritorio.",
      preview_message_mobile: "Está viendo la vista previa para móvil.",
    },
    richtextbuilder_popup: {
      give_url_for: "Introduzca una URL para el",
      or_give_url: "O introduzca una URL",
      upload_a: "Cargar un",
      upload: "Cargar",
      cancel: "Cancelar",
      add: "Añadir",
    },
    flow: {
      which_course: "¿Qué curso de formación?",
      input_placeholder: "Nombre de la secuencia",
      days_placeholder: "Número de días",
      error_message: "Una secuencia no puede tener un nombre en blanco.",
      confirmation: {
        title: "¿Está seguro?",
        description:
          "Se han completado algunas reglas, pero aún no se han añadido.",
      },
      tooltip: {
        name: "Nombre del curso de formación.",
        sector: "Sector del curso de formación.",
        language: "Idiomas del curso de formación.",
        type: "Tipo de curso de formación.",
      },
      settings: "Ajustes de la secuencia",
      expired_content_policy:
        " Añadir nuevos participantes a los cursos de formación con fecha de inicio anterior",
      finished_list: {
        title: "La secuencia finaliza cuando:",
        all: "Se han completado todos los cursos de formación.",
        particular: "Se ha realizado un curso de formación específico.",
      },
      restriction: "Restricciones",
      condition_list: {
        title: "Tipo de condición",
        direct: { title: "Disponible directamente" },
        status: {
          title: "Estado del curso de formación",
          start_expression: "Empezar como",
          middle_expression: "es",
        },
        date: {
          title: "Fecha de inicio",
          start_from: "Comenzar por",
          start_at: "Comenzar en",
        },
        relative_date: {
          title: "Fecha de inicio relativa",
          start_expression: "Iniciar",
          middle_expression: "días después",
          end_expression: "está",
          opened: "Abierto",
          finished: "Completado",
        },
      },
      create: "Crear secuencia",
      details: "Ver detalles del curso de formación",
      description_list: {
        first: "Nombre de su secuencia",
        second:
          "Seleccione al menos dos cursos de formación que integren la secuencia",
        third: "Añadir restricciones a {flowname}",
        fourth: "Ver detalles del curso de formación.",
      },
      rule_conflict:
        "Las restricciones son contradictorias. Elimine las restricciones para que los participantes puedan comenzar.",
      missing_error: "Rellene todos los campos.",
      past_date_error:
        "No se puede crear la restricción. La fecha está en el pasado.",
      recursive_error:
        "Un curso de formación no puede tener una restricción respecto a sí mismo.",
      already_exists_error: "Ya se ha añadido una restricción similar.",
      other_rule_error:
        "Utilice la restricción 'Estado del curso de formación'",
      reminder_title: "¿Está seguro?",
      reminder_message:
        "Esto eliminará cualquier restricción que se haya añadido a este curso de formación.",
      created_message: "Se ha creado la secuencia.",
      failed_message: "Ups, no se ha creado la secuencia.",
    },
    flow_details: {
      status: "Estado",
      flow_settings: "Ajustes de flujo",
      flow_name: "Nombre de flujo",
      flow_is_active: "Este flujo está activo.",
      no_course_access:
        "El participante aún no tiene acceso a este curso de formación en línea.",
      edit_courses: "Actualización de cursos y restricciones de este flujo.",
      label_list: {
        not_started: "NO INICIADO",
        busy: "EN CURSO",
        completed: "COMPLETADO",
        started: "INICIADO",
        passed: "APROBADO",
        not_passed: "NO APROBADO",
      },
    },
    flow_overview: {
      columns: {
        name: "Nombre",
        status: "Estado",
        amount_courses: "Número de cursos de formación",
        amount_users: "Número de participantes",
      },
      no_flows_to_export: "No hay secuencias para exportar",
      reminder_title: "¿Está seguro?",
      reminder_message:
        "Esto también eliminará todos los futuros componentes de la secuencia. Los componentes existentes siguen estando disponibles.",
      cancel_button: "CANCELAR",
      confirm_button: "ELIMINAR",
      created_message: "Se ha eliminado la secuencia.",
      failed_message: "Ups, no se ha eliminado la secuencia.",
    },
    flow_booking: {
      title_list: {
        first: "Añadir participantes a ",
        second: "Resumen y confirmación",
        third: "Añadir participante",
      },
      description_list: {
        first: "Seleccionar participantes para añadirlos a la secuencia ",
        second: "Ver y confirmar su inscripción.",
        third: "Añadir un participante",
      },
      created_message: "Los participantes se han añadido a la secuencia.",
      failed_message:
        "Ups, los participantes no se han añadido a la secuencia.",
    },
    update_quiz: {
      states: {
        opened: "Abierto",
        passed: "Aprobado",
        completed: "Completado",
      },
    },
    update_course: {
      passed_for: "Has aprobado el curso {coursename} con una nota de ... % 🎉",
      headers: {
        course_structure: "Estructura del curso de formación",
        edit_course: "Actualizar el curso de formación",
      },
      select_language: "Seleccione el idioma que desea cambiar.",
      language_description:
        "Seleccione el idioma del curso de formación cuyo contenido desea actualizar.",
      delete: "Eliminar",
      update: "Cambiar",
      duplicate: "Duplicar",
      reach_out_deftiq:
        "Ponte en contacto con Deftiq para actualizar esta actividad.",
      coursename: "Nombre del curso de formación",
      no_empty_coursename:
        "El nombre del curso de formación no puede estar en blanco.",
      description: "Descripción del curso de formación",
      language: "Idioma",
      status: {
        status: "Estado",
        live: "Listo para usar",
        building: "En curso",
      },
      messages: {
        course_updated: "El curso ha cambiado.",
        course_not_updated: "El curso de formación no puede actualizarse.",
        module_added: "Se ha añadido el módulo.",
        module_not_added: "No se ha podido añadir el módulo.",
        activity_added: "Se ha añadido la actividad.",
        quiz_added: "Se ha añadido el cuestionario.",
      },
      delete_module: {
        are_you_sure: "¿Está seguro?",
        description:
          "Esto también eliminará todas las inscripciones, módulos y actividades asociadas a esta formación.",
        cancel: "CANCELAR",
        delete: "ELIMINAR",
      },
      delete_activity: {
        description:
          "Esto también eliminará todas las actividades asociadas a este módulo.",
      },
    },
    booking_list_tab: {
      search: "Buscar",
      columns: {
        booking: "Reservas",
        participant: "Participante",
        course: "Curso",
        type: "Tipo",
        language: "Idioma",
        works_at: "Trabaja en",
        status: "Estado",
        bookingdate: "Fecha de reserva",
        booked_by: "Reservado por",
      },
      status_labels: {
        paid: "completado",
        expired: "caducado",
        open: "abrir",
        created: "creado",
        failed: "fallido",
        canceled: "cancelado",
        invoice: "completado",
        needs_processing: "procesamiento necesario",
        exam: "EXAMEN ",
      },
    },
    country_input: {
      country: "País",
      country_error: "Seleccione un país.",
      goodmorning: "Buenos días.",
    },
    feedback_list: {
      questions: "Preguntas de los participantes",
      status: "Estado:",
      fields: {
        fullname: "Nombre completo:",
        email: "Dirección de correo electrónico:",
        page: "Página:",
        date: "Date:",
        browser: "Navegador:",
        sent_to: "Enviado a",
        send: "Enviar",
        cancel: "Cancelar",
      },
      are_you_sure: "¿Está seguro?",
      info_message: "Esto eliminará todas las preguntas.",
      cancel: "CANCELAR",
      delete: "ELIMINAR",
    },
    invoice_viewer: {
      back: "volver",
      all_references: "Todos los números de recibo",
      no_reference: "Sin número de recibo",
      contract_email: "Correo electrónico del contrato",
      all_invoice_emails: "Todos los correos electrónicos de las facturas",
      invoice_email: "Correo electrónico de la factura",
      period: "Periodo",
      fields: {
        description: "Descripción",
        amount: "Importe",
        price: "Precio",
        cost: "Coste",
        name: "Nombre",
        startdate: "Fecha de inicio",
        examdate: "Fecha del examen",
      },
      subtotal: "Subtotal",
      participants_in: "Participantes de la formación en línea en",
      participants_with_exam: "Participantes con un examen en",
    },
    richtext_activity: {
      continue_learning: "  Pulse el botón y continúe su camino de aprendizaje",
      continue: "Continuar",
    },
    booking_list: {
      status_labels: {
        paid: "pagado",
        expired: "caducado",
        open: "abrir",
        created: "creado",
        failed: "fallido",
        canceled: "cancelado",
        invoice: "factura",
        needs_processing: "procesamiento necesario",
      },
      descriptions: {
        booking_created: "{type} reservado.",
        retry_course: " {coursename} de {firstname} {lastname} reiniciado.",
        cancel_course: "{coursename} de {firstname} {lastname} cancelado.",
        cancel_exam: "{name} de {firstname} {lastname} cancelado.",
        add_exam: "{name} reservado para {firstname} {lastname}.",
        update_exam: "{name} cambiado por {firstname} {lastname}.",
        update_course: "{coursename} cambiado para {firstname} {lastname}.",
        extend_course: "{coursename} ampliado para {firstname} {lastname}.",
      },
    },
    catelogue_popup: {
      category: "Categoría",
      status: "Estado",
      status_labels: {
        live: "en directo",
        available: "disponible",
        in_construction: "en construcción",
      },
    },
    update_booking: {
      info: "Detalles de la reserva",
      course: "E-learning:",
      start_course: "Iniciar e-learning:",
      examdate: "Fecha del examen:",
      examlocation: "Lugar del examen:",
      examstatus: "Estado del examen:",
      exam_status: {
        confirmed: "Confirmado",
        refused: "Denegado",
        waiting: "No confirmado",
      },
      examsupplier: "Proveedor del examen:",
      examlanguage: "Idioma del examen:",
      participant: "Participante:",
      invoice_email: "Correo electrónico de la factura:",
      order_number: "Número de recibo:",
      payment_method: "Forma de pago:",
      payment_status: { mollie: "Mollie", invoice: "Factura" },
      booked_by: "Reservado por:",
      contactperson: "Nombre de contacto:",
      email_contactperson: "Correo electrónico de contacto",
      street: "Calle:",
      postal_code: "Código postal:",
      city: "Ciudad:",
      country: "País:",
      history: "Historia",
      fully_cancelled: "La reserva se ha cancelado por completo.",
      failed: "La reserva ha fallado. Inténtalo de nuevo.",
      back: "Volver",
      next: "Siguiente",
      actions: {
        update_exam: "Modificar examen",
        add_exam: "Añadir examen",
        extend_course: "Ampliar el curso",
        retry_course: "Reiniciar",
        cancel_course: "Cancelar curso",
        cancel_exam: "Cancelar examen",
        book_again: "Rebooking",
      },
      action_descriptions: {
        booking_created: "Reserva creada",
        retry_course: "Se reanuda el aprendizaje por e-learning",
        cancel_course: "Cancelado el e-learning",
        cancel_exam: "Examen anulado",
        add_exam: "Examen añadido",
        update_exam: "Examen modificado",
        update_course: "E-learning modificado",
        extend_course: "Ampliación del aprendizaje electrónico",
      },
      update_booking: "Modificación de la reserva",
      cancelled_booking: "reserva anulada",
      exam_only: "Sólo examen",
      exam: "Examen ",
      booking: "Reservas",
      contract: "Contrato",
    },
    confirm_booking: {
      info: "Detalles de la reserva",
      invoice_info: "Datos de la factura",
      fields: {
        company_name: "Nombre de la empresa*",
        contactperson: "Contacto*",
        invoice_email: "Correo electrónico de la factura*.",
        street: "Nombre de la calle y número de la vivienda*",
        city: "Ciudad*",
        postalcode: "Código postal*",
        order_number: "Número de recibo",
        extend_until: "Prorrogar hasta que*",
      },
      free_action: "No se le cobrará por esta acción.",
      price: "Precio",
      total: "Total:",
      free: "Gratis",
      with_vat: "incl. IVA",
      errors: {
        company_name: "El nombre de la empresa es obligatorio.",
        contactperson: "Se requiere una persona de contacto.",
        invoice_email: "Se requiere una dirección de correo electrónico.",
        street: "Se requiere el nombre de la calle y el número de la vivienda.",
        city: "La ciudad es necesaria.",
        postalcode: "El código postal es obligatorio.",
        order_number: "Su número de pedido es incorrecto",
        no_extension: "No se renovó el acceso.",
        exam_not_cancelled: "El examen no se ha anulado.",
      },
      terms: {
        agree_with: "Acepto las",
        general_terms: "condiciones generales",
        and: "y",
        course_terms: "condiciones del campo",
        exam_terms: "las condiciones del examen",
      },
      actions: {
        extend: "Confirmar ampliación",
        retry_course: "Confirmar reinicio",
        cancel_course: "Confirmar la cancelación del e-learning ",
        cancel_exam: "Confirmar la anulación del examen",
      },
      action_descriptions: {
        extend:
          "prorrogar {coursename} para {firstname} {lastname} por 14 días",
        retry_course:
          "que {firstname} {lastname} empiece de nuevo {coursename}.",
        cancel_course: "  cancelar {coursename} para {firstname} {lastname}.",
        cancel_exam: "Anulación del examen para {firstname} {lastname}.",
      },
      messages: {
        start_again: "El participante puede volver a empezar",
        extend_access: "Se ha ampliado el acceso.",
        participant_cancel: "El participante ha sido dado de baja.",
        exam_cancel: "El examen ha sido anulado.",
      },
      back: "Volver",
      confirm: "Confirmar ",
    },
    proctor_viewer: {
      attempt_for: "Intento de examen para {coursename} en {attempted_at}",
      add_description: "Aclare por qué declara (o no) válida la tentativa.",
      validate: "Aprobar",
      invalidate: "Rechazar",
      finish: "Completar",
    },
    participant_insights: {
      insights: "Perspectivas",
      results: "Resultados",
      progress: "Progreso",
      quiz_attempts: "Intentos de concurso",
      activity: " Actividad",
      apart_of: " Parte de",
      attempt: "Inténtelo",
      status_label: "Estado",
      grade: "Calificación",
      end_date: "Fecha de finalización",
      download_certificate: "Descargar certificado",
      status: {
        passed: "completado",
        busy: "en curso",
        not_started: "no iniciado",
      },
    },
    contract: {
      admin: "Administración",
      my_admin: "Mi Administración",
      overview: "Resumen",
      update_contract: "Editar contrato",
      invoice_template: "Plantilla de presupuesto",
      info: {
        contract_info: "Información contractual",
        company: "Organización",
        email: "Correo electrónico",
        invoice_number: "Número de pedido",
        type: "Tipo",
        startdate: "Fecha de inicio",
        enddate: "Fecha de finalización",
      },
      parameters: {
        title: "Parámetros del contrato",
        amount_participants: "Número de participantes",
        amount_custom_courses: "Número de e-learnings a medida",
        price_extra_participant: "Precio por participante adicional",
        price_extra_course: "Precio por e-learning adicional a medida",
        only_started: "Sólo se cuentan los participantes que han empezado",
      },
    },
    proctor_overview: {
      welcome: "Bienvenido",
      startdate: "Hora de inicio",
      enddate: "Hora de finalización",
      message_par1:
        "    La siguiente lista muestra los intentos de examen que puede revisar.",
      message_par2:
        "    Pulse el ojo para empezar a revisar un intento de examen.",
      course: "Curso",
      activity: "Actividad",
      no_attempts: "No hay intentos de examen que revisar.",
      attempts_error: "¡Uy! No se han podido cargar los intentos.",
    },
    dashboard: {
      recent_actions: "Acciones recientes de los participantes",
      load_more_actions: "Cargar más acciones",
      add_participant: "Añadir participantes al curso",
      total: "Total",
      kpi: {
        new_participants: "Nuevos participantes",
        new_enrollments: "Nuevas aplicaciones",
        passed_participants: "Participantes con éxito",
        enrollments: "Aplicaciones",
      },
      months: {
        january: "Enero",
        february: "Febrero",
        march: "Marzo",
        april: "Abril",
        may: "Mayo",
        june: "Junio",
        july: "Julio",
        august: "Agosto",
        september: "Septiembre",
        october: "Octubre",
        november: "Noviembre",
        december: "Diciembre",
      },
      events: {
        passed: "{firstname} ha completado el {coursename}.",
        opened: "{firstname} ha abierto el {coursename}.",
        registered: "{firstname} se ha inscrito en el {coursename}. ",
        created: "{firstname} ha creado una cuenta. ",
      },
      add_flow: "Añadir secuencia",
      add_course: "Cree su propia formación en línea (Beta)",
      matrix_overview: "Ver la matriz de la formación",
    },
    training_page: {
      settings: "Ajustes",
      search: "Buscar",
      status: {
        failed: "Fallido",
        expired: "Caducado",
        planned: "Programado",
        busy: "En curso",
        finished: "Completado",
        registered: "No iniciado",
      },
      startdate: "fecha de inicio",
      view_results: "Ver resultados",
      update_booking: "Cambiar reserva",
      participants: "Participantes",
      all_status: "Todos los estados",
      columns: {
        fullname: "Nombre y apellidos",
        company: "Organización",
        course_status: "Estado",
        enddate: "Fecha de finalización",
        startdate: "Fecha de inicio",
        grade: "Calificación",
      },
    },
    training_overview: {
      exams: "Exámenes",
      course_overview: "resumen del curso",
      add_course: "añadir curso",
      requirements: "Condiciones",
      question_pool: "Grupo de preguntas",
      search: "Buscar",
      columns: {
        name: "Nombre",
        shortname: "Abreviatura",
        sector: "Sector",
        supported_languages: "Idioma",
        status: "Estado",
      },
      translate_add: "Añadir idioma",
      fields: {
        coursename: "Nombre del curso",
        shortname: "Abreviatura",
        description: "Descripción del curso",
        language: "Idioma",
        course_price: "Precio",
        course_photo: "Subir foto del curso",
        course_days_expired:
          "¿Después de cuántos días pierden los participantes el acceso al curso?",
        course_days_reminder_not_opened: "Recordatorio curso no abierto",
        course_days_reminder_not_done: "Recordatorio curso inacabado",
        course_days_reminder_expired:
          "¿Después de cuántos días reciben los participantes un recordatorio de que han perdido el acceso al curso?",
        course_passed_grade:
          "¿Qué nota debe obtener una persona para aprobar este curso?(porcentaje)",
        is_consumer_course:
          "El curso está a disposición de los administradores en el catálogo.",
        course_duration: "Duración del curso",
        is_project_instruction:
          "El curso tiene acceso directo sin autorización de los participantes",
      },
      view: "Ver",
      evaluations: "Evaluaciones",
      update: "Cambiar",
      duplicate: "Duplicar",
      delete: "Eliminar",
      no_search_results: "No se han encontrado resultados.",
      add: "Añadir",
      cancel: "Cancelar",
      participant_preview: "Previsión de participantes",
      settings: "Ajustes",
      errors: {
        coursename: "El nombre del curso no puede estar en blanco.",
        shortname: "La abreviatura no puede estar en blanco.",
        description: "Se requiere una descripción.",
        primary_color: "El color primario no es un código HEX.",
        secondary_color: "El color secundario no es un código HEX.",
        integer_days: "El valor no es un número entero de días.",
        certificate: "Cargar un fondo de certificado (opcional)",
        integer_price: "El valor no es un número entero.",
        lead_time: "La duración del curso no puede estar en blanco.",
        direct_access: "El campo tiene acceso directo",
        no_watch_course: "No se puede ver el curso.",
      },
      messages: {
        no_courses_to_export: "No hay curso para exportar.",
        preview_course: "Ahora está viendo el curso como participante.",
      },
    },
    error_page: {
      general: "Uy, algo salió mal.",
      not_found:
        "Ha sido redirigido a una página que (ya) no existe. Estaremos encantados de ayudarle.",
      invite_expired:
        "Este enlace de invitación ha caducado o es incorrecto. Consulte su buzón para recibir otro enlace de invitación. <br> ¿No lo entiende? Póngase en contacto con support@deftiq.com",
      direct_closed:
        "Se ha cerrado el acceso directo de su organización a este curso de formación. <br>Póngase en contacto con support@deftiq.com si se supone que tiene acceso.",
    },
    training: {
      activity_locked:
        "Esta actividad aún no está disponible. Asegúrese de haber realizado las actividades anteriores.",
      activity_finished: "Completado",
      activity_not_started: "no iniciado",
      activity_doing: "en curso",
      course_passed: {
        first: "Ha aprobado el curso",
        second: " con una puntuación de ",
        percent: " %.",
      },
      download_certificate: " Descargue su prueba de participación aquí.",
      no_attempts_left: "No tiene más intentos de iniciar la actividad.",
      no_rise_training_available:
        "La actividad no contiene ninguna formación RISE.",
    },
    navbar: {
      student: {
        my_overview: "Mi visión general",
        my_results: "Mis resultados",
        my_profile: "Mi perfil",
        catalogue: "Catálogo",
        overview: "Resumen",
        results: "Resultados",
        profile: "Perfil",
      },
      supervisor: {
        my_dashboard: "Mi panel de control",
        my_courses: "Mis cursos",
        my_participants: "Mis participantes",
        my_administration: "My Administration",
        catalogue: "Catálogo",
        my_profile: "Mi perfil",
        helpcenter: "Centro de ayuda",
        dashboard: "Tablero",
        profile: "Perfil",
        courses: "Cursos",
        participants: "Participantes",
      },
      admin: {
        dashboard: "Tablero",
        companies: "Empresas",
        courses: "Cursos",
        participants: "Participantes",
        contracts: "Contratos",
        catalogue: "Catálogo",
        emails: "Correos electrónicos",
        profile: "Perfil",
      },
      system_status: "Estado del sistema",
      logout: "Cierre de sesión",
      session_expires: {
        in_one_hour: "Su sesión expira en 1 hora.",
        expired: "Su sesión ha expirado.",
      },
    },
    results: {
      results: "Resultados",
      title_course_name: "El nombre del curso",
      course: "Curso",
      title_course_status: "Estado de la formación",
      course_status: "Estatus",
      title_course_progress: "Su progreso",
      course_progress: "Progreso (%)",
      title_course_grade: "El resultado obtenido",
      course_grade: "Calificación",
      title_course_finish_date: "Fecha de finalización del curso",
      course_finish_date: "Fecha de finalización",
      no_courses_finished_yet: "Todavía no has completado ningún curso.",
      results_could_not_load: "No se han podido cargar los resultados.",
    },
    evaluation: {
      your_anwser: "Su respuesta",
      anwser_question_mandatory: "Debe responder a esta pregunta.",
      submit_evaluation: "Enviar",
      thank_you_message: "Gracias por completar la evaluación.",
      continue_with_course: "Puede continuar su camino de aprendizaje",
    },
    register: {
      email_code_auth:
        "Introduzca aquí el código que ha recibido por correo electrónico.",
      code: "Código",
      incorrect_code: "El código es incorrecto",
      enter_email_and_continue:
        "Introduzca su dirección de correo electrónico y pulse siguiente.",
      incorrect_email: "La dirección de correo electrónico es incorrecta.",
      enter_password_for_login:
        "Introduzca su contraseña para iniciar la sesión.",
      firstname: "Nombre",
      firstname_mandatory: "Su nombre de pila es obligatorio.",
      lastname: " Apellido",
      lastname_mandatory: "Su apellido es obligatorio.",
      company: "Organización",
      company_mandatory: "Es necesaria la organización.",
      preference_lang_courses: "Idioma del platform preferido",
      conditions: {
        first: " He leído y acepto los ",
        second: " términos ",
        third: " y la ",
        fourth: " política de privacidad ",
        fifth: " .",
      },
      accept_conditions_mandatory: "Debe aceptar los términos y condiciones.",
      password_must_contain:
        "La contraseña debe contener al menos 8 caracteres.",
      repeat_password: "Vuelva a introducir su contraseña.",
      passwords_dont_match: "Las contraseñas no coinciden.",
      identity_check: {
        first:
          "Sólo comprobando si realmente es usted. Enviamos un código de verificación a ",
        second: " (Compruebe también su spam).",
      },
      resent_invitation:
        "Hay otra invitación esperando a que se registre. Esto fue enviado de nuevo a ",
      forgot_password: "¿Ha olvidado su contraseña?",
      switch_case_data: {
        fill_in_code: "Introduzca su código",
        welcome_to: "Bienvenido a ",
        fill_in_data: "Introduzca sus detalles",
        fill_in_password: "Introduzca su contraseña",
        think_of_password: "Crear una contraseña",
        open_invite: "Invitación abierta",
        extra_information: "Información complementaria",
      },
      register: "Registro",
      login: "Inicio de sesión",
      page_doesnt_exist: "Uy, esta página no existe.",
      page_load_error: "Algo ha ido mal al cargar la página.",
      admin_account_already_exists: "Ya tiene una cuenta de administrador",
      invite_not_accepted: "Todavía no ha aceptado su invitación.",
      gone_wrong_contact_deftiq:
        "Uy, algo salió mal. Contacte con support@deftiq.com",
    },
    register_no_course: {
      become_supervisor: "¡Conviértase en un administrador!",
      become_student: "Conviértase en un participante",
      account_already_exists_login: "Ya tiene una cuenta para conectarse.",
      account_already_exists_as: "Ya tiene una cuenta como ",
      give_your_organisation:
        "Y dé a su organización acceso a nuestro entorno de aprendizaje interactivo y a nuestros cursos únicos.",
      get_access_courses:
        "Y acceda a un rico catálogo repleto de cursos únicos.",
      fill_in_email_supervisor:
        "Rellene su correo electrónico y eche un vistazo a nuestra plataforma. Gratis.",
      fill_in_email_participant:
        "Rellene su correo electrónico y pulse siguiente.",
    },
    catalogue: {
      catalogue: "Catálogo",
      all_sectors: "Todos los sectores",
      industry: "Industria",
      construction_and_infra: "Construcción e Infraestructura",
      ore: "Energía renovable marítima",
      sir: "SIR",
      overview_ore: "Resumen de los cursos ORE",
      overview_sir: "Resumen de los cursos SIR",
      all_languages: "Todos los idiomas",
      course_type: "Tipo de curso",
      no_courses_found: "No se ha encontrado ningún curso.",
      interested_in_course: {
        first: "Somos conscientes de su interés y",
        second: "le contactaremos lo antes posible.",
        third: "Sinceramente,",
        fourth: "Deftiq",
      },
      course_available: {
        first: "El curso ya está disponible para usted.",
        second: "Sinceramente,",
        third: "Deftiq",
      },
      catalogue_header_sir: "Resumen de los cursos SIR",
      course_added_to: "El curso se ha añadido a ",
      start_up_gone_wrong: "Uy, algo va mal en el arranque.",
    },
    profile: {
      profile: "Perfil",
      skillset: "Conjunto de habilidades",
      firstname: "Nombre",
      lastname: " Apellido",
      receive_emails:
        "Quiero recibir correos electrónicos de Deftiq sobre mis cursos.",
      company: "Organización",
      reset_password: "Restablecer contraseña",
      profile_cant_load: "No se ha podido cargar el perfil.",
      profile_changes_saved: "Sus cambios de perfil se han guardado.",
      profile_changes_not_saved: "Sus cambios de perfil no se han guardado.",
      are_you_sure: "¿Está seguro?",
      delete_all_account_data:
        "De este modo, eliminará todos los datos de su cuenta.",
      cancel_button: "CANCELAR",
      confirm_button: "BORRAR",
      account_deleted: "Su cuenta ha sido eliminada. ¡Adiós!",
      account_not_deleted: "Su cuenta no ha sido eliminada.",
      terms: "Condiciones",
      works_at: "Trabaja en",
      added_by: "Añadido por",
      gender: {
        gender: "Género",
        male: "Hombre",
        female: "Mujer",
        unknown: "Desconocido",
      },
    },
    quiz: {
      previous: "Anterior",
      next_button: "siguiente",
      are_you_sure: "¿Está seguro?",
      no_changes_after_completion:
        "No puede cambiar las respuestas una vez que haya enviado el examen.",
      cancel_button: "CANCELAR",
      confirm_button: "ENVIAR",
      finish: "Completar",
      attempt_wasnt_saved: "Su intento de concurso no ha podido ser guardado.",
      fill_answer_first: "Rellene primero una respuesta.",
    },
    quiz_results: {
      course: "Curso",
      quiz_results: "Resultados de la prueba",
      most_recent_grade: "Última calificación ",
      you_are: "Usted ha ",
      passed: "Aprobado",
      failed: "Reprobado",
      highest_grade: "Máxima calificación obtenida ",
      history: "Historia",
      quiz_attempt: "Intento de la prueba",
      attempt_date: "Fecha de su intento",
      grade: "Calificación",
      attempt: "intento",
      date: "fecha",
      back_to_statistics: "Volver a las estadísticas",
      view_earlier_attempts: "Ver intentos anteriores",
      max_attempts_reached: "Número máximo de intentos alcanzado.",
      new_attempt: "Nuevo intento",
      questions: "preguntas",
      attempts: "intentos",
      attempt_in_review: "el intento es evaluado",
      needed_to_pass: "necesario para aprobar",
      start_quiz: "Iniciar",
      no_attempts_left: "No le quedan intentos de prueba.",
      expires_in_one_hour: "Su sesión expira en 1 hora.",
      login_again:
        "Ha estado conectado por mucho tiempo. Su progreso puede perderse si empieza una prueba ahora mismo. Le recomendamos que primero se conecte de nuevo.",
      cancel_button: "CANCELAR",
      confirm_button: "BORRAR",
    },
    quiz_feedback: {
      feedback_attempt: "Comentarios sobre su intento de prueba",
      incorrect: "Incorrecta",
      correct: "Correcta",
      no_feedback_question: "No hay comentarios con esta pregunta",
      results_overview: "Resumen de resultados",
      course: "Curso",
      quiz_results: "Resultados de la prueba",
      quiz_attempt_feedback: "Comentarios sobre el intento de prueba",
      participant: "Participante",
      results: "Resultados",
    },
    breadcrumb: { overview: "Resumen" },
    language_picker: {
      course_language: {
        first: "¿En qué idioma le gustaría tomarla ",
        second: " ?",
      },
      action_pick: "Seleccione su idioma preferido a continuación.",
      choice_saved: "Su selección de idioma se ha guardado.",
      went_wrong: "Algo salió mal: ",
    },
    course_expiry_message: {
      header:
        "El {coursename} tiene una duración de {days_until_expired} días.",
      description:
        "Una vez que haya elegido un idioma, tiene {days_until_expired} días para completar el curso. ¡Buena suerte!",
    },
    password_reset: {
      instructions:
        "Introduzca su dirección de correo electrónico y le enviaremos las instrucciones para restablecer su contraseña.",
      no_attempts:
        "No tiene más intentos. Por favor, inténtelo de nuevo en 10 minutos.",
      incorrect_email: "La dirección de correo electrónico es incorrecta.",
      unknown_account: "La cuenta de Deftiq es desconocida.",
      safety_update:
        "Hemos realizado una actualización de seguridad para optimizar la seguridad de la plataforma de aprendizaje. Esto hace que su cuenta esté aún más protegida.",
      fill_in_code_below: "Introduzca su código de 6 dígitos a continuación",
      fill_in_code: {
        first:
          "Se ha enviado un código de 6 dígitos a su dirección de correo electrónico ",
        second: " . Introduzca el código siguiente.",
      },
      code: "Código",
      incorrect_code: "El código es incorrecto.",
      extra_security_step:
        "Este paso adicional asegura su cuenta. Por favor, introduzca una nueva contraseña a continuación. Esta contraseña debe constar de al menos 8 caracteres",
      fill_in_new_password_below:
        "Por favor, introduzca una nueva contraseña a continuación. Esta contraseña debe constar de al menos 8 caracteres.",
      password_minimum: "La contraseña debe tener al menos 8 caracteres.",
      repeat_password: "Vuelva a introducir su contraseña.",
      passwords_dont_match: "Las contraseñas no coinciden.",
      change_password: "Cambiar contraseña",
      check_email: "Compruebe su correo electrónico",
      cybersecurity_update: "Actualización de la seguridad cibernética",
      new_password: "Crear una nueva contraseña",
      send_reset_email: "Enviar correo electrónico de restablecimiento",
      change_your_password: "Cambiar contraseña",
      no_attempts_left: "No tiene más intentos.",
      password_changed: "Su contraseña ha sido cambiada.",
      something_went_wrong: "Uy, algo va mal.",
    },
    feedback_button: {
      support_opened:
        "El soporte de Deftiq está disponible entre las 09:00 y las 17:00 horas en días laborables.",
      question: "¿Tiene alguna pregunta?",
      invalid_email: "Esta no es una dirección de correo electrónico válida.",
      subject: "Asunto",
      invalid_subject: "El asunto no debe estar en blanco.",
      message: "Mensaje",
      invalid_message: "El mensaje no debe estar en blanco.",
      send: "enviar",
      question_thanks: "Gracias por su pregunta.",
      received_message_greetings: {
        first:
          "Hemos recibido su pregunta. Haremos todo lo posible para responder a su pregunta lo antes posible.",
        second: "Saludos cordiales,",
        third: "Anna,",
        fourth: "En nombre del equipo",
      },
      ask_question: "Haga su pregunta",
    },
    birth_date: {
      birthdate: "Fecha de nacimiento",
      eightteen_or_older: "¿Tiene más de 18 años?",
      fill_in_birthdate: "Introduzca su fecha de nacimiento",
      birth_date_mandatory:
        "Se necesita su fecha de nacimiento para poder acceder a este curso.",
      birth_date_general:
        "Este entorno de aprendizaje está reservado a los participantes mayores de edad.",
      birth_date_message:
        "Introduzca las fechas de nacimiento de los participantes que figuran a continuación. Esto es necesario para poder rendir el examen.",
    },
    catalogue_popup: {
      sign_up: "Inscríbase +",
      duration: "Duración",
      language: "Idioma",
      price_per_person: "Precio p.p.",
      save: "guardar",
      cancel: "cancelar",
      course_modified: "El curso ha cambiado.",
      course_not_modified: "El curso no se puede cambiar.",
    },
    waiting_list: {
      fill_in_info: "Introduzca sus detalles",
      notification:
        "Le enviaremos una notificación cuando se publique el curso.",
      invalid_email: "La dirección de correo electrónico no es válida",
      send: "ENVIAR",
      added_to_list: "Se le ha añadido a la lista de espera.",
      something_went_wrong: "Uy, algo salió mal.",
    },
    register_popup: {
      firstname: "Nombre",
      firstname_mandatory: "Su nombre de pila es obligatorio.",
      lastname: " Apellido",
      lastname_mandatory: "Su apellido es obligatorio.",
      company: "Organización",
      company_mandatory: "Es necesaria la organización.",
      language: "Idioma",
      conditions_agree: {
        first: " He leído y acepto los ",
        second: " términos ",
        third: " y la",
        fourth: "política de privacidad",
        fifth: " .",
      },
      agreed_mandatory: "Debe aceptar los términos y condiciones.",
      verification_code: {
        first: "Enviamos un código de verificación a ",
        second:
          " (Compruebe también su spam). Introduzca su código a continuación y pulse continuar.",
      },
      code: "Código",
      incorrect_code: "El código es incorrecto.",
      fill_in_info: "Introduzca sus detalles",
      verify_email: "Verifique su dirección de correo electrónico",
      check_info: "Compruebe sus detalles",
      register: "Registro",
      verify: "Verificar",
      email_verified: "Su dirección de correo electrónico ha sido verificada.",
    },
    bookingpage: {
      back: "Atrás",
      cancel: "Cancelar",
      next: "Siguiente",
      confirm: "Confirmar ",
      pay: "Pagar ",
      search: "Buscar",
      exam: "EXAMEN ",
      exam_lowercase: "Examen ",
      messages: {
        add_exam_completed: "Ha añadido correctamente el examen. ",
        update_elearning_completed: "Ha añadido correctamente el examen. ",
        booking_failed:
          "Ha ocurrido un error al procesar la reserva.  \n Contactar con support@deftiq.com ",
        process_booking: "Su reserva será procesada.",
        pay_for_booking: "Se le redirigirá a la página de pago.",
        patience_please: "Un momento, por favor.",
        booking_completed:
          "Se ha realizado la inscripción de los participantes.",
      },
      close: {
        are_you_sure: "¿Está seguro?",
        description:
          "Está a punto de cancelar la reserva antes de tiempo. Se perderán los datos de la reserva.",
        yes: "SÍ",
        no: "NO",
      },
      header: {
        select_an_option: "Seleccionar una opción",
        select_course: "Seleccionar un curso de formación",
        select_participants: "Seleccionar uno o varios participantes",
        add_participant: "Añadir un nuevo participante",
        select_wishes: "Seleccionar los objetivos de aprendizaje",
        confirm_info: "Confirmar los datos",
      },
      available_from: "de",
      options: {
        e_learning: "formación en línea",
        e_learning_and_exam: "Formación en línea con examen",
        exam: "Examen ",
      },
      payment_information: {
        header: "Detalles de la reserva",
        course: "Curso",
        course_type: "Tipo de formación",
        amount_of_users: "Número de participantes",
        location: "Ubicación",
        examdate: "Fecha del examen",
        e_learning_available: "Publicar curso de formación en línea",
        payment_method: "Forma de pago",
        payment_message:
          "Puede pagar directamente en línea con nuestro socio Mollie.",
        examlanguage: "Idioma del examen",
      },
      invoice_information: {
        ordernumber: "Número de recibo",
        header: "Datos de la factura",
        company_name: "Nombre de la empresa*",
        company_required: "El nombre de la empresa es obligatorio.",
        contactperson: "Contacto*",
        contactperson_required: "Se requiere una persona de contacto.",
        invoice_email: "Correo electrónico de la factura*",
        invoice_email_required:
          "Se requiere una dirección de correo electrónico.",
        contactperson_email: "Correo electrónico de contacto*",
        contactperson_email_required:
          "Se requiere una dirección de correo electrónico.",
        street: "Nombre de la calle y número de la vivienda*",
        street_required:
          "Se requiere el nombre de la calle y el número de la vivienda.",
        city: "Ciudad*",
        city_required: "La ciudad es obligatoria.",
        postalcode: "Código postal*",
        postalcode_required: "El código postal es obligatorio.",
        company: "Organización",
        free_users:
          "Los participantes pueden acceder al curso de manera gratuita.",
        price: "Precio",
        transaction_cost: "Costes de transacción",
        cancel_costs: "Gastos de cancelación",
        payment_method: "Forma de pago",
      },
      filters: {
        all_courses: "Todos los cursos de formación",
        free_courses: "Cursos de formación gratuitos",
        premium_courses: "Cursos de formación premium",
        all_sectors: "Todos los sectores",
        industry: "Industria",
        construction: "Construcción e Infraestructura",
        offshore: "Energía renovable marítima",
      },
      moment_picker: {
        all_cities: "Todas las ciudades",
        all_languages: "Todos los idiomas",
        all_months: "Todos los meses",
        select_moment: "Seleccione la franja horaria deseada para su examen",
        no_moments: "No se han encontrado franjas horarias disponibles.",
        months: {
          january: "Enero",
          february: "Febrero",
          march: "Marzo",
          april: "Abril",
          may: "Mayo",
          june: "Junio",
          july: "Julio",
          august: "Agosto",
          october: "Octubre",
          september: "Septiembre",
          november: "Noviembre",
          december: "Diciembre",
        },
        days: {
          monday: "Lu",
          tuesday: "Ma",
          wednesday: "Mi",
          thursday: "Ju",
          friday: "Vi",
          saturday: "Sa",
          sunday: "Do",
        },
      },
      cost: { total: "Total", subtotal: "Subtotal", vat: "IVA" },
      courses: { course: "Curso", sector: "Sector", language: "Idioma" },
      users: {
        enroll: "Inscríbase",
        fullname: "Nombre y apellidos",
        email: "Correo electrónico",
      },
      add_new_user: "Añadir un nuevo participante",
      policy: {
        agree_with: "Acepto los",
        general_policy: "términos y condiciones,",
        course_policy: "las condiciones del curso",
        and: "y",
        exam_policy: "las condiciones del examen",
      },
    },
    add_user: {
      firstname: "Nombre",
      lastname: "Apellidos",
      email: "Correo electrónico",
      works_at: "Trabaja en",
      extra_info: "Información complementaria",
      language: "Idioma",
      company: "Organización",
      role: "Puesto",
      birthdate: "Fecha de nacimiento",
      required: {
        firstname: "El nombre es obligatorio.",
        lastname: "El apellido es obligatorio.",
        email: "La dirección de correo electrónico no es válida.",
        works_at: "Introduzca el nombre de una empresa.",
        birthdate:
          "Introduzca una fecha de nacimiento válida. Los participantes deben ser mayores de 18 años.",
        birthdate_short: "Introduzca una fecha de nacimiento válida.",
      },
    },
    expired: "caducado",
    course_language: {
      update: "Editar idioma",
      are_you_sure: "¿Está seguro?",
      description:
        "Reiniciará el aprendizaje electrónico si elige otro idioma.",
      cancel: "CANCELAR",
      pick_language: "EDITAR IDIOMA",
    },
    course_overview: {
      add_course: "Agregar curso",
      access_expired: "Acceso expirado",
      access_scheduled: "Programado",
      percent_completed: "% completado.",
      no_available_courses: "No tiene cursos disponibles.",
      course_overview_cant_load: "No se ha podido cargar el resumen del curso.",
      active_courses: "Cursos activos",
      expired_courses: "Cursos caducados",
      all_courses: "Todos los cursos",
    },
    administration_overview: {
      bookings: "Reservas",
      consumption: "Consumo",
      contracts: "Contratos",
      add_contract: "Añadir contrato",
      purchase: "Adquisiciones",
      external_courses: "Cursos externos",
      admin: "Administración",
      my_admin: "Mi Administración",
    },
    participant_overview: {
      participants: "Resumen de participantes",
      add_participant: "Añadir participante",
      add_bulk: "Añadir a granel",
      delete_participant: "Eliminar participante",
      columns: {
        id: "Id",
        fullname: "Nombre y apellidos",
        email: "Correo electrónico",
        works_at: "trabaja en",
        role: "Puesto",
        status: "Estado",
        added_by: "Añadido por",
        registration_date: "Fecha de inscripción",
      },
      no_participants: "Aún no tienes participantes.",
      enrollments: "Inscripciones",
      profile_control: "Gestión de perfiles",
      roles: {
        participant: "Participante",
        controller: "Administrador",
        admin: "Admin",
        examinator: "Examinador",
      },
      state: { active: "activo", inactive: "inactivo" },
      breadcrumb_user: "Participantes",
      breadcrumb_admin: "Mis participantes",
      errors: { delete: "No se elimina a los participantes." },
      succes: { delete: "Se retiran los participantes." },
    },
    total_overview: {
      participant: "Participante",
      breadcrumb: "Panorama completo",
    },
    participant_profile: {
      labels: {
        participants: "Participantes",
        my_participants: "Mis participantes",
        company_participants: "Participantes de la empresa",
      },
      profile: "Perfil",
      enrollments: "Inscripciones",
      state: { active: "activo", inactive: "inactivo" },
      extra_info: "Información complementaria",
      search: "Buscar",
      save: "Guardar",
      cancel: "Cancelar",
      status: {
        all: "Todos los estados",
        passed: "Completado",
        failed: "Fallido",
        expired: "Caducado",
        busy: "En curso",
        registered: "No iniciado",
        scheduled: "Programado",
      },
      columns: {
        coursename: "Nombre del curso",
        type: "Tipo de curso",
        status: "Estado",
        booked_by: "Reservado por",
        startdate: "Fecha de inicio",
        enddate: "Fecha de finalización",
      },
      actions: {
        see_results: "Ver resultados",
        update_booking: "Cambiar reserva",
        reset_password: "Restablecer contraseña",
        view_course: "Ver curso",
        deftiq_emails:
          "El participante desea recibir correos electrónicos de Deftiq sobre sus cursos.",
      },
      fields: {
        firstname: "Nombre",
        lastname: "Apellidos",
        company: "Organización",
        added_by: "Añadido por",
        works_at: "Trabaja en",
        role: "Puesto",
        email: "Correo electrónico",
        birthdate: "Fecha de nacimiento",
        registration_date: "Fecha de inscripción",
      },
      errors: {
        firstname: "El nombre no puede estar en blanco.",
        lastname: "El apellido no puede estar en blanco.",
        no_results: "no se han podido cargar los resultados.",
        user_updated: "se ha cambiado el participante",
        user_not_updated: "el participante no se ha modificado",
        password_changed: "La contraseña ha sido modificada.",
        password_not_changed: "La contraseña no se ha modificado.",
        invalid_email: "La dirección de correo electrónico ya está en uso.",
      },
    },
    skill_overview: { total: "Panorama completo", skils: "Competencias" },
  },
  fr: {
    next: "Suivant",
    email: "Courriel :",
    password: "Mot de passe",
    error: {
      default:
        "Oups, quelque chose a mal tourné. Veuillez contacter support@deftiq.com",
    },
    search: "Recherche",
    add: "Ajouter",
    continue: "Continuer",
    language: "Langue",
    save: "Sauvegarder",
    cancel: "Annuler",
    finished: "terminé",
    busy: "En cours",
    scheduled: "programmé",
    not_started: "pas commencé",
    company_page: {},
    login: {
      welcome: "Bienvenue à ",
      fill_in_password: "Entrez votre mot de passe",
      fill_in_email_below:
        "Veuillez saisir votre adresse électronique ci-dessous.",
      fill_in_password_below: "Veuillez saisir votre mot de passe ci-dessous.",
      create_an_account: "Créer un compte",
      forgot_password: "Mot de passe oublié",
      open_invite: "Invitation ouverte",
      login: "connexion",
      errors: {
        wrong_password_or_email:
          "L'adresse électronique ou le mot de passe est incorrect.",
        no_account_yet:
          "Nous n'avons pas pu trouver de compte avec cette adresse e-mail.",
        open_invite:
          "Une invitation vous attend pour vous inscrire. Ceci a été envoyé à nouveau à",
        open_invite_short: "Une invitation vous attend pour vous inscrire.",
      },
      session_expired: "Votre session a expiré",
    },
    error_page: {
      general: "Oups, quelque chose a mal fonctionné.",
      not_found:
        "Vous avez été dirigé vers une page qui n'existe pas (plus). Nous sommes heureux de vous remettre sur la bonne voie.",
      invite_expired:
        "Ce lien d'invitation est expiré ou incorrect. Vérifiez votre boîte aux lettres pour obtenir un autre lien d’invitation. <br> Vous n'arrivez pas à comprendre ? Veuillez contacter support@deftiq.com",
      direct_closed:
        "L'accès direct de votre organisation à cette formation a été fermé. <br>Contactez support@deftiq.com, si vous êtes censé y avoir accès.",
    },
    training: {
      activity_locked:
        "Cette activité n'est pas encore disponible. Assurez-vous d'avoir terminé les activités précédentes.",
      activity_finished: "Terminé",
      activity_not_started: "pas commencé",
      activity_doing: "En cours",
      course_passed: {
        first: "Vous avez réussi le cours",
        second: " avec un score de ",
        percent: " %.",
      },
      download_certificate: "Téléchargez votre preuve de participation ici.",
      no_attempts_left:
        "Vous n'avez plus de tentatives pour démarrer l'activité.",
      no_rise_training_available:
        "L'activité ne contient pas de formation RISE.",
    },
    navbar: {
      student: {
        my_overview: "Mon aperçu",
        my_results: "Mes résultats",
        my_profile: "Mon profil",
        catalogue: "Catalogue",
        overview: "Vue d'ensemble",
        results: "Résultats",
        profile: "Profil",
      },
      supervisor: {
        my_dashboard: "Mon tableau de bord",
        my_courses: "Mes cours",
        my_participants: "Mes participants",
        my_administration: "Mi Administración",
        my_flows: "Mis secuencias",
        catalogue: "Catalogue",
        my_profile: "Mon profil",
        helpcenter: "Centre d'aide",
        dashboard: "Tableau de bord",
        profile: "Profil",
        courses: "Cours",
        participants: "Participants",
      },
      admin: {
        dashboard: "Tableau de bord",
        companies: "Entreprises",
        courses: "Cours",
        participants: "Participants",
        contracts: "Contrats",
        catalogue: "Catalogue",
        emails: "E-mails",
        profile: "Profil",
      },
      add_course: "Inscribirse en un curso",
      system_status: "État du système",
      logout: "Déconnexion",
      session_expires: {
        in_one_hour: "Votre session expire dans 1 heure.",
        expired: "Votre session a expiré.",
      },
    },
    results: {
      results: "Résultats",
      title_course_name: "Le nom du cours",
      course: "Cours",
      title_course_status: "Statut de la formation",
      course_status: "Statut",
      title_course_progress: "Votre progression",
      course_progress: "Progression (%)",
      title_course_grade: "Votre résultat obtenu",
      course_grade: "Note",
      title_course_finish_date: "Date de fin du cours",
      course_finish_date: "Date de fin",
      no_courses_finished_yet: "Vous n'avez pas encore suivi de cours.",
      results_could_not_load: "Les résultats n'ont pas pu être chargés.",
    },
    evaluation: {
      your_anwser: "Votre réponse",
      anwser_question_mandatory: "Vous devez répondre à cette question.",
      submit_evaluation: "Envoyer",
      thank_you_message: "Merci d'avoir rempli l'évaluation.",
      continue_with_course:
        "Vous pouvez poursuivre votre parcours d'apprentissage",
    },
    register: {
      email_code_auth: "Entrez ici le code que vous avez reçu par e-mail.",
      code: "Code",
      incorrect_code: "Le code est incorrect",
      enter_email_and_continue:
        "Saisissez votre adresse électronique ci-dessous et appuyez sur suivant.",
      incorrect_email: "L'adresse électronique est incorrecte.",
      enter_password_for_login:
        "Entrez votre mot de passe pour vous connecter.",
      firstname: "Prénom",
      firstname_mandatory: "Votre prénom est obligatoire.",
      lastname: "Nom de famille",
      lastname_mandatory: "Votre nom de famille est obligatoire.",
      company: "Organisation",
      company_mandatory: "Une organisation est nécessaire.",
      preference_lang_courses: "Langue de la plateforme préférée",
      conditions: {
        first: " J'ai lu et j'accepte ",
        second: " les conditions",
        third: " et la ",
        fourth: " politique de confidentialité",
        fifth: " .",
      },
      accept_conditions_mandatory:
        "Vous devez accepter les termes et conditions.",
      password_must_contain:
        "Le mot de passe doit contenir au moins 8 caractères.",
      repeat_password: "Saisissez à nouveau votre mot de passe.",
      passwords_dont_match: "Les mots de passe ne correspondent pas.",
      identity_check: {
        first:
          "Je vérifie juste si c'est vraiment vous. Nous avons envoyé un code de vérification à ",
        second: " (Vérifiez aussi vos spams).",
      },
      resent_invitation:
        "Une autre invitation vous attend pour vous inscrire. Ceci a été envoyé à nouveau à ",
      forgot_password: "Mot de passe oublié ?",
      switch_case_data: {
        fill_in_code: "Entrez votre code",
        welcome_to: "Bienvenue à ",
        fill_in_data: "Entrez vos coordonnées",
        fill_in_password: "Entrez votre mot de passe",
        think_of_password: "Créez un mot de passe",
        open_invite: "Invitation ouverte",
        extra_information: "Informations complémentaires",
      },
      register: "Registre",
      login: "Connexion",
      page_doesnt_exist: "Oups, cette page n'existe pas.",
      page_load_error: "Un problème est survenu lors du chargement de la page.",
      admin_account_already_exists: "Vous avez déjà un compte administrateur",
      invite_not_accepted: "Vous n'avez pas encore accepté votre invitation.",
      gone_wrong_contact_deftiq:
        "Oups, quelque chose a mal tourné. Contact support@deftiq.com",
    },
    register_no_course: {
      become_supervisor: "Devenez administrateur !",
      become_student: "Devenez un participant",
      account_already_exists_login:
        "Vous avez déjà un compte existant pour vous connecter.",
      account_already_exists_as:
        "Vous avez déjà un compte existant en tant que ",
      give_your_organisation:
        "Et donnez à votre organisation l'accès à notre environnement d'apprentissage interactif et à nos cours uniques.",
      get_access_courses:
        "Et accédez à un riche catalogue rempli de cours uniques.",
      fill_in_email_supervisor:
        "Remplissez votre e-mail et jetez un coup d'œil à notre plateforme. Gratuit.",
      fill_in_email_participant:
        "Remplissez votre e-mail et appuyez sur suivant.",
    },
    catalogue: {
      catalogue: "Catalogue",
      all_sectors: "Tous les secteurs",
      industry: "Industrie",
      construction_and_infra: "Construction & Infrastructure",
      ore: "Énergie renouvelable en mer",
      sir: "SIR",
      overview_ore: "Aperçu des cours ORE",
      overview_sir: "Aperçu des cours SIR",
      all_languages: "Toutes les langues",
      course_type: "Type de cours",
      no_courses_found: "Aucun cours n'a été trouvé.",
      interested_in_course: {
        first: "Nous sommes conscients de votre intérêt et",
        second: "vous contactera dès que possible.",
        third: "Sincèrement,",
        fourth: "Deftiq",
      },
      course_available: {
        first: "Le cours vous est maintenant accessible.",
        second: "Sincèrement,",
        third: "Deftiq",
      },
      catalogue_header_sir: "Aperçu des cours SIR",
      course_added_to: "Le cours a été ajouté à ",
      start_up_gone_wrong: "Oups, quelque chose ne va pas au démarrage.",
    },
    profile: {
      profile: "Profil",
      skillset: "Compétences",
      firstname: "Prénom",
      lastname: "Nom de famille",
      receive_emails:
        "Je veux recevoir des e-mails de Deftiq concernant mes cours.",
      company: "Organisation",
      reset_password: "Réinitialiser le mot de passe",
      profile_cant_load: "Le profil n'a pas pu être chargé.",
      profile_changes_saved:
        "Les modifications de votre profil ont été enregistrées.",
      profile_changes_not_saved:
        "Les modifications de votre profil n'ont pas été enregistrées.",
      are_you_sure: "Vous êtes sûr ?",
      delete_all_account_data:
        "Vous supprimerez ainsi toutes les données de votre compte.",
      cancel_button: "ANNULER",
      confirm_button: "SUPPRIMER",
      account_deleted: "Votre compte a été supprimé. Au revoir !",
      account_not_deleted: "Votre compte n'a pas été supprimé.",
      gender: {
        gender: "Genre",
        male: "Homme",
        female: "Femme",
        unknown: "Inconnu",
      },
    },
    quiz: {
      previous: "Précédent",
      next_button: "suivant",
      are_you_sure: "Vous êtes sûr ?",
      no_changes_after_completion:
        "Vous ne pouvez pas modifier les réponses une fois que vous avez soumis le test.",
      cancel_button: "ANNULER",
      confirm_button: "ENVOYER",
      finish: "Compléter",
      attempt_wasnt_saved:
        "Votre tentative de quiz n'a pas pu être sauvegardée.",
      fill_answer_first: "Remplissez d'abord une réponse.",
    },
    quiz_results: {
      course: "Cours",
      quiz_results: "Résultats du quiz",
      most_recent_grade: "Dernière note ",
      you_are: "Vous avez ",
      passed: "Réussi",
      failed: "Échoué",
      highest_grade: "Note la plus élevée obtenue ",
      history: "Historique",
      quiz_attempt: "Tentative de quiz",
      attempt_date: "Date de votre tentative",
      grade: "Note",
      attempt: "tentative",
      date: "date",
      back_to_statistics: "Retour aux statistiques",
      view_earlier_attempts: "Voir les tentatives précédentes",
      max_attempts_reached: "Nombre maximal de tentatives atteint.",
      new_attempt: "Nouvelle tentative",
      questions: "questions",
      attempts: "tentatives",
      attempt_in_review: "la tentative est évaluée",
      needed_to_pass: "nécessaire pour passer",
      start_quiz: "Démarrer",
      no_attempts_left: "Vous n'avez plus de tentatives de quiz.",
      expires_in_one_hour: "Votre session expire dans 1 heure.",
      login_again:
        "Vous êtes connecté depuis longtemps. Vos progrès peuvent être perdus si vous commencez un quiz maintenant. Nous vous recommandons de vous reconnecter d'abord.",
      cancel_button: "ANNULER",
      confirm_button: "SUPPRIMER",
    },
    quiz_feedback: {
      feedback_attempt: "Un retour d'information sur votre tentative de quiz",
      incorrect: "Mauvaise réponse",
      correct: "Bonne réponse",
      no_feedback_question:
        "Il n'y a pas de retour d'information avec cette question",
      results_overview: "Aperçu des résultats",
      course: "Cours",
      quiz_results: "Résultats du quiz",
      quiz_attempt_feedback: "Retour sur les tentatives de quiz",
      participant: "Participant",
      results: "Résultats",
    },
    breadcrumb: { overview: "Vue d'ensemble" },
    language_picker: {
      course_language: {
        first: "Dans quelle langue souhaitez-vous faire le quiz ",
        second: " ?",
      },
      action_pick: "Sélectionnez votre langue préférée ci-dessous.",
      choice_saved: "Votre choix de langue a été enregistré.",
      went_wrong: "Quelque chose a mal tourné : ",
    },
    course_expiry_message: {
      header: "Le {coursename} a une durée de {days_until_expired} jours.",
      description:
        "Une fois que vous avez choisi une langue, vous disposez de {days_until_expired} jours pour terminer le cours. Bonne chance!",
    },
    password_reset: {
      instructions:
        "Saisissez votre adresse électronique et nous vous enverrons des instructions pour réinitialiser votre mot de passe.",
      no_attempts:
        "Vous n'avez plus de tentatives. Veuillez réessayer dans 10 minutes.",
      incorrect_email: "L'adresse électronique est incorrecte.",
      unknown_account: "Le compte Deftiq est inconnu.",
      safety_update:
        "Nous avons effectué une mise à jour de sécurité pour optimiser la sécurité de la plateforme d'apprentissage. Votre compte est ainsi encore mieux protégé.",
      fill_in_code_below: "Entrez votre code à 6 chiffres ci-dessous",
      fill_in_code: {
        first: "Un code à 6 chiffres a été envoyé à votre adresse e-mail. ",
        second: " . Entrez le code ci-dessous.",
      },
      code: "Code",
      incorrect_code: "Le code est incorrect.",
      extra_security_step:
        "Cette étape supplémentaire permet de sécuriser votre compte. Veuillez entrer un nouveau mot de passe ci-dessous. Ce mot de passe doit être composé d'au moins 8 caractères",
      fill_in_new_password_below:
        "Veuillez entrer un nouveau mot de passe ci-dessous. Ce mot de passe doit comporter au moins 8 caractères.",
      password_minimum: "Le mot de passe doit comporter au moins 8 caractères.",
      repeat_password: "Saisissez à nouveau votre mot de passe.",
      passwords_dont_match: "Les mots de passe ne correspondent pas.",
      change_password: "Modifier le mot de passe",
      check_email: "Vérifiez votre courrier électronique",
      cybersecurity_update: "Mise à jour sur la cybersécurité",
      new_password: "Créer un nouveau mot de passe",
      send_reset_email: "Envoyer un e-mail de réinitialisation",
      change_your_password: "Modifier le mot de passe",
      no_attempts_left: "Vous n'avez plus de tentatives.",
      password_changed: "Votre mot de passe a été modifié.",
      something_went_wrong: "Oups, quelque chose ne va pas.",
    },
    feedback_button: {
      question: "Vous avez une question ?",
      invalid_email: "Cette adresse électronique n'est pas valide.",
      subject: "Sujet",
      invalid_subject: "L'objet ne doit pas être vide.",
      message: "Message",
      invalid_message: "Le message ne doit pas être vide.",
      send: "envoyer",
      question_thanks: "Merci pour votre question.",
      received_message_greetings: {
        first:
          "Nous avons reçu votre question. Nous ferons de notre mieux pour répondre à votre question dans les meilleurs délais.",
        second: "Cordialement,",
        third: "Anna,",
        fourth: "Au nom de l'équipe ",
      },
      ask_question: "Posez votre question",
    },
    birth_date: {
      birthdate: "Date de naissance",
      eightteen_or_older: "Avez-vous plus de 18 ans ?",
      fill_in_birthdate: "Entrez votre date de naissance",
      birth_date_mandatory:
        "Votre date de naissance est requise pour pouvoir participer à ce cours.",
      birth_date_general:
        "Cet environnement d'apprentissage n'est accessible qu'aux participants ayant atteint l'âge légal.",
      birth_date_message:
        "Inscrivez les dates de naissance des participants ci-dessous listés. Ceci est nécessaire pour pouvoir participer à l'examen.",
    },
    catalogue_popup: {
      sign_up: "S'inscrire +",
      duration: "Durée",
      language: "Langue",
      price_per_person: "Prix p.p.",
      save: "sauvegarder",
      cancel: "annuler",
      course_modified: "Le cours a changé.",
      course_not_modified: "Le cours ne peut pas être modifié.",
    },
    waiting_list: {
      fill_in_info: "Entrez vos coordonnées",
      notification:
        "Nous vous enverrons une notification lorsque le cours sera publié.",
      invalid_email: "L'adresse e-mail n'est pas valide",
      send: "ENVOYER",
      added_to_list: "Vous avez été ajouté à la liste d'attente.",
      something_went_wrong: "Oups, quelque chose a mal tourné.",
    },
    register_popup: {
      firstname: "Prénom",
      firstname_mandatory: "Votre prénom est obligatoire.",
      lastname: "Nom de famille",
      lastname_mandatory: "Votre nom de famille est obligatoire.",
      company: "Organisation",
      company_mandatory: "Une organisation est nécessaire.",
      language: "Langue",
      conditions_agree: {
        first: " J'ai lu et j'accepte ",
        second: " les conditions (terms=hyperlink) ",
        third: " et la",
        fourth: "politique de confidentialité (privacy policy=hyperlink)",
        fifth: " .",
      },
      agreed_mandatory: "Vous devez accepter les termes et conditions.",
      verification_code: {
        first: "Nous avons envoyé un code de vérification à ",
        second:
          " (Vérifiez aussi vos spams). Entrez votre code ci-dessous et cliquez sur « Continuer ».",
      },
      code: "Code",
      incorrect_code: "Le code est incorrect.",
      fill_in_info: "Entrez vos coordonnées",
      verify_email: "Vérifiez votre adresse e-mail",
      check_info: "Vérifiez vos coordonnées",
      register: "Registre",
      verify: "Vérifier",
      email_verified: "Votre adresse électronique a été vérifiée.",
    },
    bookingpage: {
      back: "Retour",
      cancel: "Annuler",
      next: "Suivant",
      confirm: "Confirmer",
      pay: "Payer",
      search: "Recherche",
      exam: "EXAMEN",
      exam_lowercase: "Examen",
      messages: {
        add_exam_completed: "Vous avez ajouté l'examen avec succès.",
        update_elearning_completed: "Vous avez actualisé l'examen avec succès.",
        booking_failed:
          "Un problème est survenu lors du traitement de votre réservation.\n Contactez support@deftiq.com ",
        process_booking: "Votre réservation va être traitée.",
        pay_for_booking: "Vous serez redirigé vers la page de paiement.",
        patience_please: "Un instant, s'il vous plaît.",
        booking_completed: "Les participants ont été inscrits.",
      },
      close: {
        are_you_sure: "Êtes-vous sûr ?",
        description:
          "Vous êtes sur le point d'annuler la réservation de manière anticipée. Les données de réservation seront perdues.",
        yes: "OUI",
        no: "NON",
      },
      header: {
        select_an_option: "Sélectionner une option",
        select_course: "Sélectionner un cours de formation",
        select_participants: "Sélectionner un ou plusieurs participants",
        add_participant: "Ajouter un nouveau participant",
        select_wishes: "Sélectionnez vos besoins de formation",
        confirm_info: "Confirmer les données",
      },
      available_from: "à partir de",
      options: {
        e_learning: "Apprentissage en ligne",
        e_learning_and_exam: "Apprentissage en ligne avec Examen",
        exam: "Examen",
      },
      filters: {
        all_courses: "Tous les cours de formation",
        free_courses: "Cours de formation gratuits",
        premium_courses: "Cours de formation Premium",
        all_sectors: "Tous les secteurs",
        industry: "Industrie",
        construction: "Construction & Infrastructure",
        offshore: "Énergie renouvelable en mer",
      },
      moment_picker: {
        all_cities: "Toutes les villes",
        all_languages: "Toutes les langues",
        all_months: "Tous les mois",
        select_moment:
          "Sélectionnez le créneau horaire souhaité pour votre examen",
        no_moments: "Aucune plage horaire disponible n'a été trouvée.",
        months: {
          january: "Janvier",
          february: "Février",
          march: "Mars",
          april: "Avril",
          may: "Mai",
          june: "Juin",
          july: "Juillet",
          august: "Août",
          october: "Octobre",
          september: "Septembre",
          november: "Novembre",
          december: "Décembre",
        },
        days: {
          monday: "Lun",
          tuesday: "Mar",
          wednesday: "Mer",
          thursday: "Jeu",
          friday: "Ven",
          saturday: "Sam",
          sunday: "Dim",
        },
      },
      payment_information: {
        header: "Détails de réservation",
        course: "Cours",
        course_type: "Type de formation",
        amount_of_users: "Nombre de participants",
        location: "Adresse",
        examdate: "Date de L'examen",
        e_learning_available: "Lancement de l'apprentissage en ligne",
        payment_method: "Mode de paiement",
        payment_message:
          "Vous pouvez payer directement en ligne avec notre partenaire Mollie.",
        examlanguage: "Langue de l'examen",
      },
      invoice_information: {
        header: "Données de la facture",
        company_name: "Nom de l'entreprise*",
        company_required: "Le nom de l'entreprise est exigé.",
        contactperson: "Contact*",
        contactperson_required: "Une personne de contact est requise.",
        invoice_email: "Adresse e-mail de facturation*",
        invoice_email_required: "Une adresse e-mail est requise.",
        contactperson_email: "Adresse e-mail du contact*",
        contactperson_email_required: "Une adresse e-mail est requise.",
        street: "Nom de la rue et numéro de la maison*",
        street_required:
          "Le nom de la rue et le numéro de la maison sont exigés.",
        city: "Ville*",
        city_required: "La ville est exigée.",
        postalcode: "Code postal*",
        postalcode_required: "Le code postal est exigé.",
        company: "Organisation",
        free_users: "Les participants peuvent accéder gratuitement au cours.",
        price: "Prix",
        transaction_cost: "Coûts de transaction",
        cancel_costs: "Frais d'annulation",
        payment_method: "Mode de paiement",
      },
      cost: { total: "Total", subtotal: "Sous-total", vat: "TVA" },
      courses: { course: "Cours", sector: "Secteur", language: "Langue" },
      users: {
        enroll: "S'inscrire",
        fullname: "Nom complet",
        email: "Adresse e-mail",
      },
      add_new_user: "Ajouter un nouveau participant",
      policy: {
        agree_with: "J'accepte",
        general_policy: "les conditions générales,",
        course_policy: "les conditions de formation",
        and: "et",
        exam_policy: "les conditions d'examen.",
      },
    },
    add_user: {
      firstname: "Prénom",
      lastname: "Nom de famille",
      email: "Adresse e-mail",
      works_at: "Travaille à",
      extra_info: "Informations complémentaires",
      language: "Langue",
      company: "Organisation",
      role: "Rôle",
      birthdate: "Date de naissance",
      required: {
        firstname: "Un prénom est exigé.",
        lastname: "Un nom de famille est exigé.",
        email: "L'adresse e-mail n'est pas valide.",
        works_at: "Saisissez le nom d'une entreprise.",
        birthdate:
          "Saisissez une date de naissance valide. Un participant doit être âgé de 18 ans ou plus.",
        birthdate_short: "Saisissez une date de naissance valide.",
      },
    },
    expired: "expiré",
    course_language: {
      update: "Modifier la langue",
      are_you_sure: "Êtes-vous sûr ?",
      description:
        "Vous recommencerez l'apprentissage en ligne si vous choisissez une autre langue.",
      cancel: "ANNULER",
      pick_language: "MODIFIER LA LANGUE",
    },
    course_overview: {
      add_course: "Ajouter un cours",
      access_expired: "Accès expiré",
      access_scheduled: "Programmé",
      percent_completed: "% terminé.",
      no_available_courses: "Vous n'avez pas de cours disponibles.",
      course_overview_cant_load: "L'aperçu du cours n'a pas pu être chargé.",
      active_courses: "Cours actifs",
      expired_courses: "Cours expirés",
      all_courses: "Tous les cours",
    },
    administration_overview: {
      admin: "Administration",
      my_admin: "Mon administration",
    },
    participant_overview: {
      breadcrumb_user: "Participants",
      breadcrumb_admin: "Mes participants",
      errors: { delete: "Les participants ne sont pas retirés." },
      succes: { delete: "Les participants sont retirés." },
    },
    total_overview: { breadcrumb: "Vue d'ensemble" },
    participant_profile: {
      errors: { invalid_email: "L'adresse électronique est déjà utilisée." },
    },
    skill_overview: { skils: "Compétences" },
  },
  pt: {
    next: "Próximo",
    email: "E-mail",
    password: "Senha",
    error: {
      default: "Oops, algo deu errado. Por favor, contate support@deftiq.com",
    },
    search: "Busca",
    add: "Adicione",
    continue: "Continue",
    language: "Língua",
    save: "Salvar",
    cancel: "Cancelar",
    finished: "completado",
    busy: "em progresso",
    scheduled: "programado",
    not_started: "não iniciado",
    company_page: {},
    error_page: {
      general: "Oops, algo deu errado.",
      not_found:
        "Foi direcionado para uma página que não existe (mais). Estamos felizes em colocá-lo de volta nos trilhos.",
      invite_expired:
        "Este link de convite expirou ou está incorreto. Verifique se há outro link de convite na caixa de correio. <br> Não consegue descobrir? Entre em contato com support@deftiq.com",
      direct_closed:
        "O acesso direto da sua organização a este curso de treinamento foi encerrado. <br>Contate support@deftiq.com se deveria ter acesso.",
    },
    login: {
      welcome: "Bem-vindo a ",
      fill_in_password: "Insira a senha",
      fill_in_email_below: "Por favor, insira abaixo o seu endereço de e-mail.",
      fill_in_password_below: "Por favor, insira a sua senha abaixo.",
      create_an_account: "Crie uma conta",
      forgot_password: "Senha esquecida",
      open_invite: "Abrir o convite",
      login: "login",
      errors: {
        wrong_password_or_email:
          "O endereço de e-mail ou senha estão incorretos.",
        no_account_yet:
          "Não foi possível encontrar uma conta com este endereço de e-mail.",
        open_invite:
          "Há um convite esperando por Você mesmo para se registrar. Isto foi enviado de novo para",
        open_invite_short:
          "Há um convite esperando por Você mesmo para se registrar.",
      },
      session_expired: "A sua sessão expirou",
    },
    training: {
      activity_locked:
        "Esta atividade ainda não está disponível. Certifique-se de ter completado as atividades anteriores.",
      activity_finished: "Completado",
      activity_not_started: "não iniciado",
      activity_doing: "em progresso",
      course_passed: {
        first: "Você passou o curso",
        second: " com uma nota de ",
        percent: " %.",
      },
      download_certificate:
        " Faça o download de sua prova de participação aqui.",
      no_attempts_left:
        "Você não tem mais tentativas para iniciar a atividade.",
      no_rise_training_available:
        "A atividade não contém o treinamento de RISE.",
    },
    navbar: {
      student: {
        my_overview: "Meu resumo",
        my_results: "Meus Resultados",
        my_profile: "Meu Perfil",
        catalogue: "Catálogo",
        overview: "Resumo geral",
        results: "Resultados",
        profile: "Perfil",
      },
      supervisor: {
        my_dashboard: "Meu Painel de Controle",
        my_courses: "Meus Cursos",
        my_participants: "Meus Participantes",
        my_administration: "My Administration",
        catalogue: "Catálogo",
        my_profile: "Meu Perfil",
        helpcenter: "Centro de Ajuda",
        dashboard: "Painel de controle",
        profile: "Perfil",
        courses: "Cursos",
        participants: "Participantes",
      },
      admin: {
        dashboard: "Painel de controle",
        companies: "Empresas",
        courses: "Cursos",
        participants: "Participantes",
        contracts: "Contratos",
        catalogue: "Catálogo",
        emails: "E-mails",
        profile: "Perfil",
      },
      system_status: "Estado do sistema",
      logout: "Logout",
      session_expires: {
        in_one_hour: "A sua sessão expira em 1 hora.",
        expired: "A sua sessão expirou.",
      },
    },
    results: {
      results: "Resultados",
      title_course_name: "O nome do curso",
      course: "Curso",
      title_course_status: "Status de treinamento",
      course_status: "Status",
      title_course_progress: "O seu progresso",
      course_progress: "Progresso (%)",
      title_course_grade: "O seu resultado obtido",
      course_grade: "Categoria",
      title_course_finish_date: "Data de fim do curso",
      course_finish_date: "Data de fim",
      no_courses_finished_yet: "Você ainda não completou algum curso.",
      results_could_not_load: "Os resultados não poderiam ser carregados.",
    },
    course_overview: {
      access_expired: "O acesso expirou",
      access_scheduled: "Agendado",
      percent_completed: "% completo.",
      no_available_courses: "Você não tem os cursos disponíveis.",
      course_overview_cant_load: "O resumo do curso não pôde ser carregado.",
    },
    evaluation: {
      your_anwser: "A sua resposta",
      anwser_question_mandatory:
        "Você deve responder a esta pergunta para completar a avaliação",
      submit_evaluation: "Submeter",
      thank_you_message: "Obrigado por ter completado a avaliação.",
      continue_with_course: "Você pode continuar o seu caminho de aprendizagem",
    },
    register: {
      email_code_auth: "Insira aqui o código recebido via e-mail.",
      code: "Código",
      incorrect_code: "O código é incorreto",
      enter_email_and_continue:
        "Insira o seu endereço de e-mail abaixo e pressione em seguida.",
      incorrect_email: "O endereço de e-mail é incorreto.",
      enter_password_for_login: "Insira a senha para fazer o login.",
      firstname: "O primeiro nome",
      firstname_mandatory: "O seu primeiro nome é obrigatório.",
      lastname: "Apelido",
      lastname_mandatory: "O seu apelido é obrigatório.",
      company: "Organização",
      company_mandatory: "A organização é necessária.",
      preference_lang_courses: "A língua preferida do platformo",
      conditions: {
        first: " Li e concordo com ",
        second: " os termos ",
        third: " e ",
        fourth: " a política de privacidade ",
        fifth: " .",
      },
      accept_conditions_mandatory:
        "Você deve concordar com os termos e condições.",
      password_must_contain: "A senha deve ter pelo menos 8 caracteres.",
      repeat_password: "Insira novamente a sua senha.",
      passwords_dont_match: "As senhas não são iguais.",
      identity_check: {
        first:
          "Basta verificar se é mesmo Você. Nos enviamos um código de verificação para ",
        second: " (Verifique também o seu spam).",
      },
      resent_invitation:
        "Há outro convite esperando por Você mesmo para se registrar. Isto foi enviado de novo para ",
      forgot_password: "Esqueceu a senha?",
      switch_case_data: {
        fill_in_code: "Insira o seu código",
        welcome_to: "Bem-vindo a ",
        fill_in_data: "Insira os seus dados",
        fill_in_password: "Insira a senha",
        think_of_password: "Crie uma senha",
        open_invite: "Abrir o convite",
        extra_information: "Informações adicionais",
      },
      register: "Registre-se",
      login: "Login",
      page_doesnt_exist: "Oops, esta página não existe.",
      page_load_error: "Algo correu mal no carregamento da página.",
      admin_account_already_exists: "Você já tem uma conta de administrador",
      invite_not_accepted: "Você ainda não aceitou o seu convite.",
      gone_wrong_contact_deftiq:
        "Oops, alguma coisa correu mal. Contate support@deftiq.com",
    },
    register_no_course: {
      become_supervisor: "Torne-se o administrador!",
      become_student: "Torne-se o participante",
      account_already_exists_login:
        "Você já tem uma conta existente para fazer o login.",
      account_already_exists_as: "Você já tem uma conta existente como ",
      give_your_organisation:
        "E dê à sua organização o acesso a nosso ambiente de aprendizagem interativo e cursos exclusivos.",
      get_access_courses:
        "E tenha acesso a um rico catálogo repleto de cursos únicos.",
      fill_in_email_supervisor:
        "Preencha o seu e-mail e veja a nossa plataforma. Gratuitamente.",
      fill_in_email_participant: "Preencha o seu e-mail e pressione abaixo.",
    },
    catalogue: {
      catalogue: "Catálogo",
      all_sectors: "Todos os setores",
      industry: "Indústria",
      construction_and_infra: "Construção e Infraestrutura",
      ore: "Energia Renovável no Offshore",
      sir: "SIR",
      overview_ore: "Descrição geral dos cursos do ORE",
      overview_sir: "Descrição geral dos cursos de SIR",
      all_languages: "Todas as línguas",
      course_type: "Tipo de curso",
      no_courses_found: "Nenhum curso foi encontrado.",
      interested_in_course: {
        first: "Estamos atentos de seu interesse e",
        second: "entrará em contato com Você o mais rápido possível.",
        third: "Com os melhores cumprimentos,",
        fourth: "Deftiq",
      },
      course_available: {
        first: "O curso agora está disponível para Você.",
        second: "Com os melhores cumprimentos,",
        third: "Deftiq",
      },
      catalogue_header_sir: "Descrição geral dos cursos de SIR",
      course_added_to: "O curso foi adicionado a ",
      start_up_gone_wrong: "Oops, alguma coisa dá errado quando se começa.",
    },
    profile: {
      profile: "Perfil",
      skillset: "Skillset",
      firstname: "O primeiro nome",
      lastname: "Apelido",
      receive_emails:
        "Eu quero receber e-mails da Deftiq sobre os meus cursos.",
      company: "Organização",
      reset_password: "Alterar a senha",
      profile_cant_load: "O perfil não podia ser carregado.",
      profile_changes_saved: "O seu perfil foi salvo.",
      profile_changes_not_saved: "O seu perfil não foi salvo.",
      are_you_sure: "Você tem certeza?",
      delete_all_account_data:
        "Assim, Você excluirá todos os dados de sua conta.",
      cancel_button: "CANCELAR",
      confirm_button: "DELETE",
      account_deleted: "Sua conta foi deletada. Adeus!",
      account_not_deleted: "A sua conta não foi deletada.",
      gender: {
        gender: "Gênero",
        male: "Masculino",
        female: "Feminino",
        unknown: "Desconhecido",
      },
    },
    quiz: {
      previous: "Previamente",
      next_button: "próximo",
      are_you_sure: "Você tem certeza?",
      no_changes_after_completion:
        "Você não pode mudar as respostas uma vez que tenha submetido o teste.",
      cancel_button: "CANCELAR",
      confirm_button: "SUBMETER",
      finish: "Complete",
      attempt_wasnt_saved:
        "A sua tentativa de questionário não pôde ser salva.",
      fill_answer_first: "Preencha uma resposta em primeiro lugar.",
    },
    quiz_results: {
      course: "Curso",
      quiz_results: "Resultados do Questionário",
      most_recent_grade: "Nota mais recente ",
      you_are: "Você tem ",
      passed: "Passado",
      failed: "Falha",
      highest_grade: "A melhor nota obtida ",
      history: "A história",
      quiz_attempt: "Tentativa de questionário",
      attempt_date: "Data de sua tentativa",
      grade: "Categoria",
      attempt: "tentativa",
      date: "data",
      back_to_statistics: "Regressa às estatísticas",
      view_earlier_attempts: "Veja as tentativas anteriores",
      max_attempts_reached: "Número máximo de tentativas alcançadas.",
      new_attempt: "A tentativa nova",
      questions: "Perguntas",
      attempts: "Tentativas",
      attempt_in_review: "a tentativa é feita",
      needed_to_pass: "necessário para passar",
      start_quiz: "Começar",
      no_attempts_left:
        "Você não tem mais tentativas de perguntas e respostas.",
      expires_in_one_hour: "A sua sessão expira em 1 hora.",
      login_again:
        "Você já está conectado há muito tempo. O seu progresso pode ser perdido se Você inicia um questionário agora mesmo. Recomendamos que Você faça o login de novo em primeiro lugar.",
      cancel_button: "CANCELAR",
      confirm_button: "DELETE",
    },
    quiz_feedback: {
      feedback_attempt: "Comentários sobre a sua tentativa de questionário",
      incorrect: "Incorreto",
      correct: "Correto",
      no_feedback_question: "Não há comentários com esta pergunta.",
      results_overview: "Resumo dos resultados",
      course: "Curso",
      quiz_results: "Resultados do questionário",
      quiz_attempt_feedback: "Comentários sobre as tentativas de questionário",
      participant: "Participante",
      results: "Resultados",
    },
    breadcrumb: {
      overview: "Resumo geral",
    },
    language_picker: {
      course_language: {
        first: "Qual idioma Você gostaria de usar ",
        second: " ?",
      },
      action_pick: "Selecione o idioma de sua preferência abaixo.",
      choice_saved: "A seleção de seu idioma foi salva.",
      went_wrong: "Alguma coisa correu mal: ",
    },

    course_expiry_message: {
      header: "O {coursename} tem duração de {days_until_expired} dias.",
      description:
        "Depois de escolher um idioma, você tem {days_until_expired} dias para concluir o curso. Boa sorte!",
    },

    password_reset: {
      instructions:
        "Insira o seu endereço de e-mail e nós lhe enviaremos instruções para reajustar a sua senha.",
      no_attempts:
        "Você não tem mais tentativas. Por favor, tente de novo em 10 minutos.",
      incorrect_email: "O endereço de e-mail é incorreto.",
      unknown_account: "A conta de Deftiq é incógnita.",
      safety_update:
        "Realizamos uma atualização de segurança para otimizar a segurança da plataforma de aprendizagem. Isto torna a sua conta ainda mais protegida.",
      fill_in_code_below: "Insira o seu código de 6 dígitos abaixo",
      fill_in_code: {
        first:
          "Um código de 6 dígitos foi enviado para o seu endereço de e-mail ",
        second: " . Digite o código abaixo.",
      },
      code: "Código",
      incorrect_code: "O código é incorreto.",
      extra_security_step:
        "Esta etapa adicional assegura a sua conta. Por favor, digite uma senha nova abaixo. Esta senha deve ser composta de pelo menos 8 caracteres",
      fill_in_new_password_below:
        "Por favor, digite uma senha nova abaixo. Esta senha deve ser composta de pelo menos 8 caracteres.",
      password_minimum: "A senha deve ser composta de pelo menos 8 caracteres.",
      repeat_password: "Insira novamente a sua senha.",
      passwords_dont_match: "As senhas não são iguais.",
      change_password: "Escolha a senha",
      check_email: "Verifique o seu e-mail",
      cybersecurity_update: "Atualização de segurança cibernética",
      new_password: "Crie uma senha nova",
      send_reset_email: "Envie e-mail de reset",
      change_your_password: "Escolha a senha",
      no_attempts_left: "Você não tem mais tentativas.",
      password_changed: "A senha foi alterada.",
      something_went_wrong: "Oops, alguma coisa está indo mal.",
    },
    feedback_button: {
      question: "Você tem alguma pergunta?",
      invalid_email: "Este não é um endereço de e-mail válido.",
      subject: "Sujeito",
      invalid_subject: "O sujeito não deve estar em branco.",
      message: "Mensagem",
      invalid_message: "A mensagem não deve estar em branco.",
      send: "envie",
      question_thanks: "Obrigado por sua pergunta.",
      received_message_greetings: {
        first:
          "Recebemos a sua pergunta. Faremos o melhor possível para responder à sua pergunta o mais rápido possível.",
        second: "Atenciosamente,",
        third: "Anna,",
        fourth: "Em nome da equipe de ",
      },
      ask_question: "Faça a sua pergunta",
    },
    birth_date: {
      birthdate: "Data de nascimento",
      eightteen_or_older: "É maior de 18 anos?",
      fill_in_birthdate: "Insira a sua data de nascimento",
      birth_date_mandatory:
        "Sua data de nascimento é necessária para poder participar deste curso.",
      birth_date_general:
        "Este ambiente de aprendizagem só é acessível a participantes maiores de idade.",
      birth_date_message:
        "Insira as datas de nascimento dos participantes listados abaixo. Isso é necessário para poder participar do exame.",
    },
    catalogue_popup: {
      sign_up: "Inscrição +",
      duration: "Duração",
      language: "Língua",
      price_per_person: "Preço de p.p.",
      save: "salvar",
      cancel: "cancelar",
      course_modified: "O curso mudou.",
      course_not_modified: "O curso não pode ser alterado.",
    },
    waiting_list: {
      fill_in_info: "Insira os seus dados",
      notification:
        "Nós lhe enviaremos uma notificação quando o curso for publicado.",
      invalid_email: "O endereço de e-mail é inválido",
      send: "ENVIE",
      added_to_list: "Você foi adicionado à lista de espera.",
      something_went_wrong: "Oops, alguma coisa correu mal.",
    },
    register_popup: {
      firstname: "O primeiro nome",
      firstname_mandatory: "O seu primeiro nome é obrigatório.",
      lastname: "Apelido",
      lastname_mandatory: "O seu apelido é obrigatório.",
      company: "Organização",
      company_mandatory: "A organização é necessária.",
      language: "Língua",
      conditions_agree: {
        first: " Li e concordo com ",
        second: " os termos ",
        third: " e",
        fourth: "a política de privacidade",
        fifth: " .",
      },
      agreed_mandatory: "Você deve concordar com os termos e condições.",
      verification_code: {
        first: "Nos enviamos um código de verificação para ",
        second:
          " (Verifique também o seu spam). Insira o seu código abaixo e pressione para continuar.",
      },
      code: "Código",
      incorrect_code: "O código é incorreto.",
      fill_in_info: "Insira os seus dados",
      verify_email: "Verifique o seu endereço de e-mail",
      check_info: "Confira os seus dados",
      register: "Registre-se",
      verify: "Verifique",
      email_verified: "O seu endereço de e-mail foi verificado.",
    },

    bookingpage: {
      back: "Voltar",
      cancel: "Cancelar",
      next: "Próximo",
      confirm: "Confirmar",
      pay: "Pagar",
      search: "Pesquisar",
      exam: "EXAME",
      exam_lowercase: "Exame",
      messages: {
        add_exam_completed: "Adicionou o exame com sucesso.",
        update_elearning_completed: "Adicionou o exame com sucesso.",
        booking_failed:
          "Ocorreu um erro durante o processamento da sua reserva.\n Contacte support@deftiq.com ",
        process_booking: "A sua reserva será processada.",
        pay_for_booking: "Será redirecionado para a página de pagamentos.",
        patience_please: "Um momento, por favor.",
        booking_completed: "Os participantes foram inscritos.",
      },
      filters: {
        all_courses: "Todos os cursos de formação",
        free_courses: "Cursos de formação gratuitos",
        premium_courses: "Cursos de formação Premium",
        all_sectors: "Todos os setores",
        industry: "Indústria",
        construction: "Construção e Infraestrutura",
        offshore: "Energia Renovável no Offshore",
      },
      moment_picker: {
        all_cities: "Todas as cidades",
        all_languages: "Todos os idiomas",
        all_months: "Todos os meses",
        select_moment: "Selecione o horário desejado para o seu exame",
        no_moments: "Não foram encontrados espaços horários disponíveis.",
        months: {
          january: "Janeiro",
          february: "Fevereiro",
          march: "Março",
          april: "Abril",
          may: "Maio",
          june: "Junho",
          july: "Julho",
          august: "Agosto",
          october: "Outubro",
          september: "Setembro",
          november: "Novembro",
          december: "Dezembro",
        },
        days: {
          monday: "Seg",
          tuesday: "Ter",
          wednesday: "Qua",
          thursday: "Qui",
          friday: "Sex",
          saturday: "Sáb",
          sunday: "Dom",
        },
      },
      close: {
        are_you_sure: "Tem a certeza?",
        description:
          "Está prestes a cancelar a reserva antecipadamente. Os dados da reserva serão perdidos.",
        yes: "SIM",
        no: "NÃO",
      },
      header: {
        select_an_option: "Selecione uma opção",
        select_course: "Selecione um curso de formação",
        select_participants: "Selecione um ou mais participantes",
        add_participant: "Adicionar um novo participante",
        select_wishes: "Selecione as suas necessidades de formação",
        confirm_info: "Confirmar dados",
      },
      available_from: "de",
      options: {
        e_learning: "E-learning",
        e_learning_and_exam: "E-learning com Exame",
        exam: "Exame",
      },
      payment_information: {
        header: "Detalhes da reserva",
        course: "Curso",
        course_type: "Tipo de formação",
        amount_of_users: "Número de participantes",
        location: "Localização",
        examdate: "Data do exame",
        e_learning_available: "Lançamento do e-learning",
        payment_method: "Método de pagamento",
        payment_message:
          "Pode pagar diretamente online com o nosso parceiro Mollie.",
        examlanguage: "Idioma do exame",
      },
      invoice_information: {
        header: "Dados da fatura",
        company_name: "Nome da empresa*",
        company_required: "O nome da empresa é obrigatório.",
        contactperson: "Contacto*",
        contactperson_required: "É necessária uma pessoa de contacto.",
        invoice_email: "Endereço de e-mail da fatura*",
        invoice_email_required: "É necessário um endereço de e-mail.",
        contactperson_email: "Endereço de e-mail para contacto*",
        contactperson_email_required: "É necessário um endereço de e-mail.",
        street: "Nome da rua e número da casa*",
        street_required: "O nome da rua e número da casa são obrigatórios.",
        city: "Localidade*",
        city_required: "A localidade é obrigatória.",
        postalcode: "Código Postal*",
        postalcode_required: "O código postal é obrigatório.",
        company: "Organização",
        free_users: "Os participantes podem aceder ao curso gratuitamente.",
        price: "Preço",
        transaction_cost: "Custos de transação",
        cancel_costs: "Custos de cancelamento",
        payment_method: "Método de pagamento",
      },
      cost: {
        total: "Total",
        subtotal: "Subtotal",
        vat: "IVA",
      },
      courses: {
        course: "Curso",
        sector: "Setor",
        language: "Língua",
      },
      users: {
        enroll: "Inscrever",
        fullname: "Nome completo",
        email: "Endereço de e-mail",
      },
      add_new_user: "Adicionar novo participante",
      policy: {
        agree_with: "Concordo com",
        general_policy: "termos e condições,",
        course_policy: "condições da formação",
        and: "e",
        exam_policy: "condições do exame",
      },
    },
    add_user: {
      firstname: "Nome próprio",
      lastname: "Apelido",
      email: "Endereço de e-mail",
      works_at: "Trabalha na empresa",
      extra_info: "Informações adicionais",
      language: "Língua",
      company: "Organização",
      role: "Função",
      birthdate: "Data de nascimento",
      required: {
        firstname: "O nome próprio é obrigatório.",
        lastname: "O apelido é obrigatório.",
        email: "O endereço de e-mail é inválido.",
        works_at: "Inserir uma empresa.",
        birthdate:
          "Inserir uma data de nascimento válida. O participante deve ter 18 anos de idade ou mais.",
        birthdate_short: "Insira uma data de nascimento válida.",
      },
    },
  },
  tr: {
    next: "İleri",
    email: "E-posta",
    password: "Parola",
    error: {
      default:
        "Hay aksi! Bir şeyler ters gitti. Lütfen support@deftiq.com ile iletişime geçin",
    },
    search: "Ara",
    add: "Ekle",
    continue: "Devam et",
    language: "Dil",
    save: "Kaydet",
    cancel: "İptal",
    finished: "tamamlandı",
    busy: "Başladı",
    scheduled: "zamanlandı",
    not_started: "başlanmadı",
    company_page: {},
    login: {
      welcome: "Hoşgeldiniz ",
      fill_in_password: "Parolayı girin",
      fill_in_email_below: "Lütfen aşağıya e-posta adresinizi girin.",
      fill_in_password_below: "Lütfen aşağıya parolanızı giriniz.",
      create_an_account: "Bir hesap oluşturun",
      forgot_password: "Parolamı unuttum",
      open_invite: "Açık davetiye",
      login: "oturum aç",
      errors: {
        wrong_password_or_email: "E-posta adresi veya parola yanlış.",
        no_account_yet: "Bu e-posta adresine ait bir hesap bulamadık.",
        open_invite:
          "Kaydolmanız için sizi bekleyen bir davetiye var. Şu e-posta adresine tekrar gönderildi:",
        open_invite_short: "Kaydolmanız için sizi bekleyen bir davetiye var.",
      },
      session_expired: "Oturum süresi doldu",
    },
    training: {
      activity_locked:
        "Bu etkinlik henüz kullanılamamaktadır. Önceki etkinlikleri tamamladığınızdan emin olun.",
      activity_finished: "Tamamlandı",
      activity_not_started: "başlanmadı",
      activity_doing: "ilerleme halinde",
      course_passed: {
        first: "Kursu geçtiniz",
        second: " Puanınız ",
        percent: " %.",
      },
      download_certificate: " Katılım belgenizi buradan indirin.",
      no_attempts_left: "Etkinliği başlatmak için deneme hakkınız yok.",
      no_rise_training_available: "Etkinlik RISE eğitimi içermez.",
    },
    navbar: {
      student: {
        my_overview: "Genel Bakış",
        my_results: "Sonuçlarım",
        my_profile: "Profilim",
        catalogue: "Katalog",
        overview: "Genel bakış",
        results: "Sonuçlar",
        profile: "Profil",
      },
      supervisor: {
        my_dashboard: "Kontrol Panelim",
        my_courses: "Kurslarım",
        my_participants: "Katılımcılarım",
        my_administration: "My Administration",
        catalogue: "Katalog",
        my_profile: "Profilim",
        helpcenter: "Yardım Merkezi",
        dashboard: "Kontrol Paneli",
        profile: "Profil",
        courses: "Kurslar",
        participants: "Katılımcılar",
      },
      admin: {
        dashboard: "Kontrol Paneli",
        companies: "Şirketler",
        courses: "Kurslar",
        participants: "Katılımcılar",
        contracts: "Sözleşmeler",
        catalogue: "Katalog",
        emails: "E-postalar",
        profile: "Profil",
      },
      system_status: "Sistem Durumu",
      logout: "Oturumu kapat",
      session_expires: {
        in_one_hour: "Oturumunuz 1 saat içinde sona erecek.",
        expired: "Oturumunuzun süresi doldu.",
      },
    },
    results: {
      results: "Sonuçlar",
      title_course_name: "Kursun adı",
      course: "Kurs",
      title_course_status: "Eğitim durumu",
      course_status: "Durum",
      title_course_progress: "İlerlemeniz",
      course_progress: "İlerleme (%)",
      title_course_grade: "Elde ettiğiniz sonuç",
      course_grade: "Not",
      title_course_finish_date: "Kurs bitiş tarihi",
      course_finish_date: "Bitiş tarihi",
      no_courses_finished_yet: "Henüz herhangi bir kursu tamamlamadınız.",
      results_could_not_load: "Sonuçlar yüklenemedi.",
    },
    course_overview: {
      access_expired: "Erişimin süresi doldu",
      access_scheduled: "Planlanmış",
      percent_completed: "% tamamlandı.",
      no_available_courses: "Mevcut kursunuz yok.",
      course_overview_cant_load: "Kurs genel bakışı yüklenemedi.",
    },
    evaluation: {
      your_anwser: "Cevabınız",
      anwser_question_mandatory:
        "Değerlendirmeyi tamamlamak için bu soruyu cevaplamanız gerekir",
      submit_evaluation: "Gönder",
      thank_you_message: "Değerlendirmeyi tamamladığınız için teşekkür ederiz.",
      continue_with_course: "Öğrenmeye devam edebilirsiniz",
    },
    register: {
      email_code_auth: "E-postanıza gelen kodu buraya girin.",
      code: "Kod",
      incorrect_code: "Kod yanlış",
      enter_email_and_continue:
        "E-posta adresinizi aşağıya girin ve İleri'ye basın.",
      incorrect_email: "E-posta adresi yanlış.",
      enter_password_for_login: "Giriş yapmak için parolanızı girin.",
      firstname: "Ad",
      firstname_mandatory: "Adınız gereklidir.",
      lastname: "Soyad",
      lastname_mandatory: "Soyadınız gereklidir.",
      company: "Kurum",
      company_mandatory: "Kurum gereklidir.",
      preference_lang_courses: "Tercih edilen platform dili",
      conditions: {
        first: " . ",
        second: " Koşullar ",
        third: " ve ",
        fourth: " gizlilik politikasını ",
        fifth: " okudum ve kabul ediyorum.",
      },
      accept_conditions_mandatory: "Hüküm ve koşulları kabul etmelisiniz.",
      password_must_contain: "Parola en az 8 karakter içermelidir.",
      repeat_password: "Parolanızı tekrar girin.",
      passwords_dont_match: "Parolalar eşleşmiyor.",
      identity_check: {
        first:
          "Gerçekten siz olup olmadığınızı kontrol ediyoruz. Adresine bir doğrulama kodu gönderdik ",
        second: " (Spam klasörünü de kontrol edin).",
      },
      resent_invitation:
        "Kaydolmanız için sizi bekleyen başka bir davetiye daha var. Şu e-posta adresine tekrar gönderildi: ",
      forgot_password: "Parolanızı mı unuttunuz?",
      switch_case_data: {
        fill_in_code: "Kodunuzu girin",
        welcome_to: "Hoşgeldiniz ",
        fill_in_data: "Bilgilerinizi girin",
        fill_in_password: "Parolayı girin",
        think_of_password: "Parola oluştur",
        open_invite: "Açık davetiye",
      },
      register: "Kaydol",
      login: "Oturum aç",
      page_doesnt_exist: "Maalesef, bu sayfa mevcut değil.",
      page_load_error: "Sayfayı yüklerken bir sorun oluştu.",
      admin_account_already_exists: "Zaten bir yönetici hesabınız var",
      invite_not_accepted: "Daveti henüz kabul etmediniz.",
      gone_wrong_contact_deftiq:
        "Hay aksi, bir şeyler ters gitti. support@deftiq.com adresiyle iletişime geçin",
    },
    register_no_course: {
      become_supervisor: "Yönetici olun!",
      become_student: "Katılımcı olun",
      account_already_exists_login:
        "Giriş yapmak için zaten mevcut bir hesabınız var.",
      account_already_exists_as: "Zaten olarak mevcut bir hesabınız var ",
      give_your_organisation:
        "Kuruluşunuzun etkileşimli öğrenme ortamımıza ve benzersiz kurslarımıza erişmesini sağlayın.",
      get_access_courses:
        "Ayrıca benzersiz kurslarla dolu zengin bir kataloğa erişin.",
      fill_in_email_supervisor:
        "E-postanızı girin ve platformumuza bir göz atın. Ücretsiz.",
      fill_in_email_participant: "E-postanızı girin ve İleri'ye basın.",
    },
    catalogue: {
      catalogue: "Katalog",
      all_sectors: "Tüm Sektörler",
      industry: "Sanayi",
      construction_and_infra: "İnşaat ve Altyapı",
      ore: "Denizaşırı Yenilenebilir Enerji",
      sir: "SIR",
      overview_ore: "ORE Kurslarına Genel Bakış",
      overview_sir: "SIR Kurslarına Genel Bakış",
      all_languages: "Tüm Diller",
      course_type: "Kurs Türü",
      no_courses_found: "Hiçbir kurs bulunamadı.",
      interested_in_course: {
        first: "İlginizin farkındayız ve",
        second: "en kısa sürede sizinle iletişime geçeceğiz.",
        third: "Sevgiler,",
        fourth: "Deftiq",
      },
      course_available: {
        first: "Artık kursa katılabilirsiniz.",
        second: "Sevgiler,",
        third: "Deftiq",
      },
      catalogue_header_sir: "SIR Kurslarına Genel Bakış",
      course_added_to: "Kurs Genel Bakışım'a eklendi ",
      start_up_gone_wrong: "Hay aksi, bir şeyler ters gitti.",
    },
    profile: {
      profile: "Profil",
      skillset: "Gerekli Beceriler",
      firstname: "Ad",
      lastname: "Soyad",
      receive_emails: "Deftiq'ten kurslarım hakkında e-posta almak istiyorum.",
      company: "Kurum",
      reset_password: "Parolayı sıfırla",
      profile_cant_load: "Profil yüklenemedi.",
      profile_changes_saved: "Profil değişiklikleriniz kaydedildi.",
      profile_changes_not_saved: "Profil değişiklikleriniz kaydedilmedi.",
      are_you_sure: "Emin misiniz?",
      delete_all_account_data: "Böylece hesabınızdaki tüm veriler silinecek.",
      cancel_button: "İPTAL",
      confirm_button: "SİL",
      account_deleted: "Hesabınız silindi. Hoşçakalın!",
      account_not_deleted: "Hesabınız silinmedi.",
    },
    quiz: {
      previous: "Geri",
      next_button: "ileri",
      are_you_sure: "Emin misiniz?",
      no_changes_after_completion:
        "Testi gönderdikten sonra yanıtları değiştiremezsiniz.",
      cancel_button: "İPTAL",
      confirm_button: "GÖNDER",
      finish: "Tamamla",
      attempt_wasnt_saved: "Test denemeniz kaydedilemedi.",
      fill_answer_first: "Önce bir yanıt girin.",
    },
    quiz_results: {
      course: "Kurs",
      quiz_results: "Test Sonuçları",
      most_recent_grade: "En son not ",
      you_are: ". ",
      passed: "Geçtiniz",
      failed: "Geçemediniz",
      highest_grade: "Alınan en yüksek not ",
      history: "Geçmiş",
      quiz_attempt: "Test denemesi",
      attempt_date: "Tarih",
      grade: "Not",
      attempt: "deneme",
      date: "tarih",
      back_to_statistics: "İstatistiklere geri dön",
      view_earlier_attempts: "Önceki denemeleri görüntüle",
      max_attempts_reached: "Ulaşılan maksimum deneme sayısı.",
      new_attempt: "Yeni deneme",
      questions: "sorular",
      attempts: "denemeler",
      attempt_in_review: "deneme değerlendiriliyor",
      needed_to_pass: "geçmek için gerekli",
      start_quiz: "Başla",
      no_attempts_left: "Test denemeniz kalmadı.",
      expires_in_one_hour: "Oturumunuz 1 saat içinde sona erecek.",
      login_again:
        "Oturumunuz uzun süredir açık. Şu anda bir sınava başlarsanız ilerlemeniz kaybolabilir. Önce tekrar giriş yapmanızı öneririz.",
      cancel_button: "İPTAL",
      confirm_button: "SİL",
    },
    quiz_feedback: {
      feedback_attempt: "Test denemenizle ilgili geri bildirim",
      incorrect: "Yanlış",
      correct: "Doğru",
      no_feedback_question: "Bu soruyla ilgili geri bildirim yok",
      results_overview: "Sonuçlara Genel Bakış",
      course: "Kurs",
      quiz_results: "Test sonuçları",
      quiz_attempt_feedback: "Test denemesi geri bildirimi",
      participant: "Katılımcı",
      results: "Sonuçlar",
    },
    breadcrumb: {
      overview: "Genel bakış",
    },
    language_picker: {
      course_language: {
        first: "Hangi dilde almak istersiniz: ",
        second: " ?",
      },
      action_pick: "Aşağıdan tercih ettiğiniz dili seçin.",
      choice_saved: "Dil seçiminiz kaydedildi.",
      went_wrong: "Bir sorun oluştu: ",
    },

    course_expiry_message: {
      header: "The {coursename} has a duration of {days_until_expired} days.",
      description:
        "Once you have chosen a language, you have {days_until_expired} days to complete the course. Good luck!",
    },

    password_reset: {
      instructions:
        "E-posta adresinizi girin. Şifrenizi sıfırlamanız için size gerekli bilgileri göndereceğiz.",
      no_attempts:
        "Deneme hakkınız kalmadı. Lütfen 10 dakika sonra tekrar deneyin.",
      incorrect_email: "E-posta adresi yanlış.",
      unknown_account: "Deftiq hesabı bilinmiyor.",
      safety_update:
        "Öğrenim platformunun güvenliğini optimize etmek için bir güvenlik güncelleştirmesi gerçekleştirdik. Bu, hesabınızı daha da iyi koruyacak.",
      fill_in_code_below: "6 haneli kodunuzu aşağıya girin",
      fill_in_code: {
        first: "Şu e-posta adresine 6 haneli bir kod gönderdik: ",
        second: " okudum ve kabul ediyorum. Kodu aşağıya girin.",
      },
      code: "Kod",
      incorrect_code: "Kod yanlış.",
      extra_security_step:
        "Bu ek adım hesabınızın güvenliğini sağlar. Lütfen aşağıya yeni bir parola girin. Bu parola en az 8 karakterden oluşmalıdır",
      fill_in_new_password_below:
        "Lütfen aşağıya yeni bir parola girin. Bu parola en az 8 karakterden oluşmalıdır.",
      password_minimum: "Parola en az 8 karakterden oluşmalıdır.",
      repeat_password: "Parolanızı tekrar girin.",
      passwords_dont_match: "Parolalar eşleşmiyor.",
      change_password: "Parolayı değiştir",
      check_email: "E-postanızı kontrol edin",
      cybersecurity_update: "Siber güvenlik güncellemesi",
      new_password: "Yeni parola oluştur",
      send_reset_email: "Sıfırlama e-postası gönder",
      change_your_password: "Parolayı değiştir",
      no_attempts_left: "Deneme hakkınız kalmadı.",
      password_changed: "Parolanız değiştirildi.",
      something_went_wrong: "Hay aksi, bir şeyler ters gitti.",
    },
    feedback_button: {
      question: "Bir sorunuz mu var?",
      invalid_email: "Bu geçerli bir e-posta adresi değil.",
      subject: "Konu",
      invalid_subject: "Konu boş bırakılmamalıdır.",
      message: "Mesaj",
      invalid_message: "Mesaj boş bırakılmamalıdır.",
      send: "gönder",
      question_thanks: "Sorunuz için teşekkür ederiz.",
      received_message_greetings: {
        first:
          "Sorunuzu aldık. Sorunuzu en kısa sürede cevaplamak için elimizden gelenin en iyisini yapacağız.",
        second: "Sevgiler,",
        third: "Deftiq ekibi adına Anna",
        fourth: ".",
      },
      ask_question: "Sorunuzu sorun",
    },
    birth_date: {
      fill_in_birthdate: "Doğum tarihinizi girin",
      birth_date_mandatory:
        "Bu kursa katılmak için doğum tarihiniz gereklidir.",
    },
    catalogue_popup: {
      sign_up: "Kayıt ol +",
      duration: "Süre",
      language: "Dil",
      price_per_person: "Ücret",
      save: "kaydet",
      cancel: "iptal",
      course_modified: "Kurs değişti.",
      course_not_modified: "Kurs değiştirilemez.",
    },
    waiting_list: {
      fill_in_info: "Bilgilerinizi girin",
      notification: "Kurs yayınlandığında size bir bildirim göndereceğiz.",
      invalid_email: "E-posta adresi geçersiz",
      send: "GÖNDER",
      added_to_list: "Bekleme listesine eklendiniz.",
      something_went_wrong: "Hay aksi, bir şeyler ters gitti.",
    },
    register_popup: {
      firstname: "Ad",
      firstname_mandatory: "Adınız gereklidir.",
      lastname: "Soyad",
      lastname_mandatory: "Soyadınız gereklidir.",
      company: "Kurum",
      company_mandatory: "Kurum gereklidir.",
      language: "Dil",
      conditions_agree: {
        first: " . ",
        second: " Koşullar ",
        third: " ve",
        fourth: "gizlilik politikasını",
        fifth: " okudum ve kabul ediyorum.",
      },
      agreed_mandatory: "Hüküm ve koşulları kabul etmelisiniz.",
      verification_code: {
        first: "Adresine bir doğrulama kodu gönderdik ",
        second:
          " (Spam klasörünü de kontrol edin). Kodunuzu aşağıya girin ve devam'a basın.",
      },
      code: "Kod",
      incorrect_code: "Kod yanlış.",
      fill_in_info: "Bilgilerinizi girin",
      verify_email: "E-posta adresinizi doğrulayın",
      check_info: "Bilgilerinizi kontrol edin",
      register: "Kaydol",
      verify: "Doğrula",
      email_verified: "E-posta adresiniz doğrulandı.",
    },

    bookingpage: {
      back: "Geri",
      cancel: "İptal",
      next: "İleri",
      confirm: "Onayla",
      pay: "Öde",
      search: "Ara",
      exam: "SINAV",
      exam_lowercase: "Sınav",
      messages: {
        add_exam_completed: "Sınavı başarıyla eklediniz.",
        update_elearning_completed: "Sınavı başarıyla eklediniz.",
        booking_failed:
          "Rezervasyonunuz işlenirken bir sorun oluştu.\n support@deftiq.com adresiyle iletişime geçin ",
        process_booking: "Rezervasyonunuz işleme alınacaktır.",
        pay_for_booking: "Ödeme sayfasına yönlendiriliyorsunuz.",
        patience_please: "Bir dakika lütfen.",
        booking_completed: "Katılımcılar kaydedildi.",
      },
      close: {
        are_you_sure: "Emin misiniz?",
        description:
          "Rezervasyonu erken iptal etmek üzeresiniz. Rezervasyon verileri kaybolacaktır.",
        yes: "EVET",
        no: "HAYIR",
      },
      header: {
        select_an_option: "Bir tercih yapın",
        select_course: "Bir eğitim kursu seçin",
        select_participants: "Bir veya daha fazla katılımcı seçin",
        add_participant: "Yeni katılımcı ekleyin",
        select_wishes: "Eğitim ihtiyaçlarınızı seçin",
        confirm_info: "Verileri onaylayın",
      },
      available_from: "şuradan",
      options: {
        e_learning: "E-öğrenme",
        e_learning_and_exam: "Sınavla E-öğrenme",
        exam: "Sınav",
      },
      payment_information: {
        header: "Rezervasyon detayları",
        course: "Kurs",
        course_type: "Eğitim türü",
        amount_of_users: "Katılımcı sayısı",
        location: "Konum",
        examdate: "Sınav tarihi",
        e_learning_available: "E-öğrenmeyi yayınlayın",
        payment_method: "Ödeme yöntemi",
        payment_message:
          "İş ortağımız Mollie üzerinden doğrudan online ödeme yapabilirsiniz.",
      },
      invoice_information: {
        header: "Fatura verileri",
        company_name: "Şirket adı*",
        company_required: "Şirket adı gereklidir.",
        contactperson: "İletişim*",
        contactperson_required: "İrtibat kurulacak kişi gereklidir.",
        invoice_email: "Fatura e-posta adresi*",
        invoice_email_required: "Bir e-posta adresi gereklidir.",
        contactperson_email: "İletişim e-posta adresi*",
        contactperson_email_required: "Bir e-posta adresi gereklidir.",
        street: "Sokak adı ve apartman numarası*",
        street_required: "Sokak adı ve apartman numarası gereklidir.",
        city: "Şehir*",
        city_required: "Şehir gereklidir.",
        postalcode: "Posta Kodu*",
        postalcode_required: "Posta kodu gereklidir.",
        company: "Kurum",
        free_users: "Katılımcılar kursa ücretsiz olarak erişebilirler.",
        price: "Ücret",
      },
      cost: {
        total: "Toplam",
        subtotal: "Ara toplam",
        vat: "KDV",
      },
      courses: {
        course: "Kurs",
        sector: "Sektör",
        language: "Dil",
      },
      users: {
        enroll: "Kayıt",
        fullname: "Tam ad",
        email: "E-posta adresi",
      },
      add_new_user: "Yeni katılımcı ekleyin",
      policy: {
        agree_with: "Şart ve koşulları,",
        general_policy: "eğitim koşullarını",
        course_policy: "ve",
        and: "sınav koşullarını",
        exam_policy: "kabul ediyorum",
      },
    },
    add_user: {
      firstname: "Ad",
      lastname: "Soyad",
      email: "E-posta adresi",
      works_at: "Çalıştığı yer",
      extra_info: "Ek bilgi",
      language: "Dil",
      company: "Kurum",
      role: "Görev",
      birthdate: "Doğum tarihi",
      required: {
        firstname: "Ad gereklidir.",
        lastname: "Soyad gereklidir.",
        email: "E-posta adresi geçersiz.",
        works_at: "Bir şirket girin.",
        birthdate:
          "Geçerli bir doğum tarihi girin. Katılımcı 18 yaşında veya daha büyük olmalıdır.",
      },
    },
  },
};
export default words;

//Logic to make sure all keys are present in the translations
/*
let keys = [];

function getKeys(data, k = "") {
  for (let i in data) {
    let rest = k.length ? "." + i : i;

    if (typeof data[i] == "object") {
      if (!Array.isArray(data[i])) {
        getKeys(data[i], k + rest);
      }
    } else keys.push(k + rest);
  }
}

getKeys(words.nl);
console.log(keys.length);
getKeys(words.en);
console.log(keys.length);

function checkMissingKeys(base, other, base_key, missing_keys) {
  for (let key of Object.keys(base)) {
    if (other[key] == undefined) {
      missing_keys.push(base_key.length > 0 ? base_key + "." + key : key);
    } else if (typeof other[key] === "object") {
      checkMissingKeys(base[key], other[key], key, missing_keys);
    }
  }

  return missing_keys;
}

console.log(checkMissingKeys(words["nl"], words["en"], "nl", []));
*/

/*
  When given a new object
  Find the key mentioned and update it
  if the key does not exist create it
*/

/*
function updateOrAddKeys(base, other, new_object) {
  //Add the keys that are only in the base
  for (let key of Object.keys(base)) {
    if (other[key] == undefined) {
      new_object[key] = base[key];
    }
  }

  for (let key of Object.keys(other)) {
    if (base[key] == undefined) {
      console.log("adding in a new key", key);
      new_object[key] = other[key];
    } else if (typeof base[key] === "object") {
      new_object[key] = updateOrAddKeys(base[key], other[key], {});
    } else if (base[key] != other[key]) {
      console.log("replacing", base[key], "with", other[key]);
      new_object[key] = other[key];
    } else {
      new_object[key] = base[key];
    }
  }

  return new_object;
}
  */

//console.log(JSON.stringify(updateOrAddKeys(words["fr"], temp, {})));
